import React, { useState } from 'react'
import { Grid, Modal, Typography, Button, Box, FormGroup, IconButton } from '@mui/material'
import InputField from '../../components/InputField'
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import DropdownInput from '../../components/DropdownInput';
import { useDispatch, useSelector } from 'react-redux';
import reactotron from '../../ReactronConfig';
import { addNewUser } from '../../Redux/actions/userActions';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import BorderBox from '../../components/BorderBox';
import PhoneInputs from '../../components/PhoneInputs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard,faUser,faPhone,faArrowLeftLong,faArrowRightLong,faEnvelope} from '@fortawesome/free-solid-svg-icons';
const AddUser = ({ openAddModal, closeAddModal }) => {
    const location = useLocation();
    const { user, loading } = useSelector(state => state.auth)
            const [country, setCountry] = useState(null);
    const dispatch = useDispatch()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        borderRadius: '25px',
        border: '1px solid #fff',
        p: 4,
        height: '671',
        width: '35%'
    };
    const schema = yup.object({
        name: yup.string().required('User name required').max(30, 'Maximum  character exceeded'),
        email: yup.string().required('Email required').max(30, 'Maximum  character exceeded'),
        phone: yup.string().required('Phone required').max(30, 'Maximum  character exceeded')
    }).required();

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            customer_type: user?.customer_type,
            is_corporate: user?.is_corporate,
            parent_id: user?.id
        },
        mode: 'onChange'
    });
    const onSubmit = (data) => {
        let values = {
            customer_type: user?.customer_type,
            is_corporate: user?.is_corporate,
            parent_id: user?.id,
            name:data.name,
            email:data.email,
            phone:country.phonenumber,
            country_code:country.callingCode
        }
        dispatch(addNewUser(values))
    };

    const changePhone = (value) => {
        setCountry(value)
    }

    return (
        <Modal open={openAddModal} onClose={closeAddModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"  >
            <Box sx={style} >
            <div className='modal-top'>
                    <h3>Add New User</h3>
                    <IconButton onClick={closeAddModal}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1%' }}>

                    
                </div>
                <div className='booking-detailscard pb-30'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <InputField
                                fieldLabel="Name"
                                fieldName="name"
                                control={control}
                                error={errors.name}
                                fieldIcon={<FontAwesomeIcon icon={faUser}/>}
                            />
                        </div>
                        <div className='col-sm-6'>
                            <InputField
                                fieldLabel="Email"
                                fieldName="email"
                                control={control}
                                error={errors.email}
                                fieldIcon={<FontAwesomeIcon icon={faEnvelope}/>}
                            />
                        </div>
                        <div className='col-sm-12'>
                            <PhoneInputs
                                fieldLabel="Phone"
                                fieldName="phone"
                                control={control}
                                error={errors.phone}
                                required
                                onChangePhone={changePhone}
                                fieldIcon={<FontAwesomeIcon icon={faPhone}/>}
                            />
                        </div>
                        
                    </div>
</div>

               
<div className='col-sm-12'>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingTop: 20 }}>
                    <Button type="submit" disabled={loading} variant="filled" style={{ backgroundColor: '#b7a269', borderRadius: 30, height: 40, width: 120, padding: 5 }} onClick={handleSubmit(onSubmit)}>
                        <Typography className='buttonText'>{loading ? 'LOADING...' : "SAVE"}</Typography>
                    </Button>
                </div>
                        </div>
                  


                
              





            </Box>
        </Modal>
    )
}

export default AddUser
