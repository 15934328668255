import React, { useState, useEffect } from 'react'
import './booking.scss'
import { Button, Typography, ToggleButtonGroup, ToggleButton, Divider } from '@mui/material'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles';
import CountInput from '../../components/CountInput';
import SelectPassenger from '../../components/SelectPassenger';
import PlacesInput from '../../components/PlacesInput';
import DateInput from '../../components/DateInput';
import moment from 'moment';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock,faLocationDot,faUsers,faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { wrap } from 'lodash';

const PointToPoint = () => {

	const location = useLocation();
	const { bookingData } = useSelector(state => state.booking)
	const [open, setOpen] = useState(false)
	const dispatch = useDispatch();
	const [alignment, setAlignment] = React.useState('oneway');
	const [checktoday,setcheckday]=useState(bookingData ? moment(bookingData?.pickupdate).isSame(moment(), 'day') : true)
	const navigate = useNavigate();
	const [returnMinDate, setReturnMinDate] = useState(moment().add(0, 'days').toDate())

	useEffect(() => {
		if (bookingData) {
			setAlignment(bookingData?.bookingType)
		}
	}, [bookingData])



	const StyledToggle = styled((props) => <ToggleButton disableRipple {...props} />)(
		({ theme }) => ({
			textTransform: 'none',
			fontFamily: 'nunito',
			borderRadius: '0px',
			height: '30px',
			border: '0px',
			fontSize: theme.typography.pxToRem(12),
			marginRight: theme.spacing(0),
			color: '#333333',
			backgroundColor: '#F6F6F6',
			'&.Mui-selected': {
				color: '#fff',
				backgroundColor: '#333333'
			},
			'&.Mui-selected:hover': {
				color: '#fff',
				backgroundColor: '#B6A269'
			},
		}),
	);


	const handleChange = (event, newAlignment) => {
		if (newAlignment) {
			setAlignment(newAlignment);
		}
	};


	const schema = yup.object({
		location_from: yup.mixed().required("Pick up location is required"),
		location_to: yup.mixed().required("Drop off location is required"),
		pickupdate: yup.string().required("Pickup date & time required").typeError("Pickup date & time required"),
		returndate: yup.string().typeError("Return date & time required").when('bookingType', (bookingType, schema) => {
			return alignment === "return" ? schema.required("Return date & time required") : schema.min(0);
		}),
	}).required();

	const { register, handleSubmit, setValue, reset, control, formState: { errors }, getValues, setError } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			type: 'point',
			location_from: bookingData?.location_from,
			location_to: bookingData?.location_to,
			pickupdate: bookingData?.pickupdate ? new Date(bookingData?.pickupdate) : null,
			passenger: bookingData?.passenger ? bookingData?.passenger : 1,
			adults: bookingData?.adults ? bookingData?.adults : 1,
			baby: bookingData?.baby ? bookingData?.baby : 0,
			childrens: bookingData?.childrens ? bookingData?.childrens : 0,
			minors: bookingData?.minors ? bookingData?.minors : 0,
			returndate: bookingData?.returndate ? new Date(bookingData?.returndate) : '',
			bookingType: alignment
		}
	});





	

	const onSubmit = (data) => {
		if (!data?.adults) {
			data.adults = data?.passenger;
		}
		if (alignment === "return") {
			if (moment(data?.pickupdate) > moment(data?.returndate)) {
				toast.error("Return date should be greater than pickup date")
			}
			else {
				data.bookingType = alignment;

				localStorage.setItem("booking", JSON.stringify(data))
				if (location.pathname.toLowerCase() === "/booking") {

					window.open('/summary', '_blank')
				}
				else {
					navigate('/summary');
				}
			}
		}
		else {
			data.bookingType = alignment;

			localStorage.setItem("booking", JSON.stringify(data))
			if (location.pathname.toLowerCase() === "/booking") {

				window.open('/summary', '_blank')
			}
			else {
				navigate('/summary');
			}
		}


		//let userId = user.id
		//dispatch(addPointBooking())
	};


	const getPasengers = () => {
		setOpen(false)
		let count = getValues();


		setValue("passenger", count.baby + count.adults + count.minors + count.childrens)

	}
	const resetPasengers = () => {
		setOpen(false)
		setValue("adults", 1)
		setValue("minors", 0)
		setValue("childrens", 0)
		setValue("baby", 0)
		setValue("passenger", 1)

	}

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	return (

		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="booking-details">
			<div className="row" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap' }}>
				<div className="col-xs-12 col-sm-3 col-md-2">
				<div className="booking-tabs">
					<ToggleButtonGroup
						color="primary"
						value={alignment}
						exclusive
						onChange={handleChange}
						aria-label="Platform"
						fieldName='bookingType'
					>
						
						<StyledToggle value="oneway" className='toggleText'> One Way </StyledToggle>
						<StyledToggle value="return" className='toggleText'>Return</StyledToggle>
						
					</ToggleButtonGroup>
					</div>
				</div>
				<div className="col-xs-12 col-sm-9 col-md-10" style={{borderLeft: '1px solid #e6e6e6'}}>
				<div className="row">
				<div className="col-xs-12 col-sm-6 col-md-4 pb-15">
				<DateInput
						todaycheck={checktoday}
						fieldLabel="Pick Up Date & Time"
						fieldName="pickupdate"
						//marginRight={20}
						control={control}
						mode={'hour'}
						error={errors.pickupdate}
						fieldType="datetime"
						// minDate={new Date(Date.now() + ( 3600 * 1000 * 24)).toISOString().slice(0, 16)}
						minDate={new Date().getTime()}
						minTime={null}
						changeDate={(value) => {
							setReturnMinDate(moment(value).add(1, 'hour').toDate())
							//setValue("pickupdate", value)
						}}
						fieldIcon={<FontAwesomeIcon icon={faClock}/>}
						
					/>
				</div>
				{/* <div className="col-sm-4 pb-15">
					<div className="time-picker">
						<TimePicker setTime={(time) => pickupTime(time)} />
					</div>
				</div> */}
				<div className="col-xs-12 col-sm-6 col-md-4 pb-15">
					<PlacesInput

						fieldLabel="Pick Up Location"
						fieldName="location_from"
						control={control}
						error={errors.location_from}
						//marginRight={1}
						width={"94%"}
						fontSize="14px"
						fieldIcon={<FontAwesomeIcon icon={faLocationDot}/>}
						onPlaceChange={(value) => {
							console.log({ value })
							setValue("location_from", value)
							setError('location_from', '')
							
						}}
						
					/>
				</div>
				<div className="col-xs-12 col-sm-6 col-md-4 pb-15">
					<PlacesInput
						fieldLabel="Drop Off Location"
						fieldName="location_to"
						control={control}
						error={errors.location_to}
						fieldIcon={<FontAwesomeIcon icon={faLocationDot}/>}
						onPlaceChange={(value) => {
							setValue("location_to", value)
							setError("location_to", '')
						}}
					/>
				</div>
				{alignment === "return" &&
				<div className="col-xs-12 col-sm-6 col-md-4 pb-15">
				
					<DateInput
						fieldLabel="Return Date & Time"
						fieldName="returndate"
						control={control}
						error={errors.returndate}
						minDate={returnMinDate}
						fieldIcon={<FontAwesomeIcon icon={faClock}/>}
						fieldType="datetime"
						fontSize="14px"
						width={"90%"}
						changeDate={(value) => setValue("returndate", value)}
					/>
				
				</div>}
				<div className="col-xs-12 col-sm-6 col-md-4 pb-15">
					<div className="passenger-details" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}
					>
						<SelectPassenger
							fieldLabel="Passenger Details"
							control={control}
							fieldName="passenger"
							fontSize="14px"
							width={"100%"}
							open={open}
							onClose={handleClose}
							onOpen={handleOpen}
							values={getValues().passenger}
							fieldIcon={<FontAwesomeIcon icon={faUsers}/>}
						>
							<CountInput
								fieldLabel="Adults"
								fieldName="adults"
								error={errors.adults}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("adults", value)}
								minValue={1}
								values={getValues().adults}
							/>
							<Divider alignment='vertical' variant='middle' />
							<CountInput
								fieldLabel="Baby Seats (0 to 12 Months)"
								fieldName="baby"
								error={errors.baby}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("baby", value)}
								minValue={0}
								values={getValues("baby")}
							/>
							<Divider alignment='vertical' variant='middle' />
							<CountInput
								fieldLabel="Child Seats (1 to 2 Yrs)"
								fieldName="childrens"
								error={errors.childrens}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("childrens", value)}
								minValue={0}
								values={getValues("childrens")}
							/>
							<Divider alignment='vertical' variant='middle' />
							<CountInput
								fieldLabel="Booster Seats (3 to 4 Yrs)"
								fieldName="minors"
								error={errors.minors}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("minors", value)}
								minValue={0}
								values={getValues("minors")}
								borderBottom={{ xs: 0, md: "2px solid #F6F6F6 " }}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center'
								}}
							>
								<Button
									type="button"
									variant="filled"
									onClick={resetPasengers}
									style={{ backgroundColor: '#FFFFFF', borderRadius: 4, height: 'auto', width: 'auto', border: '1px solid #333333' }}>
									<Typography className='resetButton' >RESET</Typography>
								</Button>
								<span style={{ padding: 2 }} />
								<Button
									type="button"
									variant="filled"
									onClick={getPasengers}
									style={{ backgroundColor: '#333333', borderRadius: 4, height: 'auto', width: 'auto' }}>
									<Typography className='buttonText' >APPLY</Typography>
								</Button>
							</div>
						</SelectPassenger>
					</div>
					</div>
					<div className='col-xs-12'>
					<div>
						<Button type="submit" variant="filled" style={{ width:'110px', backgroundColor: '#ad9052', padding: 5, borderRadius: 30, height: 30 }} className=' subm-buttoncls'>
						{/* <Typography className='buttonText' >NEXT</Typography></Button> */}
						<Typography className='buttonText'>Search <span className='button-icon'><FontAwesomeIcon icon={faArrowRightLong} /></span></Typography></Button>
			</div>
					</div>
					
				</div>
				
					</div>
				</div>
				</div>
				
			
		</form>

	)
}

export default PointToPoint
