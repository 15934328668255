import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TripList from './TripList';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../Redux/constants/tripConstants';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import CarImg from '../../assets/images/IconsNew/car.png'
import DateInput from '../../components/DateInput';
import moment from 'moment'
import { getTripsByDate } from '../../Redux/actions/tripActions';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CustomDate from '../../components/CustomDate';
import Grid from '@mui/material/Grid';
import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faCalendar, faBusinessTime, faWallet, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { wrap } from 'lodash';
const StyledTabs = styled((props) => (
	<Tabs
		style={{ height: "32px" }}
		{...props}
		sx={{
			'& .MuiTabs-flexContainer': {
				flexWrap: 'wrap',
				height: 30
			},

		}}
		TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
	/>
))({
	'& .MuiTabs-indicator': {
		display: 'none',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		maxWidth: 50,
		width: '70%',
		backgroundColor: '#333333',
	},
});

const StyledTab = styled((props) =>
	<Tab disableRipple {...props} />)(
		({ theme }) => ({
			textTransform: 'none',
			fontFamily: 'nunito',
			fontWeight: '700',
			boxShadow: 1,
			fontSize: theme.typography.pxToRem(12),
			marginRight: 0,
			//borderRadius: '2px',
			//width: '70%',
			marginTop: 0,
			backgroundColor: '#b6a269',
			color: '#333333',
			'&.Mui-selected': {
				color: '#fff',
				backgroundColor: '#333333',
				//width: 200
			},

		}),
	);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box >
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function TripHistory() {

	const [value, setValue] = React.useState(0);
	const { user } = useSelector(state => state.auth)
	const { tripLists, deleteSuccess, error } = useSelector(state => state.trip)
	const [tripType, setTripeType] = React.useState(null);
	const [fromDate, setFromDate] = useState('')
	const [toDate, setToDate] = useState('')
	const navigate = useNavigate();
	const dispatch = useDispatch()



	const schema = yup.object({
		fromDate: yup.string(),
		toDate: yup.string()
	}).required();


	const { register, handleSubmit, control, formState: { errors } } = useForm({
		resolver: yupResolver()
	});
	// useEffect(() => {
	//   let id=user?.id
	//   dispatch(getTrip(id))
	// }, [])

	useEffect(() => {
		if (error) {
			toast.error(error);
			dispatch({
				type: RESET_ERROR
			})
		}
	}, [error])


	const handleChange = (event, newValue) => {
		setValue(newValue);
		switch (newValue) {
			case 0:
				setTripeType('onGoingTrips')
				break;
			case 1:
				setTripeType('upComingTrips')
				break;
			case 2:
				setTripeType('completedTrips')
				break;
			default:
				break;
		}
	};

	const onSubmit = (fromDate, toDate) => {
		let userId = user.id
		dispatch(getTripsByDate(fromDate, toDate, userId))
	};

	return (
		<div className="booking-table">
			
			<div className="col-12">
				<div className="row">
				<div className="col-sm-12 col-md-6">
						<div className="search-sec">
				{tripType !== "onGoingTrips" && <form style={{display: 'flex', alignItems: 'center' }} onSubmit={handleSubmit(onSubmit)}>

					
					
							{tripType == "upComingTrips" &&
								<div className="inline-form">
									<label>From</label>
									<div className="input-group">
										<CustomDate label={'From'} changeDate={(value) => setFromDate(value)} minDate={moment().toDate()} /> 
										<div className="input-group-addon"><FontAwesomeIcon icon={faCalendar} /></div>
									</div>
								</div>

							}
							{tripType == "completedTrips" &&
								<div className="inline-form">
									<label>From</label>
										<div className="input-group">
									
										<CustomDate label={'From'}  changeDate={(value) => setFromDate(value)} />
										<div className="input-group-addon"><FontAwesomeIcon icon={faCalendar} /></div>
									</div>
								</div>
							}
						
						
				
									{tripType == "upComingTrips" &&
										<div className="inline-form">
										<label>To</label>
										<div className="input-group">
											
											<CustomDate label={'To'} changeDate={(value) => setToDate(value)} minDate={moment().toDate()} />
											<div className="input-group-addon"><FontAwesomeIcon icon={faCalendar} /></div>
											</div>
										</div>
									}
									{tripType == "completedTrips" &&
										<div className="inline-form">
										<label>To</label>
										<div className="input-group">
										
											<CustomDate  label={'To'} changeDate={(value) => setToDate(value)} />
											<div className="input-group-addon"><FontAwesomeIcon icon={faCalendar} /></div>
											</div>
										</div>
									}
							
					</form>}
					</div>
						</div>
					<div className="col-sm-12 col-md-6 text-right">
						<div className="my-booking-nav">
							
						<StyledTabs
							value={value}
							onChange={handleChange}
							variant="scrollable"
							aria-label="basic tabs example"
							textColor="secondary"
							indicatorColor="secondary"
							className='nav nav-pills'
						>
							<StyledTab label="ONGOING" className='nav-item' {...a11yProps(0)} style={{ fontFamily: 'Nunito', fontWeight: 'bold', maxWidth: '10%', fontSize: 12  }}  />
							<StyledTab label="UPCOMING" className='nav-item' {...a11yProps(1)} style={{ fontFamily: 'Nunito', fontWeight: 'bold', maxWidth: '10%', fontSize: 12  }} />
							<StyledTab label="COMPLETED" className='nav-item' {...a11yProps(2)} style={{ fontFamily: 'Nunito', fontWeight: 'bold', maxWidth: '10%', fontSize: 12 }} />
						</StyledTabs>
				
					  </div>
						
					</div>
							
					{/* <Button
						variant="contained"
						endIcon={<img src={CarImg} style={{ width: 20, height: 20 }} />}
						style={{ color: 'white', backgroundColor: '#333333', borderRadius: 1, height: 30 }}
						onClick={() => navigate("/newbooking")}
						className="newbooking"
					>
						NEW BOOKING
					</Button> */}
				</div>
			

				</div>
			{/* <Box display={{ xs: 'none', md: 'block' }} >
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<StyledTabs
							className='tabheight'
							value={value}
							onChange={handleChange}
							variant="scrollable"
							aria-label="basic tabs example"
							textColor="secondary"
							indicatorColor="secondary"
						>
							<StyledTab className='tabheight' label="ONGOING" {...a11yProps(0)} style={{ maxWidth: '13%' }} />
							<StyledTab className='tabheight' label="UPCOMING" {...a11yProps(1)} style={{ maxWidth: '13%' }} />
							<StyledTab className='tabheight' label="COMPLETED" {...a11yProps(2)} style={{ maxWidth: '13%' }} />
						</StyledTabs>
						{tripType !== "onGoingTrips" && <form onSubmit={handleSubmit(onSubmit)}>

							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }} >
								<div >
									{tripType == "upComingTrips" &&
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<Typography fontFamily={'Nunito'} fontSize={12} >From</Typography>
											<CustomDate changeDate={(value) => setFromDate(value)} minDate={moment().toDate()} />
										</div>

									}
									{tripType == "completedTrips" &&
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<Typography fontFamily={'Nunito'} fontSize={12} >From</Typography>
											<CustomDate changeDate={(value) => setFromDate(value)} />
										</div>
									}
								</div>
								<div >
									{tripType == "upComingTrips" &&
										<div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
											<Typography fontFamily={'Nunito'} fontSize={12} >To</Typography>
											<CustomDate changeDate={(value) => setToDate(value)} minDate={moment().toDate()} />
										</div>

									}
									{tripType == "completedTrips" &&
										<div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
											<Typography fontFamily={'Nunito'} fontSize={12} >To</Typography>
											<CustomDate changeDate={(value) => setToDate(value)} />
										</div>
									}
								</div>
							</div>
						</form>}
					</div>

					<Button
						variant="contained"
						endIcon={<img src={CarImg} style={{ width: 25, height: 25 }} />}
						style={{ color: 'white', backgroundColor: '#333333', borderRadius: 4, height: 30, padding: 5 }}
						onClick={() => navigate("/newbooking")}
						className="newbooking"
					>
						NEW BOOKING
					</Button>
				</div>
			</Box> */}
			<div className="col-12">
				<div className="tab-content" id="pills-tabContent">
				<TabPanel value={value} index={0}  >
					<TripList tripType="onGoingTrips" />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<TripList tripType="upComingTrips" fromDate={fromDate} toDate={toDate} />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<TripList tripType="completedTrips" fromDate={fromDate} toDate={toDate} />
				</TabPanel>
				</div>
			</div>
		</div>
	);
}
