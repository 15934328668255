import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from '../assets/images/logo.png'
import DropDownMenu from './DropDownMenu';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_USER } from '../Redux/constants/userConstants';
import { Avatar, Grid, Stack } from '@mui/material';
import { googleLogout } from '@react-oauth/google';
import { BOOKING_DATA, RESET_BOOKING } from '../Redux/constants/bookingConstants';
import LogoMockUp from '../assets/images/Profile.png'
import HomeHeaderWrapper from './HomeHeaderWrapper'
const drawerWidth = 240;
function TopMenu(props) {
	const { user } = useSelector(state => state.auth)
	// const dispatch = useDispatch();
	const locations = useLocation();
	
	const [navItems, setNavItems] = useState([])
	const [loginType, setLoginType] = useState(null)


	useEffect(() => {
		let items = null;
		if (user) {
			if (user?.role === "customer") {
				items = [
					{
						name: 'MY BOOKINGS',
						path: '/dashboard'
					},
					{
						name: 'PROFILE',
						path: '/profile'
					}
				]
			}
			else if (user?.role === "agent") {
				items = [
					{
						name: 'MY BOOKINGS',
						path: '/dashboard'
					},
					// {
					// 	name: 'E-WALLET',
					// 	path: '/wallet'
					// },
					// {
					// 	name: 'TOP UP',
					// 	path: '/topup'
					// },
					{
						name: 'PROFILE',
						path: '/profile'
					}
				]
			}
			else if (user?.role === "corporate") {
				items = [
					{
						name: 'MY BOOKINGS',
						path: '/dashboard'
					},
					{
						name: 'PROFILE',
						path: '/profile'
					}
				]
			}

			setNavItems(items)
			let type = localStorage.getItem("loginType");
			setLoginType(type)
		}
	}, [user])


	const location = useLocation()
	const navigate = useNavigate()

	const dispatch = useDispatch()


	const { window, auth } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);



	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const navigatePage = async () => {

		dispatch({
			type: RESET_BOOKING
		})
		navigate(auth ? '/dashboard' : '/')
			
	}

	const drawer = (
	<Box sx={{ textAlign: 'center' }}>
			<img src={Logo} style={{ width: 104, height: 65, cursor: 'pointer' }} onClick={navigatePage} />
			<Divider />
			{auth && <List>
				{navItems && navItems?.map((item) => (
					<ListItem key={item.name} disablePadding className={location.pathname === item?.path ? 'active' : 'inactive'}>
						<ListItemButton sx={{ border: 0 }} onClick={() => navigate(item.path)}>
							<ListItemText primary={item.name} style={{ color: 'black', fontWeight: 'bold', border: 0 }} />
						</ListItemButton>
					</ListItem>
				))}
				{((user?.role === "agent" || user?.role === "corporate") && !user?.parent_id) && <ListItemText sx={{ display: 'flex', paddingLeft: 1 }}><DropDownMenu menuname={"SETTINGS"} activeMenu={5} /></ListItemText>}
			</List>}
		</Box>
	);

	const logout = async () => {
		dispatch({
			type: RESET_USER
		})
		await localStorage.removeItem("user")
		//signOut()
		navigate('/signin')
	}

	const googleLogout = async () => {
		dispatch({
			type: RESET_USER
		})
		await localStorage.removeItem("user")
		//googleLogout()
		//signOut()
		navigate('/signin')
	}

	const container = window !== undefined ? () => window().document.body : undefined;


	

	return (
		<>
		<ul className="sa_login menu-rt" >

<li className="homemenu mainmnu mainmnuext hd-login mb-login b2bb2cLogin">
	<Box sx={{ display: { xs: 'none', md: 'flex', flexDirection: 'row' } }}>
		{navItems?.map((item) => (
			<Button style={{ padding: 0 }} key={item.name} onClick={() => navigate(item?.path)}>
				<Typography className={location.pathname === item?.path ? 'active' : 'inactive'}>{item.name}</Typography>
			</Button>
		))}
		{user?.role !== "customer" && !user?.parent_id && <DropDownMenu menuname={"SETTINGS"} activeMenu={5} />}
		{loginType === "form" && <span onClick={logout}><Typography className='inactive'> LOGOUT </Typography></span>}
		{loginType === "google" && <span onClick={() => googleLogout()}><Typography className='inactive'>LOGOUT</Typography></span>}
	</Box>
</li>

<li className="homemenu mainmnu mainmnuext hd-signup b2bb2cSignup">
	<Stack alignItems={"center"}>
		
		<Avatar src={user?.image ? user?.image : LogoMockUp} />
		<span className='headerstyle' style={{ color: '#000', textAlign: 'center', fontSize: '12px', width: '100%' }}>{user?.name}</span>
		{(user?.role === "agent" || user?.role === "corporate") && <span className='headerstyle' style={{ color: '#000', textAlign: 'center', fontSize: '12px', width: '100%' }}>{user?.companyinfo?.company_name === 'undefined' || undefined ? '' : user?.companyinfo?.company_name}</span>}
	</Stack>
</li>
<li className="homemenu mainmnu mainmnuext hd-signup b2bb2cSignup">
	<Drawer
		container={container}
		variant="temporary"
		open={mobileOpen}
		onClose={handleDrawerToggle}
		ModalProps={{
			keepMounted: true, // Better open performance on mobile.
		}}
		sx={{
			display: { sm: 'block', md: 'none' },
			'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
		}}
	>
		{drawer}
	</Drawer>
</li>


</ul>
		</>
	);
}



export default TopMenu;
