import React from 'react'
import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { useNavigate } from "react-router-dom";
import mainLogo from '../assets/images/b2b_b2cgbanewlogo-white.png';
import HomeTopBar from './HomeTopBar'
import HomeHeader from './HomeHeader'
import  { useDispatch } from 'react-redux'
import { RESET_USER } from '../Redux/constants/userConstants';
const HomeHeaderWrapper = () => {
    
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleNavMenu8 = async() => {
        setAnchorElNav(null);
        dispatch({
          type: RESET_USER
        })
        await localStorage.removeItem("user")
        navigate('/home')  
      };
    return (
        <div className="container-fluid numainmenu">	
            <HomeHeader />
            <HomeTopBar activeMenu='1' />
            
         </div>  
    )
}

export default HomeHeaderWrapper

