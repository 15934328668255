import BookingDetails from '../Booking/BookingDetails'
import React from 'react'

const BookingForm = () => {

  return (
    <div style={{borderRadius: 0}}>
      <BookingDetails />  
    </div>  
  )
}

export default BookingForm