import React, { Fragment, useEffect } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './booking.scss'
import { Card, Typography } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { height } from '@mui/system';
import LocationImg from '../../assets/images/Icons/placeholder.png'
import TimeImg from '../../assets/images/Icons/timetable.png'
import DistanceImg from '../../assets/images/Icons/measure-distance.png'
import TopBar from '../../components/TopBar'
import BookingDetails from './BookingDetails'
import { useDispatch } from 'react-redux'
import {  useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
const Booking = ({ head }) => {

    const navigate = useNavigate()
    return (
        <Layout head={head}>
            {/* <WorkFlow activeStep={0} /> */}
            <div className='container'>
                <div className='row'>
                    <div className='col-xs-12'>
                        <div className='booking-top'>
                            <div className='row'>
                                <div className='col-sm-8'>
                                    <h2>Booking Details</h2>
                                </div>
                                <div className='col-sm-4 text-right'>
                                <a onClick={() => navigate(-1)} className='btn back-btn'><span className='button-icon'><FontAwesomeIcon icon={faArrowLeftLong} /></span>Back</a>
                                </div>
                            </div>
                            {/* <h5>Step - 1</h5> */}
                            
                            
                                
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'><BookingDetails />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Booking
