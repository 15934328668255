import React, { useEffect ,useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, MenuItem } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { toast } from 'react-toastify'
import * as yup from "yup";
import DropdownInput from '../../components/DropdownInput';
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs'
import BorderBox from '../../components/BorderBox'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {  useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard,faUser,faPhone,faArrowLeftLong,faArrowRightLong,faBuilding,faMoneyCheckDollar} from '@fortawesome/free-solid-svg-icons';
const B2BForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [country, setCountry] = useState(null)
    const [countrycompany, setCountryCompany] = useState(null)
    const [usertype, setUserType] = useState(1)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const { registerSuccess, error } = useSelector(state => state.auth)

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const schema = yup.object({
        firstname: yup.string().required('First name is required').max(100, 'Maximum character exceeded'),
        lastname: yup.string().required('Last name is required').max(100, 'Maximum character exceeded'),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required').max(30, 'Maximum character exceeded'),
        email: yup.string().email('Invalid email format').required('Email is required').max(30),
        password: yup.string().required('Password is required').min(8, 'Password should contain atleast 8 characters').max(30, 'Maximum character exceeded'),
        confirm_password: yup.string().when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("password")], "Both password need to be the same"),
        })
            .required("Confirm Password Required").max(30),
       // user_type: yup.string().required('User type is required'),
    }).required();

    const { register, handleSubmit, control, formState: { errors },reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            company_name: '',
            company_phone: '',
            company_phone_code: '',
            company_email: '',
            company_address: '',
            company_tax: '',
            name:'',
            email:'',
            lastname:'',
            phone:'',
            mobile:'',
            password:''
        },
        mode: 'onChange'
    });

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }
        if (registerSuccess) {
            setOpen(true)
            reset()
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error, registerSuccess])

    const changePhone = (value) => {
        setCountry(value)
    }

    const changeBussinessPhone = (value) => {
        setCountryCompany(value)
    }
    const changeUserType = (event) => {
        setUserType(event.target.value)
    }
    
    const onSubmit = (data) => {
        console.log(usertype);

        let formData = new FormData();
        formData.append("email", data.email);
        formData.append("name", data.firstname);
        formData.append("lastname", data.lastname);
        formData.append("mobile", country?.phonenumber ? country?.phonenumber : '');
        formData.append("phone", country?.phonenumber ? country?.phonenumber : '');
        formData.append("country_code", country?.callingCode ? country?.callingCode : '');
        formData.append("password", data.password);
        formData.append("company_name", data.company_name);
        formData.append("company_phone", countrycompany?.phonenumber ? countrycompany?.phonenumber : '');
        formData.append("company_phone_code", countrycompany?.callingCode ? countrycompany?.callingCode : '');
        formData.append("company_email", data.company_email);
        formData.append("company_address", data.company_address);
        formData.append("company_tax", data.company_tax);
        formData.append("user_type", usertype);
        formData.append("is_corporate", 2);
        dispatch(userRegister(formData))
    };
   
    return (

        <div className='row' >
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    User successfully registered
                </Alert>
            </Snackbar>
            <div className='container'>

            <div className='row'>
            <div className='col-sm-12' >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup >
                        <div class="booking-top">
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <h2>Company Registration</h2>
                                </div>
                               
                                
                               
                                {/* <div className='col-sm-12' style={{ marginTop: '30px'}}>
                                    <DropdownInput fieldLabel="User Type" fieldName="user_type" control={control} error={errors.user_type} dir="row" width={300} >
                                        <MenuItem style={{ fontSize: 12, fontFamily: 'Nunito' }} value='1'>Corporate</MenuItem>
                                        <MenuItem style={{ fontSize: 12, fontFamily: 'Nunito' }} value='2'>Travel Agency</MenuItem>
                                    </DropdownInput>
                                </div> */}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='rgst-for'>
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Register For</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="user_type"
                                            onChange={changeUserType}
                                        >
                                            <FormControlLabel value="1" control={<Radio />} label="Corporate" defaultChecked/>
                                            <FormControlLabel value="2" control={<Radio />} label="Travel Agency" />
                                            
                                            
                                        </RadioGroup>
                                    </FormControl>
                                </div> 
                                   
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 mt-30'>
                            <div className='booking-detailscard'>
                                <h3 class="hd-text register-txt-hd">Basic Information</h3>
                                <div className='row'>
                                    <div className='col-sm-6 pb-15'>
                                        <InputField
                                            fieldLabel="First Name"
                                            fieldName="firstname"
                                            control={control}
                                            error={errors.firstname}
                                            fieldIcon={<FontAwesomeIcon icon={faUser}/>}
                                        />
                                    </div>
                                    <div className='col-sm-6 pb-15'>
                                        <InputField
                                            fieldLabel="Last Name"
                                            fieldName="lastname"
                                            control={control}
                                            error={errors.lastname}
                                            fieldIcon={<FontAwesomeIcon icon={faUser}/>}
                                        />
                                    </div>
                                    <div className='col-sm-6 pb-15'>
                                        <InputField
                                            fieldLabel="Email Address"
                                            fieldName="email"
                                            control={control}
                                            error={errors.email}
                                            fieldIcon={<FontAwesomeIcon icon={faBuilding}/>}
                                        />
                                    </div>
                                    <div className='col-sm-6 pb-15'>
                                        <PhoneInputs
                                            fieldLabel="Phone Number"
                                            fieldName="phone"
                                            control={control}
                                            error={errors.phone}
                                            onChangePhone={changePhone}
                                            fieldIcon={<FontAwesomeIcon icon={faPhone}/>}
                                        />
                                    </div>
                                    <div className='col-sm-6 pb-15'>
                                        <InputField
                                            fieldLabel="Password"
                                            fieldName="password"
                                            control={control}
                                            error={errors.password}
                                            fieldType="password"
                                            // placeholder='Password should contain atleast 8 characters'
                                        />
                                    </div>
                                    <div className='col-sm-6 pb-15'>
                                        <InputField
                                            fieldLabel="Confirm Password"
                                            fieldName="confirm_password"
                                            control={control}
                                            error={errors.confirm_password}
                                            fieldType="password"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div className='col-sm-12 mt-30'>
                            <div className='booking-detailscard'>
                                    <h3 class="hd-text register-txt-hd">Company Details</h3>
                               
                                    <div className='row'>
                                        <div className='col-sm-6 pb-15'>
                                            <InputField
                                                fieldLabel="Company Name"
                                                fieldName="company_name"
                                                control={control}
                                                error={errors.company_name}
                                                fieldIcon={<FontAwesomeIcon icon={faUser}/>}
                                            />
                                        </div>
                                        <div className='col-sm-6 pb-15'>
                                            <InputField
                                                fieldLabel="Company Email Address"
                                                fieldName="company_email"
                                                control={control}
                                                error={errors.company_email}
                                                fieldIcon={<FontAwesomeIcon icon={faBuilding}/>}
                                            />
                                        </div>
                                        <div className='col-sm-6 pb-15'>
                                            <PhoneInputs
                                                fieldLabel="Phone Number"
                                                fieldName="company_phone"
                                                control={control}
                                                error={errors.company_phone}
                                                onChangePhone={changeBussinessPhone}
                                                fieldIcon={<FontAwesomeIcon icon={faPhone}/>}
                                            />
                                        </div>
                                        <div className='col-sm-6 pb-15'>
                                            <InputField
                                                fieldLabel="Tax No."
                                                fieldName="company_tax"
                                                control={control}
                                                error={errors.company_tax}
                                                fieldIcon={<FontAwesomeIcon icon={faMoneyCheckDollar} />}
                                            />
                                        </div>
                                        <div className='col-sm-6 pb-15'>
                                            <InputField
                                                fieldLabel="Company Address"
                                                fieldName="company_address"
                                                control={control}
                                                error={errors.company_address}
                                                multiline="multiline"
                                                fieldIcon={<FontAwesomeIcon icon={faAddressCard} />}
                                            />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            <div className='col-sm-12' style={{ marginTop: '3%', borderTop: '1px solid #e0e0e0' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', padding: '3% 0 5% 0' }}>
                                    <Button type="submit" className='subm-buttoncls' variant="filled" style={{ backgroundColor: '#ad9052', borderRadius: 30, padding: 5, height: 50, width: 120 }}>
                                        <Typography className='buttonText'>Register <span className='button-icon'><FontAwesomeIcon icon={faArrowRightLong} /></span></Typography></Button>
                                </div>
                            </div>
                        </div>
                    </FormGroup>
                </form>
            </div>
            </div>
        </div>
    </div>
    )
}

export default B2BForm
