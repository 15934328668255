import React, { useState, useEffect } from 'react'
//import './style.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Button, Grid,Switch, TextField,InputAdornment,Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useForm } from 'react-hook-form';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { getUserList } from '../../Redux/actions/userActions';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../Redux/constants/profileConstants';
import { makeStyles } from '@mui/styles';
import UserActions from '../../components/UserActions';
import AddUser from './AddUser'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser} from '@fortawesome/free-solid-svg-icons';
function createData(user_name, role, date) {
	return { user_name, role, date };
}

const rows = [
	createData('Sanath', 'Booker', "22/05/2022 10:00 am"),
	createData('Brandon', 'Booker', "15/05/2022 12:00 am"),
	createData('Zane', 'Accountant', "15/05/2022 12:00 am"),

];

const useStyles = makeStyles(() => ({
	noBorder: {
	  borderRadius: 0,
	  border: 0,
	},
	resize:{
		fontSize:12,
	},
}));


const UserList = () => {


	const dispatch = useDispatch()
	const navigate = useNavigate()

	const classes = useStyles();


	const { register, handleSubmit, control, formState: { errors } } = useForm();

	const { subUsers, user, newUserSuccess } = useSelector(state => state.auth)
	const { subUserUpdate } = useSelector(state => state.profile)

	useEffect(() => {
	  if(newUserSuccess){
		let data ={ 
			"parent_id": user?.id
		}
		dispatch(getUserList(data))
	  }

	  if(subUserUpdate){
		setEditModalShow(false)
		toast.success("User Updated Successfully")
		dispatch({
			type: RESET_ERROR
		})
		
	  }
	}, [newUserSuccess, subUserUpdate])
	


	useEffect(() => {
		let data ={ 
			"parent_id": user?.id
		}
		dispatch(getUserList(data))
	}, [])
	

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.paper',
		borderRadius: '25px',
		border: '1px solid #fff',
		p: 4,
		height: '671',
		width: '955'
	};

	const EditSeletedUser = (user) => {
		setSelected(user);
		setEditModalShow(true);
	}
	const [editModalShow, setEditModalShow] = useState(false);
	const [selected, setSelected] = useState(null)
	const DeleteSeletedUser = (user) => {
		setSelected(user);
		setDeleteModalShow(true);
	}
	const [deleteModalShow, setDeleteModalShow] = useState(false);

	const managePermissions = (user) => {
		navigate('/addrole', { state: user })
	}
	

	const columns = [
		{ field: 'id', headerName: '#ID', flex: 1, minWidth: 100 },
		{ field: 'name', headerName: 'USER NAME', flex: 1, minWidth: 200 },
		{ field: 'email', headerName: 'EMAIL', flex: 1, minWidth: 200 },
		{
			field: 'phone',
			headerName: 'PHONE',
			flex: 1,
			minWidth: 150
		},
		{
			field: 'created_at',
			headerName: 'DATE ADDED',
			flex: 1,
			renderCell: (params) => {
				return `${moment(params.row.created_at).format("DD-MM-YYYY hh:mm A") }`;
			},
		},
		{
			field: 'is_active',
			headerName: 'STATUS',
			flex: 1,
			minWidth: 150,
			renderCell: (params) => {
				return <Switch checked={params.row.is_active === 1 ? true: false} />
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'ACTIONS',
			flex: 1,
			cellClassName: 'actions',
			renderCell: (params) => {
				return (
					<>
					<span ><UserActions menuname={"OPTIONS"} params={params}/></span> 
						
						{/* <Button
							variant="text"
							color="primary"
							type="submit"
							onClick={() => DeleteSeletedUser(params?.row)}
						>
							<DeleteIcon />
						</Button> */}
					</>
				);
			},
		},
	];

	const [datas, setDatas] = useState([])
	const [filteruser, setFilterUser] = useState('')
	useEffect(() => {
		if (subUsers) {
			setDatas(subUsers)
		}
	}, [subUsers])
	
	useEffect(() => {
		if(filteruser){
			let lists = subUsers.filter(list => list.name.toLowerCase().includes(filteruser.toLowerCase()))
			setDatas(lists)
		}
		else
		{
			setDatas(subUsers)
		}
	}, [filteruser])
	const [addModalShow, setAddModalShow] = useState(false);

    const { newUserSuccessx, error, userx } = useSelector(state => state.auth)

    useEffect(() => {
        if (newUserSuccessx) {
            toast.success("New User added successfully");
            setAddModalShow(false)
            dispatch({
                type: RESET_ERROR
            })
        }

        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [newUserSuccessx, error])
	return (
		<div className='row'> 
			
			 <div className='col-sm-6'>
				<div style={{ paddingTop: 5, paddingBottom: 10, marginLeft:'-1%' }}>
				{/* <input type="text" onChange={(e) => setFilterUser(e.target.value)} value={filteruser} placeholder="Search User..." Icon={<SearchIcon/>} variant="outlined" width='43ch'  backgroundColor="none"/>	
				<InputIcon placeholder="Search User..." fieldName='search' value={filteruser} control={control}onChange={(e) => setFilterUser(e.target.value)} Icon={<SearchIcon/>} variant="outlined" width='43ch'  backgroundColor="none"/>
					*/}
					<TextField
					onChange={(e) => setFilterUser(e.target.value)} value={filteruser}
					style={{ backgroundColor: '#fff', justifyContent: 'center', marginLeft: 15, border: '1px solid #ccc', padding: 5 }}
					variant='standard' className='formText'
					sx={{
						"& .MuiFilledInput-underline:before": {
							borderBottom: "none"
						},
						"& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
							borderBottom: "none"
						},
					}}
						placeholder="Search User"
						InputProps={{
							disableUnderline: true,
							endAdornment: (
								<InputAdornment className='InputAdorn' >
									{<SearchIcon/>}
								</InputAdornment>
							),
							disableUnderline: true,
							//classes:{notchedOutline:c
							classes: {
								input: classes.resize
							}
						}}
					/>
				</div>
			</div>
			<div className='col-sm-6 text-end'>
				<div className='new-booking-btn'>
					<Button 
                         className='subm-buttoncls '
                        variant="contained" 
                        
                        style={{ color: 'white', backgroundColor: '#333333', borderRadius: 1, height: 40,textTransform:'capitalize' }}
                        onClick={()=>setAddModalShow(true)}
                        
                    >
                      ADD USER <span className='button-icon'><FontAwesomeIcon icon={faUser} /></span>
                    </Button>
				</div>
             </div>
			<div className='col-sm-12'>
				<div style={{ height: 450, width: "100%", border: 0, paddingTop: 10 }}>
					<DataGrid
						rows={datas ? datas : []}
						columns={columns}
						pageSize={10}
						rowsPerPageOptions={[10]}
						components={{
							NoRowsOverlay: () => (
							  <Stack height="100%" alignItems="center" justifyContent="center">
								No user found
							  </Stack>
							)
						  }}
					/>
				</div>
				{/* <Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell align="center">Username</TableCell>
							<TableCell align="center">Role</TableCell>
							<TableCell align="center">Date Added</TableCell>
							<TableCell align="center">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((user) => (
							<TableRow
								key={user.name}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell align="center">{user.user_name}</TableCell>
								<TableCell align="center">{user.role}</TableCell>
								<TableCell align="center">{user.date}</TableCell>
								<TableCell align="center"><EditIcon cursor="pointer" onClick={() => EditSeletedUser(user)} />&nbsp;<DeleteIcon onClick={() => DeleteSeletedUser(user)} cursor="pointer" /></TableCell>
							</TableRow>
						))}

					</TableBody>

					
				</Table> */}
			</div>
			{addModalShow && <AddUser openAddModal={addModalShow} closeAddModal={() => setAddModalShow(false)} />}
		</div>

	)
}

export default UserList
