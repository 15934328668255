import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, IconButton, Box } from '@mui/material'
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ErrorIcon from '@mui/icons-material/Error';
import InputField from '../../components/InputField'
import { fontWeight } from '@mui/system'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TopBar from '../../components/TopBar'
import BackupIcon from '@mui/icons-material/Backup';
import InputIcon from '../../components/InputIcon';
import LogoMockUp from '../../assets/images/Logo_mockup.jpg'
//import FlagInput from '../../components/FlagInput'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { toast } from 'react-toastify'
import { getProfile, updateProfile } from '../../Redux/actions/profileActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard,faUser,faPhone,faEnvelope,faArrowRightLong,faBuilding,faMoneyCheckDollar} from '@fortawesome/free-solid-svg-icons';
import * as yup from "yup";
import EditPassword from './EditPassword';
import { Camera, CameraAlt } from '@mui/icons-material';

import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
const PersonalDetails = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const { user } = useSelector(state => state.auth)
    const [datas, setDatas] = useState([])
    const [preview, setPreview] = useState(user?.image)
    const [img, setImg] = React.useState(false);
    const [country, setCountry] = useState(null);
    const [countrycompany, setCountryCompany] = useState(null)


    console.log({ user })

    const [countrycode, setCountrycode] = useState({
        callingCode: user?.country_code,
        phonenumber: user?.phone
    });

    const [countrycomcode, setCountryComcode] = useState({
        callingCode: user?.companyinfo?.company_phone_code,
        phonenumber: user?.companyinfo?.company_phone
    });


    console.log({ countrycode })
    console.log({ user })

    const fileRef = useRef(null)





    useEffect(() => {
        let id = user?.id
        dispatch(getProfile(id))
    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleCloseImg = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setImg(false);
    };

    const { updateSuccess, error } = useSelector(state => state.profile)
    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (updateSuccess) {
            setOpen(true)

            dispatch({
                type: RESET_ERROR
            })


        }

    }, [error, updateSuccess])
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;



    const schema = yup.object({
        name: yup.string().required('First Name is required').max(100, 'Maximum character exceeded'),
        lastname: yup.string().required('Last Name is required').max(100, 'Maximum character exceeded').typeError('Last Name is required'),
        mobile: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required').max(30, 'Maximum character exceeded'),
        email: yup.string().email('Invalid email format').required('Email is required').max(30, 'Maximum character exceeded'),
        company_name: yup.string().max(200, 'Maximum character exceeded').typeError('Maximum character exceeded'),
        company_email: yup.string().email().max(30, 'Maximum character exceeded').typeError('Maximum character exceeded'),
        company_tax: yup.string().max(30, 'Maximum character exceeded').typeError('Maximum character exceeded'),
        company_address: yup.string().max(60, 'Maximum character exceeded').typeError('Maximum character exceeded'),
    }).required();

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: user?.name,
            lastname: user?.lastname,
            email: user?.email,
            mobile: `${user.country_code?.replace('+', '')}${user.phone}`,
            phone: `${user.country_code?.replace('+', '')}${user.phone}`,
            company_name: user?.companyinfo?.company_name ? user?.companyinfo?.company_name : '',
            company_email: user?.companyinfo?.company_email ? user?.companyinfo?.company_email : '',
            company_phone: `${user.companyinfo?.company_phone_code?.replace('+', '')}${user.companyinfo?.company_phone}`,
            company_tax: user?.companyinfo?.company_tax === "undefined" ? "" : user?.companyinfo?.company_tax ? user?.companyinfo?.company_tax : '',
            company_address: user?.companyinfo?.company_address ? user?.companyinfo?.company_address : '',
        },
        mode: 'onChange'
    });

    const handleFileChange = (e) => {

        console.log({ e })
        let image = e.target.files && e.target.files[0];
        if (image && image.size < 2e6) {
            setValue("image", e.target.files[0])
            const objectUrl = URL.createObjectURL(e.target.files[0])
            setPreview(objectUrl)
        }
        if (image.size > 2e6) {
            setImg(true)

        }


    }

    const changePhone = (value) => {

        console.log({ value })
        setCountrycode(value)
    }

    const changeBussinessPhone = (value) => {
        setCountryComcode(value)
    }


    const onSubmit = (data) => {
        let formData = new FormData();
        if (data?.image) {
            formData.append("image", data.image);
        }

        formData.append("userID", user.id);
        formData.append("email", data.email);
        formData.append("name", data.name);
        formData.append("lastname", data.lastname);
        formData.append("phone", countrycode.phonenumber);
        formData.append("country_code", countrycode.callingCode);
        formData.append("mobile", countrycode.phonenumber);
        formData.append("is_active", 1);
        if (user?.role !== "customer" && !user?.parent_id) {
            formData.append("user_type", user?.role === "agent" ? 2 : user?.role === "corporate" ? 1 : 3);
            formData.append("company_name", data.company_name);
            formData.append("company_phone", countrycomcode?.phonenumber ? countrycomcode?.phonenumber : '');
            formData.append("company_phone_code", countrycomcode?.callingCode ? countrycomcode?.callingCode : '');
            // formData.append("company_phone", data.company_phone);
            formData.append("company_email", data.company_email);
            formData.append("company_address", data.company_address);
            formData.append("company_tax", data.company_tax);
        }
        dispatch(updateProfile(formData))
    };
    return (

        <Grid>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Profile Details updated Successfully
                </Alert>
            </Snackbar>
            <Snackbar open={img} autoHideDuration={2000} onClose={handleCloseImg}>
                <Alert onClose={handleCloseImg} severity="error" sx={{ width: '100%' }}>
                    Please upload a file less than 2 MB
                </Alert>
            </Snackbar>
            
            <div className='col-sm-12'>
                <div className='profile-img-sec'>
                    <div className='profile-img'>
                        <img src={preview ? preview : LogoMockUp} alt='alt' style={{ width: 200, height: 200, borderRadius: 200,marginBottom: 30, objectFit: 'cover' }} />
                        <IconButton style={{ backgroundColor: '#B6A269', top: 35, right: 0, position: 'absolute', width: 30, height: 30 }} onClick={() => fileRef.current.click()} >
                            <CameraAlt style={{ color: '#fff' }} />
                        </IconButton>
                   </div>
                    <input
                        style={{ display: 'none' }}
                        ref={fileRef}
                        type="file"
                        onChange={handleFileChange}
                    />
                </div>
                
            
                <form onSubmit={handleSubmit(onSubmit)}>
                    
                        <Box py={2} display={'flex'} justifyContent={'flex-end'} position={'absolute'} right={25} top={-40}>
                            
                        </Box>

                        <div style={{ paddingBottom: 20 }}>
                            <div class="booking-detailscard">
                                <h3 class="hd-text">Basic Details</h3>
                                <div className='row'>
                                    <div className='col-sm-6 pb-15'>
                                        <InputField
                                            fieldLabel="First Name"
                                            fieldName="name"
                                            control={control}
                                            error={errors.name}
                                            fieldIcon={<FontAwesomeIcon icon={faUser}/>}
                                        />
                                    </div>
                                    <div className='col-sm-6 pb-15'>
                                        <InputField
                                            fieldLabel="Last Name"
                                            fieldName="lastname"
                                            control={control}
                                            error={errors.lastname}
                                            fieldIcon={<FontAwesomeIcon icon={faUser}/>}
                                        />
                                    </div>
                                    <div className='col-sm-6 pb-15'>
                                        <InputField
                                            fieldLabel="Email Address"
                                            fieldName="email"
                                            fieldType="email"
                                            control={control}
                                            error={errors.email}
                                            fieldIcon={<FontAwesomeIcon icon={faEnvelope}/>}
                                        />
                                    </div>
                                    <div className='col-sm-6 pb-15'>
                                        <PhoneInputs
                                            fieldLabel="Mobile"
                                            fieldName="mobile"
                                            control={control}
                                            error={errors.mobile}
                                            onChangePhone={changePhone}
                                            fieldIcon={<FontAwesomeIcon icon={faPhone}/>}
                                        />
                                    </div>
                                    {/* <Grid item xs={12} md={4}>
                                        <PhoneInputs
                                            fieldLabel="Phone"
                                            fieldName="phone"
                                            control={control}
                                            error={errors.phone}
                                        />
                                    </Grid> */}
                                   
                                </div>
                            </div>
                        </div>
                        
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            {(user && user?.role !== "customer" && !user?.parent_id) && <>
                                <div class="booking-detailscard">
                                    <h3 class="hd-text">Company Info</h3>
                                    <div className='row'>
                                        <div className='col-sm-6 pb-15'>
                                            <InputField
                                                fieldLabel="Company Name"
                                                fieldName="company_name"
                                                control={control}
                                                error={errors.company_name}
                                                fieldIcon={<FontAwesomeIcon icon={faBuilding}/>}
                                            />
                                        </div>
                                        <div className='col-sm-6 pb-15'>
                                            <InputField
                                                fieldLabel="Company Email Address"
                                                fieldName="company_email"
                                                fieldType="email"
                                                control={control}
                                                error={errors.company_email}
                                                fieldIcon={<FontAwesomeIcon icon={faEnvelope}/>}
                                            />
                                        </div>
                                        <div className='col-sm-6 pb-15'>
                                            <PhoneInputs
                                                fieldLabel="Company Phone Number"
                                                fieldName="company_phone"
                                                control={control}
                                                error={errors.company_phone}
                                                onChangePhone={changeBussinessPhone}
                                                fieldIcon={<FontAwesomeIcon icon={faPhone}/>}
                                            />
                                        </div>
                                        <div className='col-sm-6 pb-15'>
                                            <InputField
                                                fieldLabel="Tax No."
                                                fieldName="company_tax"
                                                control={control}
                                                error={errors.company_tax}
                                                fieldIcon={<FontAwesomeIcon icon={faMoneyCheckDollar} />}
                                            />
                                        </div>
                                        <div className='col-sm-6 pb-15'>
                                            <InputField
                                                fieldLabel="Company Address"
                                                fieldName="company_address"
                                                multiline="multiline"
                                                control={control}
                                                error={errors.company_address}
                                                fieldIcon={<FontAwesomeIcon icon={faAddressCard} />}
                                            />
                                        </div>
                                    </div>
                                    </div>
                                    </>}


                        </div>
                        <div className='row'>
                        <div className='col-sm-12 text-right'>
                                        <div className='btn-sec'>
                                            <Button
                                                type="submit"
                                                variant="standard"
                                                sx={{ boxShadow: 3 }}
                                                className='subm-buttoncls'
                                                style={{ backgroundColor: '#b8a269', borderRadius: 30, marginTop: 10, width: 100, alignSelf: 'center', padding: 5, height: 40, marginTop: 30 }}>
                                                <Typography className='buttonText'>Save <span className='button-icon'><FontAwesomeIcon icon={faArrowRightLong} /></span></Typography>
                                            </Button>
                                        </div>
                                       
                                    </div>
                        </div>
                </form>
                <EditPassword />
            </div>
        </Grid>

    )
}

export default PersonalDetails
