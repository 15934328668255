import { CloseRounded } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, Divider, IconButton, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import reactotron from '../ReactronConfig'
import MainStop from '../screens/Summary/MainStop'
import SubStop from '../screens/Summary/SubStop'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
const VehicleCard = ({ booking, data, bookingType, trip, services, deleteBooking, mode }) => {

    const { markupLists } = useSelector(state => state.markup)
    const { enablegett, bookingData } = useSelector(state => state.booking)
    const { user, additionalStops, myTransfer } = useSelector(state => state.auth)


    reactotron.log({booking})

    //let tax = 0, total = 0;

    let total = 0;
    let returnTotal = 0

    if (mode === "current") {
        if (enablegett) {
            let extraRate = 0;
            let rate = 0;
            let adminMarkup = 0;
            let agentmarkup = 0;

            let tax = 0;

            let extras = [];

            rate = parseFloat(data?.price);
            data?.extras.map(ext => {
                if (ext?.extraName === "Child seat" && booking?.childrens > 0) {
                    extraRate += parseFloat(ext?.price) * parseFloat(booking?.childrens);
                    extras.push({ extraId: ext?.extraId, amount: booking?.childrens })
                }
                else if (ext?.extraName === "Booster seat" && booking?.minors > 0) {
                    extraRate += parseFloat(ext?.price) * parseFloat(booking?.minors);
                    extras.push({ extraId: ext?.extraId, amount: booking?.minors })
                }

            })
            if (myTransfer && myTransfer?.value) {
                if (myTransfer?.value_type === "Percentage") {
                    adminMarkup = (rate / 100) * parseFloat(myTransfer?.value)
                }
                else {
                    adminMarkup = myTransfer?.value ? parseFloat(myTransfer?.value) : 0
                }
            }


            if (user?.role === "agent" && markupLists?.type === "fixed") {

                if (markupLists?.status === 1) {
                    agentmarkup = parseFloat(markupLists?.value);
                }

                if (myTransfer?.tax) {
                    tax = ((rate + adminMarkup + extraRate + agentmarkup) / 100) * parseFloat(myTransfer?.tax)
                }

                



                total = rate + adminMarkup + extraRate + agentmarkup + tax;
            }
            else {
                if (user?.role === "agent" && markupLists?.status === 1) {
                    agentmarkup = (rate / 100) * parseFloat(markupLists?.value)
                }

                if (myTransfer?.tax) {
                    tax = ((rate + adminMarkup + extraRate + agentmarkup) / 100) * parseFloat(myTransfer?.tax)
                }

                total = rate + adminMarkup + extraRate + agentmarkup + tax;
            }
        }
        else {
            let agentmarkup = 0;
            let adminMarkup = 0
            let tax = 0;
            let returnTax = 0;
            let additionalStop = 0;
            let baserate = 0;
            let extras = data?.extraRate;
            let extraReturn = data?.extrasReturn
            let adminDiscount = 0;
            let extraCharges = data?.extraCharges;
            let extraReturnCharges = data?.extraChargesReturn
            let addonService = 0;
            let toll = data?.tollrate ? parseFloat(data?.tollrate) : 0;
            addonService = services ? services?.reduce((a, b) => a + parseFloat(b?.price), 0) : 0
            additionalStop = booking?.stops ? additionalStops ? booking?.stops?.length * additionalStops : booking?.stops?.length * 20 : 0;
            baserate = booking?.type === "hour" ? (parseFloat(data?.rate) * parseFloat(booking?.duration)) + additionalStop : parseFloat(data?.rate) + additionalStop

            // extraCharges = data?.addonItem?.map(extra => {
            //     if (extra?.rate_type === "percentage") {
            //         extras += booking?.type === "hour" ? (((parseFloat(baserate) / 100) * parseFloat(extra?.rate)) * parseFloat(booking?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
            //     }
            //     else {
            //         extras += booking?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(booking?.duration)) : parseFloat(extra?.rate)
            //     }
            // })

            if (user?.role === "agent" && markupLists?.type === "fixed") {
                if (markupLists?.status === 1) {
                    agentmarkup = parseFloat(markupLists?.value);
                }

                if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                    if (data?.markUpDetails[0]?.value_type === "Percentage") {
                        adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.markUpDetails[0]?.value)
                    }
                    else {
                        adminMarkup = parseFloat(data?.markUpDetails[0]?.value)
                    }
                }
                else if (data?.defaultMarkup) {
                    adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.defaultMarkup?.value)
                }

                if(data?.discountsData){
                    let ddata = data?.discountsData;
                    let totalAmount = baserate + adminMarkup + agentmarkup + toll + extras + addonService;
                    if(ddata?.discount_type === "percentage"){
                        adminDiscount = (totalAmount/100) * parseFloat(ddata?.discount)
                    }
                    else{
                        adminDiscount += parseFloat(ddata?.discount)
                    }
                }



                tax = ((baserate + adminMarkup + agentmarkup + extras + addonService + toll - adminDiscount) / 100) * parseFloat(data?.tax?.value)

                returnTax = ((baserate + adminMarkup + agentmarkup + extraReturn + addonService + toll - adminDiscount) / 100) * parseFloat(data?.tax?.value)
                returnTotal = baserate + adminMarkup + agentmarkup + extraReturn + returnTax + addonService + toll - adminDiscount;

                total = baserate + adminMarkup + agentmarkup + extras + tax + addonService + toll - adminDiscount;


            }
            else {
                if (user?.role === "agent" && markupLists?.status === 1) {
                    agentmarkup = ((baserate + extras + addonService + toll) / 100) * parseFloat(markupLists?.value)
                }

                if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                    if (data?.markUpDetails[0]?.value_type === "Percentage") {
                        adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.markUpDetails[0]?.value)
                    }
                    else {
                        adminMarkup = parseFloat(data?.markUpDetails[0]?.value)
                    }
                }
                else if (data?.defaultMarkup) {
                    adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.defaultMarkup?.value)
                }

                if(data?.discountsData){
                    let ddata = data?.discountsData;
                    let totalAmount = baserate + adminMarkup + agentmarkup + toll + extras + addonService;
                    if(ddata?.discount_type === "percentage"){
                        adminDiscount = (totalAmount/100) * parseFloat(ddata?.discount)
                    }
                    else{
                        adminDiscount += parseFloat(ddata?.discount)
                    }
                }

                tax = ((baserate + adminMarkup + agentmarkup + extras + addonService + toll - adminDiscount) / 100) * parseFloat(data?.tax?.value)

                total = baserate + adminMarkup + agentmarkup + extras + tax + addonService + toll - adminDiscount;

                returnTax = ((baserate + adminMarkup + agentmarkup + extraReturn + addonService + toll - adminDiscount) / 100) * parseFloat(data?.tax?.value)
                returnTotal = baserate + adminMarkup + agentmarkup + extraReturn + returnTax + addonService + toll - adminDiscount;
            }
        }


    }



    console.log({ bookingData }, 'vechicle card........')

    return (
        <>
         <div className='trip-details'>
            <div className='trip-hd'>
            <h3> Trip {trip}</h3>
            <p className='trip-type'>{data?.vehicledetails?.vehicle_type?.vehicle_type}{bookingData?.duration &&
              <span> {`${bookingData.duration}Hrs`}</span>}</p>
            </div>
                
                    <div className='trip-close-btn'>
                    {deleteBooking && <IconButton onClick={deleteBooking}>
                        <CloseRounded className='' style={{ color: '#fff' }} />
                    </IconButton>}
                    </div>
                <div className='trip-route'>
                    
                    <p><span className='place'>{booking?.location_from?.address}
                    
                    {booking?.type === 'point' &&
                        <div className='d-none' >
                            {booking?.stops?.map((stop, index) => (
                                <SubStop
                                    address={stop?.stop_name?.address}
                                    count={index + 2}
                                    color="blue"
                                    index={index}
                                    //removeStop={(index) => removeExtraStop(index)}
                                    stopNote={stop?.stopNote}
                                />
                            ))}
                        </div>}
                        </span><span className='place-icon'><FontAwesomeIcon icon={faArrowRightLong} /></span>
                    {booking?.location_to?.address && <span className='place'>{booking?.location_to?.address}</span>}</p>
                </div>
                <div className='dptr-sec'>
                
                            <p className=''>{booking?.type === 'point' ? booking?.location_from?.type === "airport" ? 'Arrival' : booking?.location_to?.type === "airport" ? 'Departure' : 'Point to Point' : 'Hourly'}
                       : {moment(booking?.pickupdate).format("DD-MM-YYYY")} / {moment(booking?.pickupdate).format("hh:mm A")}</p>
                </div>
                
                <div className='trip-rate'><h2><span>Fare</span> S${mode === "current" ? total.toFixed(2) : (parseFloat(data?.total)).toFixed(2)}</h2></div>
            </div>
      
        {(booking?.bookingType === "return" && mode === "current") && 
        
        <div className='trip-details'>
        <h3> Trip {trip + 1}</h3>
        <div className='trip-close-btn'>
        {deleteBooking && <IconButton onClick={deleteBooking} style={{ position: 'absolute', right: 5 }}>
                        <CloseRounded style={{ color: '#fff' }} />
                    </IconButton>}
            </div>
        <div className='trip-route'>
            
            <p><span className='place'>{booking?.location_to?.address}
            
            {booking?.type === 'point' &&
                        <div >
                        {booking?.stops?.map((stop, index) => (
                                <SubStop
                                    address={stop?.stop_name?.address}
                                    count={index + 2}
                                    color="blue"
                                    index={index}
                                    //removeStop={(index) => removeExtraStop(index)}
                                    stopNote={stop?.stopNote}
                                />
                            ))}
                        </div>}
                </span><span className='place-icon'><FontAwesomeIcon icon={faArrowRightLong} /></span>
                {booking?.location_from?.address && <span className='place'>{booking?.location_from?.address}</span>}</p>
        </div>
        <div className='dptr-sec'>
        <p className='trip-type'>{data?.vehicledetails?.vehicle_type?.vehicle_type}{bookingData?.duration &&
                    <span> {`${bookingData.duration}Hrs`}</span>}</p>
            <p className='clr_white'>{booking?.type === 'point' ? booking?.location_to?.type === "airport" ? 'Arrival' : booking?.location_from?.type === "airport" ? 'Departure' : 'Point to Point' : 'Hourly'}
               : {moment(booking?.returndate).format("DD-MM-YYYY")} / {moment(booking?.returndate).format("hh:mm A")}</p>
        </div>
        
        <div className='trip-rate'><h2><span>Fare</span> S${mode === "current" ? returnTotal.toFixed(2) : (parseFloat(data?.total)).toFixed(2)}</h2></div>
    </div>
        
        
        
        
        
        
        
        }
        </>
    )
}

export default VehicleCard
