import moment from "moment";
import { isArray } from "lodash";

export function calculateExtra(item, date, type, duration, rate, mode){
        let extras = 0 ;
        let extraArray = [];
        if(isArray(item?.extraChargesNew)){
            item?.extraChargesNew?.map(extra => {
    
                let rateType = extra?.rate_type
                //text
                if(extra?.input_type === "text"){
                    
                    if(rateType === "percentage"){
                        extras += type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)

                        extraArray.push({
                            id: extra?.add_on_item_id,
                            name: extra?.addonitem?.name,
                            price: type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
                        })
                    }
                    else if(rateType === "flatrate"){
                        extras += type === "hour" ? (parseFloat(extra?.rate) * parseFloat(duration)) : parseFloat(extra?.rate)
                        extraArray.push({
                            id: extra?.add_on_item_id,
                            name: extra?.addonitem?.name,
                            price: type === "hour" ? (parseFloat(extra?.rate) * parseFloat(duration)) : parseFloat(extra?.rate)
                        })
                    }
                    else if(rateType === "times"){
                        extras += type === "hour" ? ((rate * parseFloat(extra?.rate)) * parseFloat(duration)) : rate * parseFloat(extra?.rate)
                        extraArray.push({
                            id: extra?.add_on_item_id,
                            name: extra?.addonitem?.name,
                            price: type === "hour" ? ((rate * parseFloat(extra?.rate)) * parseFloat(duration)) : rate * parseFloat(extra?.rate)
                        })
                    }
                }
    
                //date
                if(extra?.input_type === "date"){
                    const date1 = moment(extra?.label1, "MM/DD/YYYY");
                    let checkTime = moment(date)
                    // Check if the time is between the start and end times
                    const isBetween = moment(date1).isSame(checkTime, 'day')
                    let rateType = extra?.rate_type
    
                    //console.log({isBetween}, "date", date1, checkTime)
    
                    if(isBetween){
                        if(rateType === "percentage"){
                            extras += type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
                            })
                        }
                        else if(rateType === "flatrate"){
                            extras += type === "hour" ? (parseFloat(extra?.rate) * parseFloat(duration)) : parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? (parseFloat(extra?.rate) * parseFloat(duration)) : parseFloat(extra?.rate)
                            })
                        }
                        else if(rateType === "times"){
                            extras += type === "hour" ? (rate * parseFloat(extra?.rate) * parseFloat(duration)) : parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? ((rate * parseFloat(extra?.rate)) * parseFloat(duration)) : rate * parseFloat(extra?.rate)
                            })
                        }
                    }
                }
    
    
                //timerange
                if(extra?.input_type === "timerange"){
                    let startTime, endTime;
                    let day = moment(date).format("A");
                    if(day?.toLowerCase().includes("am")){
                        startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${extra?.label1}`, "DD-MM-YYYY hh:mm A").add(-1, 'day');
                        endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${extra?.label2}`, "DD-MM-YYYY hh:mm A")
                    }
                    else{
                        startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${extra?.label1}`, "DD-MM-YYYY hh:mm A")
                        endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${extra?.label2}`, "DD-MM-YYYY hh:mm A").add(1, 'day');;

                    }
                    // let startTime, endTime;
                    // if(extra?.label1?.toLowerCase().contains("pm") && extra?.label2?.toLowerCase().contains("pm")){
                    //     startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${extra?.label1}`, "DD-MM-YYYY hh:mm A");
                    //     endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${extra?.label2}`, "DD-MM-YYYY hh:mm A");
                    // }
                    // else if(extra?.label1?.toLowerCase().contains("pm") && extra?.label2?.toLowerCase().contains("am")){
                    //     let newDate = moment(date).format("hh:mm A").toLowerCase().contains("pm")
                    //     if(newDate){
                    //         startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${extra?.label1}`, "DD-MM-YYYY hh:mm A").add('-1', 'day');
                    //         endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${extra?.label2}`, "DD-MM-YYYY hh:mm A");
                    //     }
                    //     startTime = moment(`${moment(date).format("DD-MM-YYYY")} ${extra?.label1}`, "DD-MM-YYYY hh:mm A");
                    //     endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${extra?.label2}`, "DD-MM-YYYY hh:mm A");
                    // }
                    //const endTime = moment(`${moment(date).format("DD-MM-YYYY")} ${extra?.label2}`, "DD-MM-YYYY hh:mm A").add(1, "day");
                    let checkTime = moment(date)
                    // Check if the time is between the start and end times
                    const isBetween = checkTime.isBetween(startTime, endTime);
                    
                    //console.log({isBetween, startTime: startTime.format("DD-MM-YYYY hh:mm A"), endTime: endTime.format("DD-MM-YYYY hh:mm A")})
    
                    if(isBetween){
                        if(rateType === "percentage"){
                            extras += type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
                            })
                        }
                        else if(rateType === "flatrate"){
                            extras += type === "hour" ? (parseFloat(extra?.rate) * parseFloat(duration)) : parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? (parseFloat(extra?.rate) * parseFloat(duration)) : parseFloat(extra?.rate)
                            })
                        }
                        else if(rateType === "times"){
                            extras += type === "hour" ? ((rate * parseFloat(extra?.rate)) * parseFloat(duration)) : rate * parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? ((rate * parseFloat(extra?.rate)) * parseFloat(duration)) : rate * parseFloat(extra?.rate)
                            })
                        }
                    }
                }
    
    
                //daterange
                if(extra?.input_type === "daterange"){
                    let dates = extra?.label1.split('-')
                    let date1 = moment(dates[0].trim(), "DD/MM/YYYY");
                    let date2 = moment(dates[1].trim(), "DD/MM/YYYY");
    
                    const isBetween =moment(date).isBetween(date1, date2, null, '[]');
    
    
                   //console.log({isBetween})
                    
    
                    if(isBetween){
                        if(rateType === "percentage"){
                            extras += type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
                            })
                        }
                        else if(rateType === "flatrate"){
                            extras += type === "hour" ? (parseFloat(extra?.rate) * parseFloat(duration)) : parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? (parseFloat(extra?.rate) * parseFloat(duration)) : parseFloat(extra?.rate)
                            })
                        }
                        else if(rateType === "times"){
                            extras += type === "hour" ? ((rate * parseFloat(extra?.rate)) * parseFloat(duration)) : rate * parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? ((rate * parseFloat(extra?.rate)) * parseFloat(duration)) : rate * parseFloat(extra?.rate)
                            })
                        }
                    }
                }
    
    
                //multidate
                if(extra?.input_type === "multidate"){
                    let dates = extra?.label1.split(',');
                    let isBetween;
                    dates?.map(da => {
                        //console.log({first: moment(da, "MM/DD/YYYY").format("DD-MM-YYYY"), date: moment(bookingData?.pickupdate).format("DD-MM-YYYY"), da})
                        if (moment(date).isSame(moment(da, "MM/DD/YYYY"), 'day')) {
                            //console.log("same")
                            isBetween = true
                        }
                    })
                    if(isBetween){
                        if(rateType === "percentage"){
                            extras += type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? ((parseFloat(rate) / 100) * parseFloat(extra?.rate) * parseFloat(duration)) : (parseFloat(rate) / 100) * parseFloat(extra?.rate)
                            })
                        }
                        else if(rateType === "flatrate"){
                            extras += type === "hour" ? (parseFloat(extra?.rate) * parseFloat(duration)) : parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? (parseFloat(extra?.rate) * parseFloat(duration)) : parseFloat(extra?.rate)
                            })
                        }
                        else if(rateType === "times"){
                            extras += type === "hour" ? (rate * parseFloat(extra?.rate)) : parseFloat(extra?.rate)
                            extraArray.push({
                                id: extra?.add_on_item_id,
                                name: extra?.addonitem?.name,
                                price: type === "hour" ? ((rate * parseFloat(extra?.rate)) * parseFloat(duration)) : rate * parseFloat(extra?.rate)
                            })
                        }
                    }
                }
            })
        }

        if(mode === "price"){
            return extras;
        }
        else{
            return extraArray;
        }
}