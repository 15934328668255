const CarouselCard = ({ name, img, cardStyle }) => {
  return (
    <div className={`my-card ${cardStyle}`}>
        <img src={img} alt={name} />
        
      </div>
    );
  };
  


export default CarouselCard