import React, { Fragment } from 'react'
import { FormGroup } from '@mui/material';
import { TextField, Typography } from '@mui/material'
import { Controller } from "react-hook-form";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
    noBorder: {
        borderRadius: 0,
        border: 0,
    },
    resize: {
        fontSize: 12,
        lineHeight: 1.2
    },
}));

const DateOnlyInput = ({ fieldName, fieldType, fieldLabel, minDate, width, fontSize, control, error, children, marginRight, maxDate, onDateChange }) => {

    const [value, setValue] = React.useState(null);

    const changeDateValue = (value) => {
        setValue(value)
     
        //changeDate(value.format("DD-MM-YYYY hh:mm A"))
    }



    const classes = useStyles();


    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormGroup>
                <Typography className='formText' sx={{ fontSize: '12px' }} >{fieldLabel}</Typography>

                <Controller
                    name={fieldName}
                    fullWidth
                    control={control ? control : null}
                    render={({ field: { onChange, value: newValue } }) =>
                        <DatePicker 
                            inputFormat='DD-MM-YYYY'
                            value={newValue}
                            minDate={minDate ? minDate : null}
                            maxDate={maxDate ? maxDate : null}
                            
          
                            onChange={(newValue) => {
                                onChange(newValue)
                           
                                changeDateValue(newValue);
                                onDateChange(newValue)
                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                defaultValue={null}
                                error={error?.message ? true : false}
                                
                                variant='standard'
                                style={{ backgroundColor: '#fff', border: '1px solid #ccc', fontSize: 12, borderRadius: 4, height: 40, paddingLeft: 5, paddingRight: 10, justifyContent: 'center', alignItems:'stretch' }}

                                sx={{
                                    "& .MuiFilledInput-underline:before": {
                                        borderBottom: "none"
                                    },
                                    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
                                        borderBottom: "none"
                                    },
                                }}
                                placeholder={fieldLabel}
                            >{children}
                            </TextField>}
                        />
                    } />
                {error && <Typography color={"red"} fontSize={10}>{error?.message}</Typography>}

            </FormGroup>
        </LocalizationProvider>
    )
}

export default DateOnlyInput