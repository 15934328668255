import React, {  useEffect } from 'react'
import './login.scss'
import { Grid, Typography, Button,  Box } from '@mui/material'
import InputField from '../../components/InputField'
import { Link, useNavigate, useNavigation } from "react-router-dom";
import GoogleImg from '../../assets/images/google.png'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { googleSignIn, loginUsers } from '../../Redux/actions/userActions'
import { toast } from 'react-toastify'
import { RESET_ERROR } from '../../Redux/constants/userConstants'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'
import HomeHeaderWrapper from '../../components/HomeHeaderWrapper'
import Footer from '../../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,faArrowRightLong} from '@fortawesome/free-solid-svg-icons';
const Login = () => {

	const dispatch = useDispatch();
 
	

	const login = useGoogleLogin({
		onSuccess: tokenResponse => {
			getUserProfile(tokenResponse.access_token)
		},
	});


	const getUserProfile = async(token) => {
		await axios
		.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json'
			}
		})
		.then((res) => {
			//setProfile(res.data);


			let data = {
				email: res?.data?.email,
				name: res?.data?.name
			}
			localStorage.setItem("loginType", "google");
			dispatch(googleSignIn(data))

		})
		.catch((err) => toast.error({err}));
	}

	const { loading, isAuthenticated, error } = useSelector(state => state.auth)

	const schema = yup.object({
		email: yup.string().email('Invalid email format').required('Email required').max(30,'Maximum character exceeded'),
		password: yup.string()
			.required('No password provided.')
			.min(8, 'Password should contain at least 8 characters').max(30,"Maximum character exceeded")
	}).required();

	const navigate = useNavigate();
	const { register, handleSubmit, control, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
		mode:'onChange'
	});

	useEffect(() => {
		if (error) {
			toast.error(error)
			dispatch({
				type: RESET_ERROR
			})
		}
		if (isAuthenticated) {
			navigate("/dashboard")
		}
	}, [error, isAuthenticated])


	const onSubmit = data => {
		localStorage.setItem("loginType", "form");
		dispatch(loginUsers(data))
	};

	

	return (
		<>
		<HomeHeaderWrapper />
		<div className="register-form registerfor-b2c">

			
				<section>
					<div className="register-holld">
						<div className="section-title">
				  			 <h2>Login</h2>
				  		 </div>
						<div className="content-wrap">
						<form onSubmit={handleSubmit(onSubmit)}>
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center' }}>
							
							<Box width={{ xs: "95%", md: '95%' }}>
							<div style={{ paddingBottom: 20 }}>
							<InputField
								fieldLabel="Email Address"
								fieldName="email"
								control={control}
								error={errors.email}
								width={{ xs: "95%", md: '95%' }}
								fieldIcon={<FontAwesomeIcon icon={faEnvelope}/>}
							/>
							</div>
							<div style={{ paddingBottom: 10 }}>
							<InputField
								fieldLabel="Password"
								fieldName="password"
								control={control}
								error={errors.password}
								width={{ xs: "95%", md: '95%' }}
								fieldType={"password"}
							/>
							</div>
							</Box>
							
						</div>
						<Box width={{ xs: "95%", md: '98%' }} display={"flex"} justifyContent="flex-end" paddingTop={"1px"} paddingBottom={1}>
							<Typography onClick={() => navigate("/forgotpassword")} style={{ fontFamily: 'Nunito', cursor: 'pointer', fontSize: 12, marginRight: 10,  }}>FORGOT PASSWORD?</Typography>
						</Box>
						<Grid  item xs={12} md={12}  style={{ display: 'flex', alignItems:'center',  justifyContent: 'center', gap:5, paddingTop: 30, marginBottom: 10 }}>
							<Button  variant="filled" onClick={() => login()}  style={{ backgroundColor: '#4286F5', borderRadius: 30, height: 36, width: 160, padding: 0, margin: 0, paddingLeft: 0.4 }}
								startIcon={<img src={GoogleImg} style={{width: 22, height: 22, margin: 0, paddingLeft: 2 }} />}>
								<Typography className='buttonText'> Google Sign In</Typography>
							</Button>

							<button type="submit" className="c-button sa_btn_cus subm-buttoncls" id="memberLoginBtn"
							>
								Login <span className='button-icon'><FontAwesomeIcon icon={faArrowRightLong} /></span>
							</button>						
						</Grid>
						<div className='new-user-sec'>
							<span style={{ fontFamily: 'Lato', display: 'block', fontWeight: 600, fontSize: 14, marginBottom: 10 }}>New user  Register here</span>
							<div className='rgstr-btns'>
								<button type="button" className="c-button sa_btn_cus" id="memberLoginBtn"
							onClick={() => navigate("/b2bregistration")} >Company</button> <span>/</span>
								<button type="button" className="c-button sa_btn_cus" id="memberLoginBtn"
							onClick={() => navigate("/customerregistration")} >Customer</button>
							</div>
						</div>
					</form>
							
						</div>
					</div>
				</section>			
			
		</div>
		<Footer/>
		</>
	)
}

export default Login
