import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import SendIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { tooltipClasses } from '@mui/material/Tooltip';
import EditUser from '../screens/User/EditUser';
import DeleteUser from '../screens/User/DeleteUser';
import { Settings } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import ViewTrip from '../screens/Dashboard/ViewTrip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {  Tooltip } from '@mui/material';

import { toast } from 'react-toastify';

import customAxios from '../CustomAxios';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#F6F6F6',
		color: '#333333',
		//boxShadow: theme.shadows[1],
		fontSize: 12,
		fontFamily: 'Nunito',
		fontWeight: '600',
		Padding: 0
	},
}));
const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className='action-dropdown'
      {...props}
    />
    ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      backgroundColor:'rgb(55, 65, 81)',
      marginTop: theme.spacing(0),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '0px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
    }));


const UserActions = ({menuname, params}) => {
	const navigate = useNavigate()
const [viewModalShow, setViewModalShow] = useState(false);
const [selected, setSelected] = useState(null)

const [loading, setLoading] = useState(false)


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    

    const EditSeletedUser = (user) => {
      setSelected(user);
      setEditModalShow(true);
    }
    const [editModalShow, setEditModalShow] = useState(false);
    const DeleteSeletedUser = (user) => {
      setSelected(user);
      setDeleteModalShow(true);
    }
    const [deleteModalShow, setDeleteModalShow] = useState(false);
  
    const managePermissions = (user) => {
      navigate('/addrole', { state: user })
    }

  return (
    <div>
        <Button className='settings-btn'
        
            id="basic-button"
               aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
           //expandIcon={<img src={ArrowDownImg} style={{width:42,height:42,borderRadius:'42px',backgroundColor:'white'}} />}
            onClick={handleClick}
            //endIcon={<SendIcon />}
            endIcon={open ? <SendIcon />:<KeyboardArrowRightIcon/>}
        >
            <FontAwesomeIcon icon={faGear} />
            
        </Button>
        <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            'class':'action-dropdown'
            }}
            
            
        >
        
        <Button
							variant="text"
							color="primary"
							type="submit"
              
							onClick={() => EditSeletedUser(params?.row)}
						>
							Edit User
						</Button>
						
            {params?.row?.is_active === 1 && <Button
							variant="text"
							color="primary"
							type="submit"
             
							onClick={() => managePermissions(params?.row)}
						>
							User Management
						</Button>}
					</StyledMenu>
           
          {editModalShow && <EditUser openEditModal={editModalShow} closeEditModal={() => setEditModalShow(false)} users={selected} />}
					{deleteModalShow && <DeleteUser openDeleteModal={deleteModalShow} closeDeleteModal={() => setDeleteModalShow(false)} users={selected} />}
    </div>
    
  )
}



export default UserActions
