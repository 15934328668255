import React, { useState } from 'react'
import { FormGroup, IconButton, InputAdornment } from '@mui/material';
import { TextField, Typography } from '@mui/material'
import { Controller } from "react-hook-form";
import { makeStyles } from '@mui/styles';
import { RemoveRedEye } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
	noBorder: {
	  borderRadius: 10,
	  border: 0,
	},
}));

const DateTimeInput = ({ fieldName, fieldType, fieldLabel, placeholder, width, fontSize, control, error, children, defaultValue, rows, multiline, min, readonly, helperText, required,minDate }) => {

    const classes = useStyles();

	return (
		<FormGroup sx={{ m: 1, marginTop: 2, width: width }}  >
			<Typography  className='formText' sx={{ fontSize: fontSize ? { fontSize } : '15px' }} >{fieldLabel}{required && <span style={{ color: 'red' }}>*</span>}</Typography>
			<Controller
				name={fieldName}
				control={control ? control : null}
				render={({ field }) => <TextField
                    
					disabled={readonly}
					size='small'
					helperText={helperText}
					defaultValue={defaultValue}
					error={error?.message ? true : false}
					multiline={multiline ? multiline : null}
					rows={rows ? rows : 0}
					InputProps={{
						inputProps: {
                            // min: "2022-10-19T00:00"
                            min: minDate
						},
						disableUnderline: true,
						classes:{notchedOutline:classes.noBorder},
					}}
					style={{ backgroundColor: '#F6F6F6',  }}
					{...field}
					variant='outlined' className='formText'
					sx={{
						"& .MuiFilledInput-underline:before": {
							borderBottom: "none"
						},
						"& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
							borderBottom: "none"
						}, borderRadius: '10px'
					}}
					type='datetime-local'
					placeholder={placeholder}
				>{children}</TextField>}
			/>
			{error && <Typography color={"red"} fontSize={10} className='label-error'>{error?.message}</Typography>}
		</FormGroup>
	)
}

export default DateTimeInput
