import * as React from 'react';
import { useNavigate,useRef } from 'react-router-dom';
import corp from '../assets/images/transfer/corp.png'
import airp from '../assets/images/transfer/airp.png'
import wed from '../assets/images/transfer/wed.png'
import phoneapp from '../assets/images/transfer/phone_app.png'
import gapp from '../assets/images/transfer/g_app.svg'
import aapp from '../assets/images/transfer/a_app.svg'
import image1 from '../assets/images/upload_file/vechicle/1582002107_mercedez.png'
import image2 from '../assets/images/upload_file/vechicle/1584436376_03.png'
import image3 from '../assets/images/upload_file/vechicle/1582263188_bmw.png'
import image4 from '../assets/images/upload_file/vechicle/1582278967_Accord-LX_honda.png'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAlternateLongArrowRight } from '@fortawesome/free-regular-svg-icons'
import CarouselCard from "./CarouselCard";

const HomeMiddlePortion = ({}) => {

  const navigate = useNavigate();

  
 const data = [
	{
	  name: "simon",
	  img: image1,
	},
	{
	  name: "neo",
	  img: image2,
	},
	{
	  name: "morpheus",
	  img: image3,
	},
	{
	  name: "trinity",
	  img: image4,
	},
  ];
  const delay = 2500;
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === data.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);
  const home = () => document.getElementById('scroller');
  const signup = () => document.getElementById('signupid');
  
  return (
    <>
   
   <div className="white_bg">
		<div className="container">
				<div className="sa_aboutcont">

				<div className="sa_title text-center">
					<h1>We Organize your Trip</h1>
				</div>
				<div className="">
					<div className="row mt-50">
						<div className="col-md-4 col-sm-4 col-xs-12">
							<div className="col-md-12 text-center">
								<img src={corp} alt="Corprate" />
							</div>
							<div class="col-md-12 text-center sa_subtitle">
								<h1>Cruise</h1>
								<p className="text-center">In contrast, the few dedicated transport-oriented ocean liners that remain typically transport passengers from one point to another, rather than on round trips.</p>
									</div>
						</div>
						<div className="col-md-4 col-sm-4 col-xs-12">
							<div className="col-md-12 text-center">
								<img src={airp} alt="Corprate" />
							</div>
							<div className="col-md-12 text-center sa_subtitle">
								<h1>Airport</h1>
								<p className="text-center">With airport car transport, you can carry your suitcases and hand luggage. Your vehicle will be ready and waiting as soon as you step off your flight.</p>
										</div>
						</div>
						<div className="col-md-4 col-sm-4 col-xs-12">
							<div className="col-md-12 text-center">
								<img src={wed} alt="Corprate" />
							</div>
							<div className="col-md-12 text-center sa_subtitle">
								<h1>Tuas Checkpoint</h1>
								<p className="text-center">Being far away from the main residential and the commercial district of Singapore, Tuas was chosen for industrial development as the adjacent industrial areas.</p>
								
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
<section className="container-fluid" id="sa_m_app">
	<div className="sa_app">
		<div className="row">
			<div className="col-xs-12 col-sm-6">
				<div className="sa_phone_app">
					<img src={phoneapp} alt='phoneimg'/>
				</div>
			</div>
			<div className="col-xs-12 col-sm-6 sa_app_content">
				<div>
				<div className="sa_app_title">
					<h1>Download the app</h1>
					<p>Easily book, change, or cancel rides on the go. Think of it as peace of mind in the palm of your hand.</p>
				</div>
				<ul>
					<li><a href="#" target="_blank"><img src={gapp} alt='phoneimg'/></a></li>
					<li><a href="#" target="_blank"><img src={aapp} alt='phoneimg'/></a></li>
				</ul>
				</div>
			</div>
		</div>
	</div>
</section>
<section className="slider_car">
		<div className="row">
		<div className="col-md-12 ">
			<div className="sa_title text-center">
				<h1>Popular Limo’z</h1>
				
			
			</div>
			
			<div
						className="card-carousel cus_translate"
						style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
					>
						{data.map((person, idx) => {
							let position = (index > idx) ? "next" : (index === idx ? 
								"active1" : "prev" );
								return <CarouselCard {...person} cardStyle={position} />;
						
							})}
					</div>		 

					 
					
				
			</div>
		</div>
</section>
<section className="best_off">
		
	<div className="row offer-card">
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
			<div className="sa_card-inner">
				<div className="sa_card_img">
					<img src={image1} />
				</div>
				<div className="sa_card_cont">
					<h2>Business className</h2>
					<ul>
						<li>Max: 5</li>
						<li>Max: 3</li>
					</ul>
					<div className="sa_car_rating rating">
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
            			</div>
					<a className='mt10' onClick={() =>{home().scrollIntoView({behavior: 'smooth'})}}>View More</a>
				</div>
				
			</div>
		</div>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
			<div className="sa_card-inner">
				<div className="sa_card_img">
					<img src={image2} />
				</div>
				<div className="sa_card_cont">
					<h2>First className</h2>
					<ul>
						<li>Max: 5</li>
						<li>Max: 2</li>
					</ul>
					<div className="sa_car_rating rating">
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
            			</div>
					<a className='mt10' onClick={() =>{home().scrollIntoView({behavior: 'smooth'})}}>View More</a>
				</div>
				
			</div>
		</div>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
			<div className="sa_card-inner">
				<div className="sa_card_img">
					<img src={image3} />
				</div>
				<div className="sa_card_cont">
					<h2>Business Van</h2>
					<ul>
						<li>Max: 5</li>
						<li>Max: 5</li>
					</ul>
					<div className="sa_car_rating rating">
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
            			</div>
					<a className='mt10' onClick={() =>{home().scrollIntoView({behavior: 'smooth'})}}>View More</a>
				</div>
				
			</div>
		</div>
		<div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
			<div className="sa_card-inner">
				<div className="sa_card_img">
					<img src={image4} />
				</div>
				<div className="sa_card_cont">
					<h2>Mini Car</h2>
					<ul>
						<li>Max: 10</li>
						<li>Max: 3</li>
					</ul>
					<div className="sa_car_rating rating">
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
                            <span className="glyphicon glyphicon-star"></span>
            			</div>
					<a className='mt10' onClick={() =>{home().scrollIntoView({behavior: 'smooth'})}}>View More</a>
				</div>
			</div>
		</div>
		
	</div>
	<div className="sa_quote">
		<div>
			<h3>Ready To Go Drive</h3>
			<div>
				<a  onClick={() =>{signup().scrollIntoView({behavior: 'smooth'})}}> 
					<button type="button" className="c-button pointSearch sa_btn_cus c-button" style={{'width':'200px'}}>Register/Sign Up </button>
				</a>
			</div>
		</div>
	</div>

</section>

</div>

    
      </>
  );
};
export default HomeMiddlePortion;
