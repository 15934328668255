import React, { useState } from 'react'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './style.scss'
import { Button, Grid, Typography } from '@mui/material'
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ErrorIcon from '@mui/icons-material/Error';
import InputField from '../../components/InputField'
import { fontWeight } from '@mui/system'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TopBar from '../../components/TopBar'
import PersonalDetails from './PersonalDetails'
import {  useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
const Profile = ({ head }) => {
    const navigate = useNavigate()
    return (
        <Layout head={head}>
            <div className='container'>
            {/* <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflow: 'hidden' }}>
                <h1 className='subheading'><span class='underline'>Personal</span> Details</h1>
            </Grid> */}
            <Grid>
                {/* <h1 class='underline'><span style={{ textDecorationLine:'underline', textDecorationColor:'#B6A269' }}>Personal</span> Details</h1> */}
                <div className='col-sm-12'>
                    <div class="booking-top">
                        <div class="row">
                            <div class="col-xs-8">
                                <h2>Profile Details</h2>
                            </div>
                            <div className='col-xs-4 text-right'>
                                    <a onClick={() => navigate(-1)} className='btn back-btn'><span className='button-icon'><FontAwesomeIcon icon={faArrowLeftLong} /></span>Back</a>
                                </div>
                        </div>
                    </div>
                </div>
            </Grid>
            <PersonalDetails />
            </div>
        </Layout>
    )
}

export default Profile
