import * as React from 'react';
import Logo from '../assets/images/logo.png'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import  { useSelector,useDispatch } from 'react-redux'
import { BOOKING_DATA, REMOVE_ORDER_ITEM, RESET_BOOKING } from '../Redux/constants/bookingConstants';
import  { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import DropDownMenu from './DropDownMenu';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import TopMenu from './TopMenu';

import { RESET_USER } from '../Redux/constants/userConstants';
export default function Hamburger({ isOpen },props){

	const { window, auth } = props;
    const { user, isAuthenticated } = useSelector(state => state.auth)
	// const dispatch = useDispatch();
  const location = useLocation()
	
	const [navItems, setNavItems] = useState([])
	const [loginType, setLoginType] = useState(null)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  useEffect(() => {
    let items = null;
    if (user) {
        if (user?.role === "customer") {
            items = [
                {
                    name: 'MY BOOKINGS',
                    path: '/dashboard'
                },
                {
                    name: 'PROFILE',
                    path: '/profile'
                }
            ]
        }
        else if (user?.role === "agent") {
            items = [
                {
                    name: 'MY BOOKINGS',
                    path: '/dashboard'
                },
                {
                	name: 'E-WALLET',
                	path: '/wallet'
                },
                // {
                // 	name: 'TOP UP',
                // 	path: '/topup'
                // },
                {
                    name: 'PROFILE',
                    path: '/profile'
                }
            ]
        }
        else if (user?.role === "corporate") {
            items = [
                {
                    name: 'MY BOOKINGS',
                    path: '/dashboard'
                },
                {
                    name: 'PROFILE',
                    path: '/profile'
                }
            ]
        }

        setNavItems(items)
        let type = localStorage.getItem("loginType");
        setLoginType(type)
    }
}, [user])

  const [mobileOpen, setMobileOpen] = React.useState(false);


	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const navigatePage = async () => {

		dispatch({
			type: RESET_BOOKING
		})
		navigate(auth ? '/dashboard' : '/')
			
	}
 
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleNavMenu1 = () => {
    setAnchorElNav(null);
    navigate('/attraction')
  };
  const handleNavMenu2 = () => {
    setAnchorElNav(null);
    navigate('/transfer')
  };
  
 
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const resetdata = ()=>{
    dispatch({
      type: RESET_BOOKING,
    })
  
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleMenuOne = () => {
    // do something
    setOpen(false);
  };

  const handleMenuTwo = () => {
    // do something
    setOpen(false);
  };

	const logout = async () => {
		dispatch({
			type: RESET_USER
		})
		await localStorage.removeItem("user")
		//signOut()
		navigate('/signin')
	}

	const googleLogout = async () => {
		dispatch({
			type: RESET_USER
		})
		await localStorage.removeItem("user")
		//googleLogout()
		//signOut()
		navigate('/signin')
	}

	const container = window !== undefined ? () => window().document.body : undefined;


    return(
        <>
        <div className='responsive-menu'>
        {isAuthenticated && 
          <ul>
            <li>
              <div className='user-top'>
                <img></img>
                <div>
                
		
		
                  <span  className='user-top-name'> {user?.name}</span>
                  <span className='user-top-position'>{(user?.role === "agent" || user?.role === "corporate") && user?.companyinfo?.company_name === 'undefined' || undefined ? '' : user?.companyinfo?.company_name}
	                </span>
                </div>
              </div>
            </li>
          </ul>}
        
         
        {isAuthenticated && 
			<ul>
            {navItems && navItems?.map((item) => (
			<li onClick={() => navigate(item?.path)}>
				<a>{item.name}</a>
			</li>
		))}
       
		{user?.role !== "customer" && !user?.parent_id && <li><a><DropDownMenu menuname={"SETTINGS"} activeMenu={5} /></a></li>}
		{loginType === "form" && <li onClick={logout}>  <a>LOGOUT </a></li>}
		{loginType === "google" && <li onClick={() => googleLogout()}><a>LOGOUT</a></li>}
       
        </ul>	
        }
     {!isAuthenticated && <ul>
        <li><a onClick={()=>navigate('/signin')}>Sign In</a></li>
        <li >
                <a onClick={()=>navigate('/customerregistration')}>Customer Sign Up</a>
              </li>
              <li >
              <a onClick={()=>navigate('/b2bregistration')}>Company Sign Up</a>
              </li>
              </ul>}
       
       
        </div>
    
 </>
 )
}