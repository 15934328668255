import React, { useEffect } from 'react'
import TopBar from '../../components/TopBar'
import { Container } from '@mui/system'
import TableRow from '@mui/material/TableRow'
import Header from '../../components/Header'
import { useDispatch, useSelector } from 'react-redux'
import TopMenu from '../../components/TopMenu'
import { Box } from '@mui/material'
import Banner from '../../components/Banner'
import { useLocation } from 'react-router-dom'
import { CLEAR_PREVIOUS_ROUTE } from '../../Redux/constants/userConstants'
import HomeHeaderWrapper from '../../components/HomeHeaderWrapper'
import Footer from '../../components/Footer'
const linkElement = document.createElement("link");
linkElement.setAttribute("rel", "stylesheet");
linkElement.setAttribute("type", "text/css");
linkElement.setAttribute(
"href",
"https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css"
);
document.head.appendChild(linkElement);





const Layout = ({children, head}) => {

    const location = useLocation()
    const dispatch = useDispatch()
    const { isAuthenticated, previousRoute, user } = useSelector(state => state.auth)


    useEffect(() => {
        if(previousRoute){
            if(previousRoute === location.pathname){
                dispatch({
                    type: CLEAR_PREVIOUS_ROUTE
                })
            }
        }    
    }, [previousRoute])
    

    return (
        <>
            {/* { !user && <Header/>} */}
            <HomeHeaderWrapper/>
            <Banner heading={head}/>
            <Box 
                px={{ xs:2, sm:8, md:9, lg: 10, xl: 12 }}
                paddingBottom={10}
                
                >
                {children}
            </Box>   
          <Footer/>
        </>
    )
}

export default Layout
