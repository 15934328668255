import * as React from 'react';
import Logo from '../assets/images/logo.png'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import  { useSelector,useDispatch } from 'react-redux'
import { BOOKING_DATA, REMOVE_ORDER_ITEM, RESET_BOOKING } from '../Redux/constants/bookingConstants';
import  { useEffect, useState } from 'react';
import mainLogo from '../assets/images/b2b_b2cgbanewlogo-white.png';
import DropDownMenu from './DropDownMenu';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import TopMenu from './TopMenu';
import Hamburger from './Hamburger';
const HomeTopBar = ({activeMenu},props) => {

	const { window, auth } = props;
  const { user, isAuthenticated } = useSelector(state => state.auth)
	// const dispatch = useDispatch();
  const location = useLocation()
	
	const [navItems, setNavItems] = useState([])
	const [loginType, setLoginType] = useState(null)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
	useEffect(() => {
		let items = null;
		if (user) {
			if (user?.role === "agent") {
				items = [
					
					{
						name: 'E-Wallet',
						path: '/wallet'
					}
				]
			}
			

			setNavItems(items)
			let type = localStorage.getItem("loginType");
			setLoginType(type)
		}
	}, [user])

  const [mobileOpen, setMobileOpen] = React.useState(false);


	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const navigatePage = async () => {

		dispatch({
			type: RESET_BOOKING
		})
		navigate(auth ? '/dashboard' : '/')
			
	}
 
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleNavMenu1 = () => {
    setAnchorElNav(null);
    navigate('/attraction')
  };
  const handleNavMenu2 = () => {
    setAnchorElNav(null);
    navigate('/transfer')
  };
  
 
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const resetdata = ()=>{
    dispatch({
      type: RESET_BOOKING,
    })
  
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleMenuOne = () => {
    // do something
    setOpen(false);
  };

  const handleMenuTwo = () => {
    // do something
    setOpen(false);
  };

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () =>{
  
      setHamburgerOpen(!hamburgerOpen)
  }
  
	
  return (
    <>
    <nav id="main-menu" role="navigation" className="mainmenutop">
      <ul className="menumainHolder">

        <li id='signupid' className={`homemenu mainmnu  animatedto  `} style={{visibility: 'visible', animationDuration: '0.5s', animationDelay: '0s', animationName: 'slideInDown'}}>
            <a className="" ><span><em>Attraction </em></span><i className="b2c-hme-sprite menu_attractionico"></i></a>
          </li> 

          <li className={`homemenu mainmnu  animatedto ${location.pathname === '/home' ? "menuu__item--current" : ""} `} style={{visibility: 'visible', animationDuration: '0.5s', animationDelay: '0s', animationName: 'slideInDown'}}>
            <a className="" ><span>Transfer</span><i className="b2c-hme-sprite menu_carico"></i></a>
          </li>
          {isAuthenticated && <>
          {navItems && navItems?.map((item) => (
             <li className={`homemenu mainmnu  animatedto ${location.pathname === item?.path ? "menuu__item--current" : ""} `}  style={{visibility: 'visible', animationDuration: '0.5s', animationDelay: '0s', animationName: 'slideInDown'}} onClick={() => navigate(item.path)}>
             <a className="" ><span>{item.name}</span><i className="b2c-hme-sprite menu_carico"></i></a>
           </li>
            
             ))}
            </>
          }
          <li className="homemenu mainmnu emptysep float-right">
            <a href="javascript:void(0);" className="" ><span></span><i className=""></i></a>
          </li>


          
          {!isAuthenticated && <ul className="sa_login" >

          <li className="homemenu mainmnu mainmnuext hd-login mb-login b2bb2cLogin"><a href="javascript:void(0)" className="sa_user" onClick={()=>navigate('/signin')}><span><i className="b2c-hme-sprite menu_loginico"></i> &nbsp;</span></a>
            <ul className="menu mini leftm animated chainboard flipInX" data-animation-type="flipInX" data-animation-duration="0" data-animation-delay="0.1" >
              <li><a data-value="1" title="Member Login" className="soap-popupbox PopupClick">Member Login</a></li>
              <li><a data-value="2" title="Corporate Login" className="soap-popupbox PopupClick">Corporate  Login</a></li>
              <li><a className="soap-popupbox gba-hide" href="#member-login" id="MemberLoginPopupShow">Member Login</a></li>
              <li><a className="soap-popupbox gba-hide" href="#agent-login" id="AgentLoginPopupShow">Corporate Login</a></li>
            </ul>

          </li>
          <div className="dropdown">
          <button onClick={handleOpen}  className="homemenu mainmnu mainmnuext hd-signup b2bb2cSignup"><span>Sign Up</span><i class="b2c-hme-sprite menu_signupico"></i></button>
       
          {open ? (
            <ul className="menu signup-menu">
              <li className="menu-item">
                <button onClick={()=>navigate('/customerregistration')}>Customer Sign Up</button>
              </li>
              <li className="menu-item">
              <button onClick={()=>navigate('/b2bregistration')}>Company Sign Up</button>
              </li>
            </ul>
          ) : null}
        </div>
    
          {/* <li className="homemenu mainmnu mainmnuext hd-signup b2bb2cSignup"><a href="javascript:void(0)" onClick={()=>navigate('/customerregistration')}><span>Sign Up</span><i class="b2c-hme-sprite menu_signupico"></i></a>
            <ul className="menu mini animated chainboard flipInX" data-animation-type="flipInX" data-animation-duration="0" data-animation-delay="0.1" >
              <li><a href="register.html" title="Member Sign Up">Member Sign Up</a></li>
              <li><a href="corporateRegister.html" title="Corporate Sign Up">Corporate Sign Up</a></li>
              <li><a href="partnerRegister.html" title="Partner Sign Up">Partner Sign Up</a></li>
            </ul>
          </li> */}


        </ul> }
        </ul>
        {isAuthenticated && <TopMenu  />}






      
      </nav>
      <nav class="navbar navbar-default">
        <div class="container-fluid">

          <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false" onClick={() => toggleHamburger()}>
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <a onClick={()=>navigate('/')} class="navbar-brand" >
                    <img src={mainLogo} alt="Welcome"  />
                </a>
           
          </div>

   {hamburgerOpen && <Hamburger isOpen={hamburgerOpen}/>}
    <style jsx>{`
    @media (min-width: 768px)
    .navbar-toggle {
        display: block !important;
    }

@media (min-width: 1199px) {
  .button.navbar-toggle.collapsed {
    display: none !important;
  }
}





`}</style>
  </div>
</nav>
    {/* </nav><AppBar position="static" className='top'>
        <Container maxWidth="100%" height="97" width="100%" style={{ paddingLeft: 100, paddingRight: 100 }}>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <img src={Logo} style={{ width: 90, height: 50 }} onClick={resetdata} />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} style={{ justifyContent: 'end' }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon className='menuicon' />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <span>{activeMenu == '1' ? <Typography className='active'>HOME</Typography> : <Typography className='inactive'>HOME</Typography>}</span>
                <span><DropDownMenu menuname={"SERVICES"} activeMenu={activeMenu} /></span>
                <span>{activeMenu == '2' ? <Typography className='active'>BLOG</Typography> : <Typography className='inactive'>BLOG</Typography>}</span>
                <span><DropDownMenu menuname={"COMPANY"} activeMenu={activeMenu} /></span>
                <span>{activeMenu == '3' ? <Typography className='active'>CARBON OFFSET</Typography> : <Typography className='inactive'>CARBON OFFSET</Typography>}</span>
                <span><Typography className='inactive'>CONTACT US</Typography></span>
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
              }}
            >
              <img src={Logo} style={{ width: 90, height: 50 }} onClick={resetdata} />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <span>{activeMenu == '1' ? <Typography className='active'>HOME</Typography> : <Typography className='inactive'>HOME</Typography>}</span>
              <span><DropDownMenu menuname={"SERVICES"} activeMenu={activeMenu} /></span>
              <span>{activeMenu == '2' ? <Typography className='active'>BLOG</Typography> : <Typography className='inactive'>BLOG</Typography>}</span>
              <span><DropDownMenu menuname={"COMPANY"} activeMenu={activeMenu} /></span>
              <span>{activeMenu == '3' ? <Typography className='active'>CARBON OFFSET</Typography> : <Typography className='inactive'>CARBON OFFSET</Typography>}</span>
              <span><Typography className='inactive'>CONTACT US</Typography></span>
            </Box>
          </Toolbar>
        </Container>
            </AppBar></> */}
      </>
  );
};
export default HomeTopBar;
