import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Typography } from '@mui/material'
import './style.scss'
import CloseIcon from '@mui/icons-material/Close';


const SubStop = ({address, count, color,index,  removeStop, stopNote}) => {


  console.log({address})
  return (
    <div style={{ display: 'flex' ,flexDirection:'row' , alignItems:'center',gap: 1,paddingLeft:25}}>
        <span class="route-map subroute-map">
            <LocationOnIcon  style={{ width: 20, height: 'auto', color: '#B6A269' }} />
            <div style={{ fontSize: 10, width: 15, height: 15, borderRadius: '50%', backgroundColor: '#333', color: '#fff', display:'flex', justifyContent: 'center', alignItems: 'center',top: -3, right: -5, position: 'absolute'  }}>{count}</div>
        </span>
        <Typography fontSize={12} marginBottom={1}  className="text" style={{marginLeft: 5,wordBreak: "break-word",}}  >{address}</Typography>
        {removeStop && <span>
            <CloseIcon  style={{ width: 15, height: 15, background: '#dc0000', borderRadius: '50%', padding:'2px', color: '#fff', marginLeft: 5, cursor: 'pointer' }} onClick={()=> removeStop(index)} />
        </span>}
    </div>
  )
}

export default SubStop    