import React, { Fragment, useEffect } from 'react'
import Layout from '../Layout'
import './style.scss'

import { useLocation, useNavigate } from 'react-router-dom';
import DetailsForm from './DetailsForm'
import { useDispatch, useSelector } from 'react-redux'
import AddedVehicle from '../../components/AddedVehicle'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
const PassengerDetails = ({ head }) => {
    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { selectedVehicle, bookingData } = useSelector(state => state.booking)

    useEffect(() => {
      if(!selectedVehicle){
        getLocalStorageData()
      }
    }, [bookingData])

    const getLocalStorageData = async() => {
        let data = await localStorage.getItem("booking");
        if(data){
        //     let booking = JSON.parse(data);
        //     dispatch({
        //         type: BOOKING_DATA,
        //         payload: booking
        //     })
            navigate("/summary");
        }
        else{
            navigate("/newbooking");
        }
    }
    return (
        
        <Layout head={head}>
            {/* <WorkFlow activeStep={2} /> */}
            <div className='container'>
                <div className='row'>
                    <div className='col-xs-12'>
                        <div className='booking-top'>
                            <div className='row'>
                                <div className='col-sm-8'>
                                    {/* <h5>Step - 3</h5> */}
                                    <h2>Booking Details</h2>
                                </div>
                                <div className='col-sm-4 text-right'>
                                    <a onClick={() => navigate(-1)} className='btn back-btn'><span className='button-icon'><FontAwesomeIcon icon={faArrowLeftLong} /></span>Back</a>
                                </div>
                            </div>
                                   
                                
                                    
                                
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-8'>
                        <DetailsForm data={selectedVehicle} booking={bookingData} />
                    </div>
                    <div className='col-sm-4 pt50'>
                        <AddedVehicle />
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default PassengerDetails
