import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Divider, Typography } from '@mui/material'
import './style.scss'


const MainStop = ({ address, count, color }) => {
  return (
    <div className='test' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', height: 38 }}>
      <span className="route-map">
        <LocationOnIcon style={{ width: 25, height: 'auto', color: '#B6A269' }} />
        <div style={{ fontSize: 12, width: 20, height: 20, borderRadius: '50%', backgroundColor: color, color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', top: -5, right: -5, position: 'absolute' }}>{count}</div>

      </span>
      <Typography className='map-location' style={{fontFamily: 'Nunito', fontSize: 14, fontWeight: 700,  wordBreak: "break-word", minWidth: 200}}  >{address}</Typography>
    </div>
  )
}

export default MainStop