import React from 'react'
import {Button, FormControl, FormGroup, TextField} from '@mui/material';
import { Typography,Select } from '@mui/material'
import { Controller } from "react-hook-form";
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

const SearchBox = styled(TextField)(() => ({
	'& input': {
	  paddingLeft: '4px',
	  height: '15px',
	  paddingLeft: 2
	},
	'& fieldset': {
	  borderRadius: '4px',
	},
  }));
const SelectPassenger = ({fieldName,fieldLabel,width,fontSize,control,children, open, onOpen, onClose, values}) => {
    return (
        <FormGroup  style={{ marginRight: 5, marginLeft: 5}} >
            <Typography className='formText' sx={{ fontSize: '12px' }}  >{fieldLabel}</Typography>
            <FormControl fullWidth>
                <Controller
                    name={fieldName}
                    control={control ? control : null}
                    render={({ field }) =><Select 
                        labelId="level-label" 
                        {...field} 
                        open={open}
                        onOpen={onOpen}
                        onClose={onClose}
                        disableUnderline
                        style={{ backgroundColor: '#fff',   borderRadius: 4, border: '1px solid #ccc', width: '100%', height: 31, justifyContent: 'center' }}
                        variant="standard"  className='formText'
                        //components={<SearchBox />}
                        //input={<SearchBox />}
                        IconComponent={() => (
                            <Button variant='text' style={{ padding: 0, width: 20, margin: 0 }}>
                           <span className='iconlabel-cls'><FontAwesomeIcon icon={faUsers} onClick={onOpen}  /></span>
                            </Button>
                        )}
                    >
                        {children}
                    </Select>}
                />
                <Typography className='formText' style={{ position: 'absolute', top: 7, left: 10 }}  >{values}</Typography>
            </FormControl>
        </FormGroup>
    )
}

export default SelectPassenger
