import React, { useState, useEffect } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './style.scss'
import { Button, Grid, Box, FormGroup, Stack } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TopBar from '../../components/TopBar'
import UserList from './UserList'
import AddUser from './AddUser'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { RESET_ERROR } from '../../Redux/constants/userConstants'

const User = ({ head }) => {
    const AddNewUser = () => {
        setAddModalShow(true);
    }

    const dispatch = useDispatch()
    const [addModalShow, setAddModalShow] = useState(false);

    const { newUserSuccess, error, user } = useSelector(state => state.auth)

    useEffect(() => {
        if (newUserSuccess) {
            toast.success("New User added successfully");
            setAddModalShow(false)
            dispatch({
                type: RESET_ERROR
            })
        }

        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [newUserSuccess, error])




    return (

        <Layout head={head}>
            
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h1 class="hd-text" style={{ margin: '30px 0 10px' }}>User Details</h1>
                
                        <Box xs={6} md={5} pt={0}>
                            <UserList />
                        </Box>
                    </div>
                </div>
            
           
            </div>
        </Layout>
    )
}

export default User
