import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SendIcon from '@mui/icons-material/ExpandMore';
import { useNavigate,useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
    ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      backgroundColor:'rgb(55, 65, 81)',
      marginTop: theme.spacing(0),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '0px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
    }));


const DropDownMenu = ({menuname, activeMenu}) => {

  const location = useLocation()
    const { user } = useSelector(state => state.auth)
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleNavMenu5 = () => {
      setAnchorEl(null);
      navigate('/markup')
    };
    const handleNavMenu6 = () => {
      setAnchorEl(null);
      navigate('/user')
    };
    const handleNavMenu7 = () => {
      setAnchorEl(null);
      navigate('/role')
    };
   
  return (
    <div>
        <Button 
        
            id="basic-button"
            className={`setting-btn-cls ${(location.pathname === '/markup' || location.pathname === '/user') ? 'active' : 'inactive'} `}
            style={{ fontSize: '12px', color: (activeMenu === '5' || activeMenu === '6' || activeMenu === '7') ? '#fff' : '#0A2638' }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
           //expandIcon={<img src={ArrowDownImg} style={{width:42,height:42,borderRadius:'42px',backgroundColor:'white'}} />}
            onClick={handleClick}
            //endIcon={<SendIcon />}
            endIcon={open ? <SendIcon />:<KeyboardArrowRightIcon/>}
        >
            {menuname}
            
        </Button>
        <StyledMenu
            id="basic-menu"
            className='user-menu-cls'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            
        >
            {user?.role === "agent" && <MenuItem style={{ backgroundColor: '#333333',color:'#FFFFFF' }} onClick={handleNavMenu5}>Markup Management</MenuItem>}
            <MenuItem style={{ backgroundColor: '#333333',color:'#FFFFFF' }} onClick={handleNavMenu6}>User Management</MenuItem>
        </StyledMenu>
    </div>
  )
}



export default DropDownMenu
