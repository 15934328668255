
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import arrowPng from '../assets/images/footer_img/arrow.png';
import footerPng from '../assets/images/b2b_b2cgbanewlogo-footer.png'
import { useNavigate } from 'react-router-dom';
const Footer = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const navigate = useNavigate();
  function handleInput(event) {
    setEmail(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (email === "" || !/\S+@\S+\.\S+/.test(email)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
      alert(`Thank you for subscribing with ${email}`);
      setEmail("");
    }
  }
  return (
    <footer id="footer" style={{verticalAlign: 'bottom'}} className="footer footer-b2c">
    <div className="footer-wrapper">
        

<div className="container">
  <div className='row'>

  
   <div className="col-sm-12">
        <div className="footer-hold">
<div className='row'>
                  <div className="col-sm-12 col-md-4">
                      <img src={footerPng} alt="" className="footer-logo-img" />
                      <p className="padd-0 sa_decs">LIMO Z connects passengers to our vast network of licensed drivers and insured vehicles. Our fleet of newer model luxury vehicles are meticulously maintained and operated by courteous and professional drivers.</p>
                  </div>
                 <div className='col-xs-12 col-md-8'>
                  <div className='row'>
                    <div className="col-xs-6 col-md-6 text-left">
                        <div>
                          <div>
                            <h2 className="quick-links" >Quick Link</h2>
                            <ul className="footer-link">
                                <li className="text-left"><a href="aboutUs.html">About Us</a></li>
                                <li className="text-left"><a href="contactUs.html">Contact Us</a></li>
                                <li className="text-left"><a href="privacyPolicy.html">Privacy Policy</a></li> 
                                <li className="text-left"><a href="termsConditions.html">Terms &amp; Conditions</a></li>
                                <li className=""><a onClick={() => navigate("/")}>&nbsp;</a></li>
                            </ul>		
                          </div>
                          
                        </div>
                    </div>
                    <div className="col-xs-6 col-md-6 text-left">
                      <h2 className="quick-links" >Address</h2>
                        <div className="padd-0 sa_f_about">
                            <p className="">#01-02, High Street Centre,</p>
                            <p className="clr_them">+65 8180 8013</p>
                            <p className="">Singapore - 179094</p>
                            <p className="">booking@limo-z.sg</p>
                        </div>
                    </div>
                  </div>
                 </div>
                  {/* <div className="col-sm-6 col-md-4  newsletter b2cfriend-ftr">
                      <div className="col-xs-12 col-md-12">
                     
                                                  
                      
                          <ul className="social-icons clearfix">
                              <li className="facebook"><a title="Facebook" href="https://www.facebook.com/limo.z.sg/" data-toggle="tooltip" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a></li>	
                              <li className="twitter"><a title="Twitter" href="#" data-toggle="tooltip" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
                          </ul>
                      </div>
                  </div> */}

                  <div className="footer-s-top">
                      <a id="back-to-top" href="#" className="animated" data-animation-type="bounce"><i className="soap-icon-longarrow-up circle"></i></a>
                  </div>
                  </div>
        </div>
    </div>
    </div>
</div>
  <div className="container-fluid footer-all-copyright b2cfriend-ftr">
    <div className="copyright_wrapper">
      <div className='container'>
        <div className='row'>
          <div className="col-sm-6 col-md-6 col-xs-12">
            <div className='copyright'>
              <p>©2023 Limoz.com Pvt Ltd. All Rights Reserved</p>
            </div>
          </div>	
          <div className="col-sm-6 col-md-6 col-xs-12">
            <div className="sa_social">
              <ul>
                  <li><a href="#"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                  <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
                  <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li>
                  <li><a href="#"><FontAwesomeIcon icon={faLinkedin} /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
    </div>				
  </div>
</div>
</footer> 
  )
}

export default Footer