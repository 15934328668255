import React, { useState, useEffect, useMemo } from 'react'
import './style.scss'
import { Typography, MenuItem, Box, FormControl, FormLabel, Checkbox, FormGroup, Snackbar, Alert } from '@mui/material'

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
    Button
} from "@mui/material";
import InputField from '../../components/InputField';
import { useLocation } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DropdownInput from '../../components/DropdownInput';
import Add from '@mui/icons-material/Add';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DateInput from '../../components/DateInput';
import reactotron from '../../ReactronConfig';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_BOOKING, BOOKING_INPUT, LOADING, ORDER_DATA, PASSENGERS_DETAILS, RESET_BOOKING_DATA, RESET_ORDER_DATA, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants';
import uuid from 'react-uuid';
import PhoneInputs from '../../components/PhoneInputs';
import countryList from 'react-select-country-list'
import { isArray } from 'lodash';
import customAxios from '../../CustomAxios';
import { toast } from 'react-toastify';
import TimePicker from '../../components/TimePicker';
import { Image, Visibility, VisibilityOff } from '@mui/icons-material';
import ImageInput from '../../components/ImageInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,faUser,faPhone,faCar,faArrowsRotate} from '@fortawesome/free-solid-svg-icons';


const DetailsForm = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [services, setServices] = useState(null)
    const [open, setOpen] = useState(false);

    //console.log({ services })

    const options = useMemo(() => countryList().getData(), [])



    const { user, additionalStops } = useSelector(state => state.auth)
    const { orderData, passengers, bookingType, selectedBooking: booking, currentVehicle: data, selectedVehicle, airportType, itenary } = useSelector(state => state.booking)


    reactotron.log({ data })
    // console.log({ selectedVehicle })

    // const state = useSelector(state => state.booking)

    const { vehicleAvailability } = useSelector(state => state.mytransfer)
    const { markupLists } = useSelector(state => state.markup)


    //console.log({vehicleAvailability})









    const dispatch = useDispatch();

    const [methods, setMethods] = useState('')
    const [returnMethods, setReturnMethods] = useState('')
    const [country, setCountry] = useState('SG')
    const [arival, setArival] = useState('international');
    const [returnArival, setReturnArival] = useState('international');

    const [bufferList, setBufferList] = useState([]);
    const [buffer, setBuffer] = useState(null)
    const [returnBuffer, setReturnBuffer] = useState(null)
    const [loading, setLoading] = useState(false);
    const [countlaugage,setlaugagecount]=useState(null)






    const [flightArrival, setFlightArrival] = useState([
        {
            value: 15,
            name: '15 Mins After Flight Arrival'

        },
        {
            value: 30,
            name: '30 Mins After Flight Arrival'

        },
        {
            value: 45,
            name: '45 Mins After Flight Arrival'

        },
        {
            value: 60,
            name: '60 Mins After Flight Arrival'

        },
    ])


    const [cruiseArrival, setCruiseArrival] = useState([
        {
            value: 15,
            name: '15 Mins After Cruise Arrival'

        },
        {
            value: 30,
            name: '30 Mins After Cruise Arrival'

        },
        {
            value: 45,
            name: '45 Mins After Cruise Arrival'

        },
        {
            value: 60,
            name: '60 Mins After Cruise Arrival'

        },
    ])




    const uploadFiles = async (file) => {


        //console.log({ file })

        dispatch({
            type: LOADING,
            payload: true
        })

        let formData = new FormData();
        formData.append("itenary", file)

        await customAxios.post(`uploaditenary`, formData)
            .then(async response => {

                dispatch({
                    type: LOADING,
                    payload: false
                })
                dispatch({
                    type: BOOKING_INPUT,
                    payload: {
                        prop: 'itenary',
                        value: response?.data?.data
                    }
                })
            })
            .catch(async error => {
                dispatch({
                    type: LOADING,
                    payload: false
                })
                toast.error(error)

            });
    }

    useEffect(() => {
        if (data?.addOnServices) {
            let services = data?.addOnServices?.map(serv => {
                return {
                    id: serv?.id,
                    name: serv?.addonservice?.name,
                    price: serv?.rate
                }
            })

            setServices(services)
        }
    }, [])


    useEffect(() => {
        window.scrollTo(0, 300)
    }, [])



    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


    const schema = yup.object({
        passenger_name: yup.string().required("First name Required").max(40, 'Maximum character exceeded'),
        passenger_lname: yup.string().required("Last name Required").max(40, 'Maximum character exceeded'),
        mobile: yup.string().required("Phone Number Required").max(40, 'Maximum character exceeded'),
        email: yup.string().email('Invalid email format').required('Email required').max(40, 'Maximum character exceeded'),
        arrival_method: yup.string(),
        flight_no: vehicleAvailability?.pickupType === "airport" ? yup.string().required('Flight Number required') : yup.string(),
        luggage: yup.number().max(bookingType === "PAL" ? data?.vehicledetails?.vehicle_type?.no_check_in : selectedVehicle?.suitcases, "Maximum luggage has been exceeded").typeError(`Maximum luggage has been exceeded`).nullable(true).transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value),
        // arival: yup.string().typeError("Arival method required").when('arrival_method', (arrival_method, schema) => {
        //     return arrival_method === "flight" ? schema.required("Arrival method required")   : schema.string();
        // }),
        // flight_date: yup.string().typeError("Flight date & time required").when('arrival_method', (arrival_method, schema) => {
        //     return arrival_method === "flight" ? schema.required("Flight date & time required")   : schema.min(0);
        // }),
        //flightdate: yup.string().required("Flight date & time required")
        hand_carry: yup.number().max(bookingType === "PAL" ? data?.vehicledetails?.vehicle_type?.no_hand_carry : 99, "Maximum hand carry has been exceeded").typeError(`Maximum hand carry has been exceeded`).nullable(true).transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value),
    }).required();

    const { register, handleSubmit, control, formState: { errors }, getValues, setValue,setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: passengers ? passengers : {
            id: uuid(),
            flight_date: orderData?.flight_date ? orderData?.flight_date : moment(booking?.pickupdate).toDate(),
            country: 'SG',
            passenger_name: user?.role === "customer" ? user?.name : '',
            email: user?.role === "customer" ? user?.email : '',
            mobile: user?.role === "customer" ? `${user?.country_code}${user?.phone}` : '',
            arival: 'international',
            passenger_lname: user?.role === "customer" ? user?.lastname : ''
        },
        mode: 'onChange'
    });


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };



    const getBufferTime = async () => {
        setLoading(true);
        await customAxios.get(`auth/getbuffertime/${selectedVehicle.vehicle_type_id
            }`)
            .then(async response => {
                console.log({ response: response.data.data })
                setBuffer(response?.data?.data?.[0]?.buffer_time)
                setBufferList(response?.data?.data)
                setValue('time_related', response?.data?.data?.[0]?.buffer_time)
                setLoading(false);
            })
            .catch(async error => {
                setLoading(false);
                toast.error(error)

            });

    }
  
    useEffect(() => {
        if (booking?.location_from?.type === "airport") {
            getBufferTime();
        }

        if (booking?.location_from?.type === "cruise") {
            getBufferTime();
        }
    }, [booking?.location_from?.type])



    const onSubmit = (datas) => {

    
         

        if(bookingType === 'PAL' && countlaugage > data?.vehicledetails?.vehicle_type?.no_check_in){
            setOpen(true)
            //return false;
            // setError('luggage', { type: 'custom', message: 'Maximum luggage exceed' });
        }
        // dispatch({
        //     type: PASSENGERS_DETAILS,
        //     payload: datas
        // })
        if (bookingType === "PAL") {
            populateOrderData(datas)
        }
        else if (bookingType === "MYTRANSFER") {
            populateMyTransferData(datas)
        }
        dispatch({
            type: ADD_BOOKING
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'selectedService',
                value: null
            }
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'itenary',
                value: null
            }
        })

        if (user) {
            navigate('/payment')
        }
        else {
            navigate('/loginregister')
        }
        dispatch({
            type: RESET_BOOKING_DATA
        })
        localStorage.removeItem("booking");
    };

    const addMoreBooking = async (data) => {
        
        if(bookingType === 'PAL' && countlaugage > data?.vehicledetails?.vehicle_type?.no_check_in){
            setOpen(true)
            return false;
            // setError('luggage', { type: 'custom', message: 'Maximum luggage exceed' });
        }
        populateOrderData(data)
        await localStorage.removeItem("booking");
        dispatch({
            type: ADD_BOOKING
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'selectedService',
                value: null
            }
        })
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'itenary',
                value: null
            }
        })
        navigate('/newbooking')
    }

    const populateMyTransferData = (datas) => {

           


        let inputData = {
            customer_reference: datas?.reference_number,
            arrival: booking?.type === 'point' ? (booking?.location_from?.type === 'airport' || vehicleAvailability?.pickupType === "airport") ? 'Arrival' : booking?.location_to?.type === 'airport' ? 'Departure' : 'Point to Point' : 'Hourly',
            id: datas?.id,
            location_from: booking?.location_from?.address,
            location_to: booking?.location_to?.address,
            baggage_protection: (booking?.location_to?.type === "airport" && country === "SG") ? true : false,
            name: `${datas?.passenger_name} ${datas?.passenger_lname}`,
            customerFirstName: datas?.passenger_name,
            customerLastName: datas?.passenger_lname,
            country: datas?.country,
            customerCountry: datas?.country,
            email: datas?.email,
            customerEmail: datas?.email,
            phone: datas?.mobile,
            customerPhone: datas?.mobile,
            cruise_no: datas?.cruise_no,
            disembarkment: moment(datas?.disembarkment).format("hh:mm A"),
            mobile: datas?.mobile,
            namesign: "",
            nationality: "",
            passport: "",
            adults: booking?.adults ? booking?.adults : 0,
            childrens: booking?.childrens ? booking?.childrens : 0,
            minors: booking?.minors ? booking?.minors : 0,
            booster_seats: booking?.minors ? booking?.minors : 0,
            baby_seats: booking?.baby ? booking?.baby : 0,
            suitcase: datas?.luggage ? datas?.luggage : 0,
            carryon: datas?.hand_carry ? datas?.hand_carry : 0,
            specialinstructions: datas?.instructions ? datas?.instructions : 0,
            pickupdate: moment(booking?.pickupdate).format("YYYY-MM-DD"),
            pickuptime: moment(booking?.pickupdate).format("hh:mm A"),
            pickup_loc_type: null,
            flightno: getValues('flight_no') && datas?.flight_no ? datas?.flight_no : null,
            flighTiming: getValues('flight_no') && datas?.flight_date ? datas?.flight_date : null,
            flightdate: getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("DD-MM-YYYY") : null,
            flighttime: getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("hh:mm A") : null,
            time_related:  datas?.time_related ? datas?.time_related : null,
            buffer_time: datas?.time_related ? datas?.time_related : null,
            supplier_id: null,
            vehicle_type: null,
            vehicle_id: null,
            driver_id: null,
            customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
            created_by: user?.id ? user?.id : null,
            currency: data?.currencyId ? data?.currencyId : "SGD",
            usertype: user ? user?.role : "guest",
            stops: [],
            stopCount: 0,
            payment_type: "card",
            arival: arival ? arival : null,
            booking_data: booking,
            selectedVehicle: selectedVehicle,
            bookingType: bookingType,
            addonItem: null,
            addonservices: null,
            taxPer: null,
            vehicletype: null,
            service_type: null,
            vehicle_image: selectedVehicle?.imageURL,
            agentMarkup: selectedVehicle?.agentmarkup,
            base_rate: selectedVehicle?.price,
            markup: selectedVehicle?.adminMarkup,
            tax: selectedVehicle?.taxValue,
            total_price: selectedVehicle?.total,
            toll: 0,
            extraCharges: selectedVehicle?.extraRate,
            addonService: 0,
            extras: selectedVehicle?.extras,
            itenary: booking?.type === "hour" ? itenary?.itenary : null,
            itenary_id: booking?.type === "hour" ? itenary?.id : null,
        }



        dispatch({
            type: ORDER_DATA,
            payload: inputData
        })
    }

    const populateOrderData = (datas) => {



        let agentmarkup = 0;
        let adminMarkup = 0
        let tax = 0, returntax = 0;
        let total = 0, returnTotal = 0;
        let additionalStop = 0;
        let baserate = 0;
        let extras = data?.extraRate;
        let extraReturn = data?.extrasReturn
        let extraCharges = data?.extraCharges;
        let extraReturnCharges = data?.extraChargesReturn
        let addonService = 0;
        let adminDiscount = 0;
        let toll = data?.tollrate ? parseFloat(data?.tollrate) : 0;

        let service = services?.filter(serv => serv?.checked)

        service?.map(serv => {
            addonService += parseFloat(serv?.price)
        })

        additionalStop = booking?.stops ? additionalStops ? booking?.stops?.length * additionalStops : booking?.stops?.length * 20 : 0;
        baserate = booking?.type === "hour" ? (parseFloat(data?.rate) * parseFloat(booking?.duration)) + additionalStop : parseFloat(data?.rate) + additionalStop

        // extraCharges = data?.addonItem?.map(extra => {
        //     if (extra?.rate_type === "percentage") {
        //         extras += booking?.type === "hour" ? (((parseFloat(baserate) / 100) * parseFloat(extra?.rate)) * parseFloat(booking?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
        //         return {
        //             id: extra?.addonitem?.id,
        //             name: extra?.addonitem?.name,
        //             price: booking?.type === "hour" ? ((parseFloat(baserate) / 100) * parseFloat(extra?.rate) * parseFloat(booking?.duration)) : (parseFloat(baserate) / 100) * parseFloat(extra?.rate)
        //         }
        //     }
        //     else {
        //         extras += booking?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(booking?.duration)) : parseFloat(extra?.rate)
        //         return {
        //             id: extra?.addonitem?.id,
        //             name: extra?.addonitem?.name,
        //             price: booking?.type === "hour" ? (parseFloat(extra?.rate) * parseFloat(booking?.duration)) : parseFloat(extra?.rate)
        //         }
        //     }
        // })

        if (user?.role === "agent" && markupLists?.type === "fixed") {




            if (markupLists?.status === 1) {
                agentmarkup = parseFloat(markupLists?.value);
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.markUpDetails[0]?.value)
                }
                else {
                    adminMarkup = parseFloat(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.defaultMarkup?.value)
            }

            if(data?.discountsData){
                let ddata = data?.discountsData;
                let totalAmount = baserate + adminMarkup + agentmarkup + toll + extras + addonService;
                if(ddata?.discount_type === "percentage"){
                    adminDiscount = (totalAmount/100) * parseFloat(ddata?.discount)
                }
                else{
                    adminDiscount += parseFloat(ddata?.discount)
                }
            }



            tax = ((baserate + adminMarkup + agentmarkup + extras + addonService + toll - adminDiscount) / 100) * parseFloat(data?.tax?.value)

            total = baserate + adminMarkup + agentmarkup + extras + tax + addonService + toll - adminDiscount;

            returntax = ((baserate + adminMarkup + agentmarkup + extraReturn + addonService + toll - adminDiscount) / 100) * parseFloat(data?.tax?.value)

            returnTotal = baserate + adminMarkup + agentmarkup + extraReturn + returntax + addonService + toll - adminDiscount;
        }
        else {



            if (user?.role === "agent" && markupLists?.status === 1) {
                agentmarkup = ((baserate + extras + addonService + toll) / 100) * parseFloat(markupLists?.value)
            }

            if (data?.markUpDetails && data?.markUpDetails?.length > 0) {
                if (data?.markUpDetails[0]?.value_type === "Percentage") {
                    adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.markUpDetails[0]?.value)
                }
                else {
                    adminMarkup = parseFloat(data?.markUpDetails[0]?.value)
                }
            }
            else if (data?.defaultMarkup) {
                adminMarkup = ((baserate + extras + toll + addonService) / 100) * parseFloat(data?.defaultMarkup?.value)
            }

            if(data?.discountsData){
                let ddata = data?.discountsData;
                let totalAmount = baserate + adminMarkup + agentmarkup + toll + extras + addonService;
                if(ddata?.discount_type === "percentage"){
                    adminDiscount = (totalAmount/100) * parseFloat(ddata?.discount)
                }
                else{
                    adminDiscount += parseFloat(ddata?.discount)
                }
            }

            tax = ((baserate + adminMarkup + agentmarkup + extras + addonService + toll - adminDiscount) / 100) * parseFloat(data?.tax?.value)

            total = baserate + adminMarkup + agentmarkup + extras + tax + addonService + toll - adminDiscount;

            returntax = ((baserate + adminMarkup + agentmarkup + extraReturn + addonService + toll - adminDiscount) / 100) * parseFloat(data?.tax?.value)

            returnTotal = baserate + adminMarkup + agentmarkup + extraReturn + returntax + addonService + toll - adminDiscount;
        }

        let selected = {
            ...data,
            rate: baserate,
            adminMarkup,
            agentmarkup,
            extraRate: extras,
            total: total,
            taxValue: tax,
            extraCharges,
            services: service,
            addonService,
            adminDiscount
        }

        let returnselected = {
            ...data,
            rate: baserate,
            adminMarkup,
            agentmarkup,
            extraRate: extraReturn,
            total: returnTotal,
            taxValue: returntax,
            extraCharges: extraReturnCharges,
            services: service,
            addonService,
            addonItem: extraReturnCharges,
            adminDiscount
        }

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: booking,
                selectedVehicle: selected,
                id: 'current'
            }
        })

        if (orderData) {
            let order = orderData.filter(or => or.id !== datas.id);
            dispatch({
                type: RESET_ORDER_DATA,
                payload: order
            })
        }



        let stops = booking?.stops?.map(stop => {
            return {
                cityname: stop?.stop_name?.address,
                latitude: stop?.stop_name?.latitude,
                longitude: stop?.stop_name?.longitude,
            }
        })





        console.log({ stops }, "STOPS")


        let inputData = {
            customer_reference: datas?.reference_number,
            arrival: booking?.type === 'point' ? booking?.location_from?.type === 'airport' ? 'Arrival' : booking?.location_to?.type === 'airport' ? 'Departure' : 'Point to Point' : 'Hourly',
            id: datas?.id,
            location_from: booking?.location_from?.address,
            location_to: booking?.location_to?.address,
            baggage_protection: (booking?.location_to?.type === "airport" && country === "SG") ? true : false,
            name: `${datas?.passenger_name} ${datas?.passenger_lname}`,
            country: datas?.country,
            email: datas?.email,
            phone: datas?.mobile,
            mobile: datas?.mobile,
            namesign: "",
            nationality: "",
            passport: "",
            adults: booking?.adults ? booking?.adults : 0,
            childrens: booking?.childrens ? booking?.childrens : 0,
            cruise_no: datas?.cruise_no,
            disembarkment: moment(datas?.disembarkment).format("hh:mm A"),
            minors: booking?.minors ? booking?.minors : 0,
            booster_seats: booking?.minors ? booking?.minors : 0,
            baby_seats: booking?.baby ? booking?.baby : 0,
            suitcase: datas?.luggage ? datas?.luggage : 0,
            carryon: datas?.hand_carry ? datas?.hand_carry : 0,
            specialinstructions: datas?.instructions ? datas?.instructions : 0,
            pickupdate: moment(booking?.pickupdate).format("YYYY-MM-DD"),
            pickuptime: moment(booking?.pickupdate).format("hh:mm A"),
            pickup_loc_type: datas?.arrival_method ? datas?.arrival_method : null,
            flightno: getValues('flight_no') && datas?.flight_no ? datas?.flight_no : null,
            flighTiming: getValues('flight_no') && datas?.flight_date ? datas?.flight_date : null,
            flightdate: getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("DD-MM-YYYY") : null,
            flighttime: getValues('flight_no') && datas?.flight_date ? moment(datas?.flight_date).format("hh:mm A") : null,
            time_related:  datas?.time_related ? datas?.time_related : null,
            buffer_time: datas?.time_related ? datas?.time_related : null,
            supplier_id: data?.supplier_id ? data?.supplier_id : null,
            vehicle_type: data?.vehicledetails?.vehicle_type?.id ? data?.vehicledetails?.vehicle_type?.id : null,
            vehicle_id: data?.vehicledetails?.veh_details?.vehicle_id ? data?.vehicledetails?.veh_details?.vehicle_id : null,
            driver_id: data?.vehicledetails?.veh_details?.driver_id ? data?.vehicledetails?.veh_details?.driver_id : null,
            customer_id: user?.parentDetails ? user?.parentDetails?.id : user?.id ? user?.id : null,
            created_by: user?.id ? user?.id : null,
            currency: data?.currencyId ? data?.currencyId : "SGD",
            usertype: user ? user?.role : "guest",
            stops: stops ? stops : [],
            stopCount: stops ? stops?.length : 0,
            payment_type: "card",
            arival: arival ? arival : null,
            booking_data: booking,
            selectedVehicle: selected,
            bookingType: bookingType,
            addonItem: extraCharges,
            addonservices: service,
            taxPer: data?.tax?.value,
            vehicletype: data?.vehicledetails?.vehicle_type?.vehicle_type,
            service_type: data?.service_type,
            vehicle_image: data?.vehicledetails?.vehicle_images?.length > 0 ? `${data?.imageBasePath}${data?.vehicledetails?.vehicle_images[0].path}` : null,
            agentMarkup: agentmarkup,
            base_rate: baserate,
            markup: adminMarkup,
            tax: tax,
            total_price: total,
            toll: toll,
            extraCharges: extras,
            addonService: addonService,
            itenary: booking?.type === "hour" ? itenary?.itenary : null,
            itenary_id: booking?.type === "hour" ? itenary?.id : null,
            adminDiscount
        }

        dispatch({
            type: ORDER_DATA,
            payload: inputData
        })


        if (booking?.bookingType === "return") {
            let order = orderData?.find(or => or.id === datas.id);
            if (!order) {
                let returnData = {
                    ...inputData,
                    arrival: booking?.type === 'point' ? booking?.location_to?.type === 'airport' ? 'Arrival' : booking?.location_from?.type === 'airport' ? 'Departure' : 'Point to Point' : 'Hourly',
                    id: uuid(),
                    cruise_no: datas?.return_cruise_no,
                    disembarkment: moment(datas?.return_disembarkment).format("hh:mm A"),
                    pickup_loc_type: datas?.return_arrival_method ? datas?.return_arrival_method : null,
                    flightno: getValues('return_flight_no') && datas?.return_flight_no ? datas?.flight_no : null,
                    flighTiming: getValues('return_flight_no') && datas?.return_flight_date ? datas?.return_flight_date : null,
                    flightdate: getValues('return_flight_no') && datas?.return_flight_date ? moment(datas?.return_flight_date).format("DD-MM-YYYY") : null,
                    flighttime: getValues('return_flight_no') && datas?.return_flight_date ? moment(datas?.return_flight_date).format("hh:mm A") : null,
                    time_related: datas?.return_time_related ? datas?.return_time_related : null,
                    buffer_time: datas?.return_time_related ? datas?.return_time_related : null,
                    arival: returnArival ? returnArival : null,
                    location_from: inputData?.location_to,
                    location_to: inputData?.location_from,
                    pickupdate: moment(booking?.returndate).format("YYYY-MM-DD"),
                    pickuptime: moment(booking?.returndate).format("hh:mm A"),
                    tax: returntax,
                    total_price: returnTotal,
                    extraCharges: extraReturn,
                    selectedVehicle: returnselected,
                    addonItem: extraReturnCharges,
                    booking_data: {
                        ...inputData.booking_data,
                        location_from: inputData.booking_data?.location_to,
                        location_to: inputData.booking_data?.location_from,
                        pickupdate: inputData.booking_data?.returndate
                    }
                }
                dispatch({
                    type: ORDER_DATA,
                    payload: returnData
                })
            }
        }

        

    }

    const addAddOnService = (checked, id) => {


        let newServ = services?.map(add => {
            if (id === add?.id) {
                return {
                    ...add,
                    checked: checked
                }
            }
            else {
                return add
            }
        })

        setServices(newServ)

        let selectedServices = newServ?.filter(add => add.checked === true)
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'selectedService',
                value: selectedServices
            }
        })
    }

    // const onChangedev = (e) => {
    //     const { value } = e.target;
    //     console.log({value, checkin: data?.vehicledetails?.vehicle_type?.no_check_in})
    //     setlaugagecount(parseInt(value))
    //     if (bookingType === 'PAL' && parseInt(value) > data?.vehicledetails?.vehicle_type?.no_check_in ) {
    //         setOpen(true)
    //         setError('luggage', { type: 'custom', message: 'Maximum luggage exceed' });
    //     }else{
    //         //setError('luggage', '');
    //     }
    // }

    const returnInformation = () => {
        if (booking?.bookingType === "return") {
            if(booking?.location_to?.type === "airport"){
                return(
                    <div style={{ paddingTop: 20 }}>

                    <div className='booking-detailscard'>
                    <h3 class="hd-text">Depature Information</h3>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <RadioGroup row style={{ paddingTop: 5 }} >
                                <FormControlLabel
                                    value="international"
                                    control={<Radio
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setReturnArival('international')
                                            }
                                        }}
                                        checked={returnArival === "international"}
                                    //name="arival" {...register("arival")} value="international"   
                                    />}
                                    label={<Typography style={{ fontSize: 12 }}>International</Typography>}
                                />
                                {country !== "SG" && <FormControlLabel
                                    value="domestic"
                                    control={<Radio
                                        name="arival"
                                        //{...register("arival")}  
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setReturnArival('domestic')
                                            }
                                        }}
                                        checked={returnArival === "domestic"}
                                    />}
                                    label={<Typography style={{ fontSize: 12 }}>Domestic</Typography>}
                                />}
                            </RadioGroup>
                            <span style={{ fontSize: 10, color: 'red' }}>{errors.arival?.message}</span>
                        </div>
                        <div className='col-sm-6 pb-15'>
                            <InputField
                                fieldLabel="Flight No."
                                fieldName="return_flight_no"
                                control={control}
                                error={errors.return_flight_no}
                            />
                        </div>
                        <div className='col-sm-6 pb-15'>
                            <DateInput
                                fieldLabel="Flight Date & Time"
                                minDate={moment(booking?.returndate).toDate()}
                                maxDate={moment(booking?.returndate).toDate()}
                                fieldName="return_flight_date"
                                control={control}
                                fieldType="datetime"
                                error={errors.return_flight_date}
                                changeDate={(value) => setValue("return_flight_date", value)}
                            />
                        </div>
                        <div className='col-sm-6 pb-15'>
                            <DropdownInput
                                fieldLabel="Buffer Time"
                                fieldName="return_time_related"
                                control={control}
                                error={errors.return_time_related}
                                values={returnBuffer}
                                changeValue={(e) => {
                                    setValue('return_time_related', e.target?.value)
                                    setReturnBuffer(e.target?.value)
                                }
                                }
                            >
                                <MenuItem value={bufferList?.[0]?.buffer_time}> {bufferList?.[0]?.buffer_label}</MenuItem>
                                {flightArrival.filter(res => res.value !== bufferList?.[0]?.buffer_time).map((val) => (
                                    <MenuItem value={val?.value}> {val?.name}</MenuItem>
                                ))
                                }
                            </DropdownInput>
                        </div>
                        </div>
                    </div>
                    </div>
                )
            }
            else{
                return(
                    <div style={{ paddingTop: 20, flexDirection: 'row' }}>
                        <div className='booking-detailscard'>
                    
                    <h3 class="hd-text">Depature Information</h3>
                    
                        <div class="row pb-15">
                            <div className='col-sm-6 pb-15'>
                                <DropdownInput
                                    fieldLabel="Arrival Method"
                                    fieldName="return_arrival_method"
                                    control={control}
                                    error={errors.return_arrival_method}
                                    changeValue={e => setReturnMethods(e.target.value)}
                                >
                                    <MenuItem disabled value="">
                                        Select
                                    </MenuItem>
                                    <MenuItem value="Cruise">Cruise</MenuItem>
                                    <MenuItem value="others">Others</MenuItem>
                                </DropdownInput>
                            </div>
                            </div>
                            <div class="row">
                            {returnMethods === "Cruise" && <div className='col-sm-6 clearfix pb-15'>
                                <InputField
                                    fieldLabel="Name of Cruise"
                                    fieldName="return_cruise_no"
                                    control={control}
                                    error={errors.return_cruise_no}
                                />
                            </div>}
                            {returnMethods === "Cruise" && <div className='col-sm-6 pb-15'>
                                <TimePicker
                                    fieldLabel="Time of Disembarkment"
                                    fieldName="return_disembarkment"
                                    control={control}
                                    error={errors.return_disembarkment}
                                    changeDate={(value) => setValue("return_disembarkment", moment(value, 'YYYY-MM-DD h:mm aa').format('h:mm aa'))}
                                />
                            </div>}
                            {returnMethods === "Cruise" && <div className='col-sm-6 pb-15'>
                                <DropdownInput
                                    fieldLabel="Buffer Time"
                                    fieldName="return_time_related"
                                    control={control}
                                    error={errors.return_time_related}
                                    values={buffer}
                                    changeValue={(e) => {
                                        setValue('return_time_related', e.target?.value)
                                        setReturnBuffer(e.target?.value)
                                    }
                                    }
                                >
                                    <MenuItem value={bufferList?.[0]?.buffer_time}> {bufferList?.[0]?.buffer_label}</MenuItem>
                                    {cruiseArrival.filter(res => res.value !== bufferList?.[0]?.buffer_time).map((val) => (
                                        <MenuItem value={val?.value}> {val?.name}</MenuItem>
                                    ))}
                                </DropdownInput>
                            </div>}
                        </div>
                        
                   
                    </div>
                </div>

                )
            }
            
        }             
    }

    return (
        <div className='pt50'>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                   {`Maximum lauguage ${data?.vehicledetails?.vehicle_type?.no_check_in}`}
                </Alert>
            </Snackbar>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box width={{ xs: "100%", md: "100%" }} paddingLeft={{ xs: 0, md: "0%" }} paddingRight={{ xs: 0, md: "0%" }}>
                    <div className='booking-detailscard'>
                    <h3 class="hd-text">Passenger Details</h3>
                    
                        <div className='row'>
                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="First Name"
                                    fieldName="passenger_name"
                                    fieldType="text"
                                    control={control}
                                    error={errors.passenger_name}
                                    fieldIcon={<FontAwesomeIcon icon={faUser}/>}
                                    required
                                    marginTop={0}
                                />
                            </div>
                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="Last Name"
                                    fieldName="passenger_lname"
                                    fieldType="text"
                                    control={control}
                                    error={errors.passenger_lname}
                                    fieldIcon={<FontAwesomeIcon icon={faUser}/>}
                                    required
                                />
                            </div>
                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="Email Address (Booking Notification)"
                                    fieldName="email"
                                    control={control}
                                    error={errors.email}
                                    fieldIcon={<FontAwesomeIcon icon={faEnvelope}/>}
                                    required
                                />
                            </div>
                            <div className='col-sm-6 pb-15'>
                                <DropdownInput
                                    fieldLabel="Country"
                                    fieldName="country"
                                    control={control}
                                    error={errors.country}
                                    changeValue={(e) => {
                                        setCountry(e.target?.value)
                                        if (e?.target?.value === "SG") {
                                            setArival('international')
                                        }

                                    }}
                                >
                                    <MenuItem disabled value="" className='formText'>
                                        Select
                                    </MenuItem>
                                    {options?.map(opt => (
                                        <MenuItem className='formText' value={opt?.value}>{opt?.label}</MenuItem>
                                    ))}
                                </DropdownInput>
                            </div>
                            <div className='col-sm-6 pb-15'>
                                <PhoneInputs
                                    fieldLabel="Mobile"
                                    fieldName="mobile"
                                    control={control}
                                    error={errors.mobile}
                                    fieldIcon={<FontAwesomeIcon icon={faPhone}/>}
                                    required
                                />
                            </div>
                            
                            <div className='col-sm-6 pb-15'>
                                <div className='row'>
                                <div className='col-sm-6'>
                                <InputField
                                    fieldLabel="Check-in Luggage"
                                    fieldName="luggage"
                                    fieldType={"number"}
                                    min={1}
                                    control={control}
                                    error={errors.luggage}
                                    //onChangedev={onChangedev}
                                />
                            </div>
                            <div className='col-sm-6'>
                                <InputField
                                    fieldLabel="Hand Carry"
                                    fieldName="hand_carry"
                                    fieldType={"number"}
                                    min={1}
                                    control={control}
                                    error={errors.hand_carry}
                                />
                            </div>
                                </div>
                            </div>

                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="Special Instructions"
                                    fieldName="instructions"
                                    control={control}
                                    // multiline={true}
                                    rows={1}
                                    error={errors.instructions}
                                />
                            </div>
                            {(user?.role === 'agent' || user?.role === 'corporate') ? 
                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="Reference Number"
                                    fieldName="reference_number"
                                    fieldType={"text"}
                                    control={control}
                                    error={errors.reference_number}
                                />
                            </div> : ''}
                            {booking?.type === "hour" && <div className='col-sm-6 pb-15'>
                                {/* <FileInput
                                    fieldLabel="Itinerary"
                                    fieldName="itenary"
                                    fieldType={"file"}
                                    control={control}
                                    error={errors.itenary}
                                    onChange={}
                                /> */}
                                <ImageInput
                                    fieldName="itenary"
                                    fieldLabel="Itinerary"
                                    // imageHelperText={"512 * 512"}
                                    control={control}
                                    error={errors.itenary}
                                    type={'application/pdf'}
                                    changeImage={(e) => uploadFiles(e)}
                                    Icon={
                                        <div className='uploadIcon'><Image /></div>}
                                />
                            </div>}
                        </div>
                     
                    </div>
                    {(isArray(services) && services?.length > 0 || booking?.location_to?.type === "airport") && <div style={{ paddingTop: 20 }}>
                    <div className='booking-detailscard'>
                        <h3 class="hd-text">Add on Services</h3>
                        <div className='row'>
                                {services?.map((serv, index) => (
                                    <div className='col-sm-6 pb-15'>
                                        <FormControlLabel
                                            style={{ fontSize: "15px" }}
                                            className='checkbox-wrap'
                                            control={
                                                <Checkbox className='checkbox-wrap-cls' style={{ fontSize: 15 }} checked={serv?.checked} onChange={(e) => addAddOnService(e.target.checked, serv?.id)} name={serv?.id} />
                                            }
                                            label={<Typography className='checkbox-wrap-label' style={{ fontSize: 15 }}>{serv?.name} (S${serv?.price})</Typography>}
                                           
                                        />
                                    </div>
                                ))}
                                {booking?.location_to?.type === "airport" && <div className='col-sm-6 pb-15'>
                                    <FormControlLabel
                                        style={{ fontSize: "15px" }}
                                        className='checkbox-wrap'
                                        control={
                                            <Checkbox className='checkbox-wrap-cls' style={{ fontSize: 15 }} checked={true} disabled name={"backageProtection"} />
                                        }
                                        label={<Typography style={{ fontSize: 15 }} className='checkbox-wrap-label'>Baggage Protection</Typography>}
                                       
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>}
                    {booking?.type === "point" && <div style={{ paddingTop: 20 }}>
                    <div className='booking-detailscard'>
                        <div style={{ display: 'block',
                                        flexDirection: 'row'
                                    }}>
                                    <h3 class="hd-text mb-0">Arrival Information</h3>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 10
                                    }}
                                    >
                                    <span style={{ lineHeight: '0'}}>
                                        <ErrorIcon style={{ width: 25, height: 25, marginRight: 5}} />
                                    </span>
                                    <span>
                                        <Typography className='loctext' >Flight/Ferry arrival cannot be tracked without proper information!</Typography>
                                    </span>
                                </div>
                            </div>
                        
                            {/* {vehicleAvailability?.pickupType === "airport" &&
                                <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'start'
                                        }}
                                    >
                                        <InputField
                                            fieldLabel="Flight No."
                                            fieldName="flight_no"
                                            control={control}
                                            error={errors.flight_no}
                                            required
                                        />
                                    </div>
                                </Grid>} */}
                            {(booking?.type === "point") && <>
                                <div className='row'>
                                    {booking?.location_from?.type === "point" && <div className='col-md-6 pb-15'>
                                        <DropdownInput
                                            fieldLabel="Arrival Method"
                                            fieldName="arrival_method"
                                            control={control}
                                            error={errors.arrival_method}
                                            changeValue={e => setMethods(e.target.value)}
                                        >
                                            <MenuItem disabled value="">
                                                Select
                                            </MenuItem>
                                            <MenuItem value="Cruise">Cruise</MenuItem>
                                            <MenuItem value="others">Others</MenuItem>
                                        </DropdownInput>

                                    </div>}
                                    {booking?.location_from?.type === "airport" && <div className='col-sm-12 pb-15'>
                                        <RadioGroup row style={{ paddingTop: 5 }} >
                                            <FormControlLabel
                                                value="international"
                                                control={<Radio
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setArival('international')
                                                        }
                                                    }}
                                                    checked={arival === "international"}
                                                //name="arival" {...register("arival")} value="international"   
                                                />}
                                                label={<Typography style={{ fontSize: 12 }}>International</Typography>}
                                            />
                                            {country !== "SG" && <FormControlLabel
                                                value="domestic"
                                                control={<Radio
                                                    name="arival"
                                                    //{...register("arival")}  
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setArival('domestic')
                                                        }
                                                    }}
                                                    checked={arival === "domestic"}
                                                />}
                                                label={<Typography style={{ fontSize: 12 }}>Domestic</Typography>}
                                            />}
                                        </RadioGroup>
                                        <span style={{ fontSize: 10, color: 'red' }}>{errors.arival?.message}</span>
                                    </div>}

                                    {(booking?.location_from?.type === "airport" || vehicleAvailability?.pickupType === "airport") &&
                                        <div className='col-sm-6 pb-15'>
                                            <InputField
                                                fieldLabel="Flight No."
                                                fieldName="flight_no"
                                                control={control}
                                                error={errors.flight_no}
                                            />
                                        </div>}
                                    {(booking?.location_from?.type === "airport" || vehicleAvailability?.pickupType === "airport") &&
                                        <div className='col-sm-6 pb-15'>
                                            <DateInput
                                                fieldLabel="Flight Date & Time"
                                                minDate={moment(booking?.pickupdate).toDate()}
                                                maxDate={moment(booking?.pickupdate).toDate()}
                                                fieldName="flight_date"
                                                control={control}
                                                fieldType="datetime"
                                                error={errors.flight_date}
                                                changeDate={(value) => setValue("flight_date", value)}
                                            />
                                        </div>}
                                    {(booking?.location_from?.type === "airport" || vehicleAvailability?.pickupType === "airport") && buffer &&
                                        <div className='col-sm-6 pb-15'>
                                            <DropdownInput
                                                fieldLabel="Buffer Time"
                                                fieldName="time_related"
                                                control={control}
                                                error={errors.time_related}
                                                values={buffer}
                                                changeValue={(e) => {
                                                    setValue('time_related', e.target?.value)
                                                    setBuffer(e.target?.value)
                                                }
                                                }
                                            >
                                                <MenuItem value={bufferList?.[0]?.buffer_time}> {bufferList?.[0]?.buffer_label}</MenuItem>
                                                {flightArrival.filter(res => res.value !== bufferList?.[0]?.buffer_time).map((val) => (
                                                    <MenuItem value={val?.value}> {val?.name}</MenuItem>
                                                ))
                                                }
                                            </DropdownInput>
                                        </div>}
                                    {methods === "Cruise" && <div className='col-sm-6 pb-15'>
                                        <InputField
                                            fieldLabel="Name of Cruise"
                                            fieldName="cruise_no"
                                            control={control}
                                            error={errors.cruise_no}
                                        />
                                    </div>}
                                    {methods === "Cruise" && <div className='col-sm-6 pb-15'>
                                        {/* <InputField
                                            fieldLabel="Time of disembarkment"
                                            fieldName="disembarkment"
                                            control={control}
                                            error={errors.disembarkment}
                                        /> */}
                                        <TimePicker
                                            fieldLabel="Time of Disembarkment"
                                            fieldName="disembarkment"
                                            control={control}
                                            error={errors.disembarkment}
                                            changeDate={(value) => setValue("disembarkment", moment(value, 'YYYY-MM-DD h:mm aa').format('h:mm aa'))}
                                        />
                                    </div>}
                                    {methods === "Cruise" &&<div className='col-sm-6 pb-15'>
                                            <DropdownInput
                                                fieldLabel="Buffer Time"
                                                fieldName="time_related"
                                                control={control}
                                                error={errors.time_related}
                                                values={buffer}
                                                changeValue={(e) => {
                                                    setValue('time_related', e.target?.value)
                                                    setBuffer(e.target?.value)
                                                }
                                                }
                                            >
                                                <MenuItem value={bufferList?.[0]?.buffer_time}> {bufferList?.[0]?.buffer_label}</MenuItem>
                                                {cruiseArrival.filter(res => res.value !== bufferList?.[0]?.buffer_time).map((val) => (
                                                    <MenuItem value={val?.value}> {val?.name}</MenuItem>
                                                ))
                                                }
                                            </DropdownInput>
                                        </div>}
                                </div>
                            </>}
                        </div>
                        {returnInformation()}
                    </div>}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'end',
                            paddingTop: 20,
                            paddingBottom: 100
                        }}
                    >
                        {bookingType === "PAL" && <Button
                            onClick={handleSubmit(addMoreBooking)}
                            variant="filled"
                            className='subm-buttoncls'
                            style={{
                                backgroundColor: '#333333',
                                borderRadius: 4,
                                width: 175,
                                marginRight: '1%',
                                padding: 5,
                                height: 40,
                                borderRadius: 30
                            }}
                           
                        >
                            <Typography className='buttonText'>Add More Booking <span className='button-icon'><FontAwesomeIcon icon={faCar} /></span></Typography>
                        </Button>}
                        <Button
                            type="submit"
                            variant="filled"
                            className='subm-buttoncls'
                            style={{
                                backgroundColor: '#ad9052',
                                borderRadius: 4,
                                width: 175,
                                padding: 5,
                                height: 40,
                                borderRadius: 30
                            }}
                            >
                            <Typography className='buttonText'>Proceed <span className='button-icon'><FontAwesomeIcon icon={faArrowsRotate} /></span></Typography>
                        </Button>
                    </div>
                </Box>
            </form>
        </div>
    )
}

export default DetailsForm

