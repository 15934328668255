import React, { useState, useEffect } from 'react'
import { Grid, Modal, Typography, IconButton, Container, Box, FormControl, Stack } from '@mui/material'
import InputField from '../../components/InputField'
import ModalHeader from '../../components/ModalHeader';
import CloseIcon from '@mui/icons-material/Close';
import Loc2Img from '../../assets/images/IconsNew/Loc2Img.png'
import TimeImg from '../../assets/images/Icons/timetable.png'
import LittleImg from '../../assets/images/IconsNew/little-kid.png'
import Loc1Img from '../../assets/images/IconsNew/Loc1Img.png'
import Divider from '@mui/material/Divider'
import { useLocation } from 'react-router-dom';
import AdultImg from '../../assets/images/IconsNew/avatar.png'
import BabyImg from '../../assets/images/IconsNew/baby-boy.png'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import reactotron from '../../ReactronConfig';
import MainStop from '../Summary/MainStop';
import SubStop from '../Summary/SubStop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong,faChild,faBaby,faPerson } from '@fortawesome/free-solid-svg-icons';


const ViewTrip = ({ openModal, closeModal, trips }) => {
    const [booking, setBooking] = useState(null);



    console.log({ trips })

    const { user } = useSelector(state => state.auth)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#F6F6F6',
        borderRadius: '15px',
        border: '0px solid #fff',
        height: 'auto',
        width: '75%',
    };

    useEffect(() => {
        setBooking(JSON.parse(trips?.row?.booking_data))
    }, [])




    let addOns = trips.row?.addons ? JSON.parse(trips?.row?.addons) : [];
    let addonitems = trips.row?.addonitems ? JSON.parse(trips?.row?.addonitems) : [];

   




    return (
        
        <Modal open={openModal} onClose={closeModal}   >

            <Box sx={style} className='modalScroll modal-md' >
                <div className='modal-top'>
                    <h3>Trip Details</h3>
                    <IconButton onClick={closeModal}>
                        <CloseIcon style={{ color: 'gray', fontSize: 20 }} />
                    </IconButton>
                </div>
                <div className='col-sm-12 mb-20'>
                    <div className='row'>
                    <div className='col-sm-6'>
                        {/* <Typography className='modalHeadStyle' >Trip ID : #{trips.id}</Typography> */}
                        <Typography className='vehiclename'>{trips?.row?.vehicletype ?trips?.row?.vehicletype.vehicle_type : ''}</Typography>
                        <Typography className='modalDate'  > {moment(trips?.row?.pickupdate, "YYYY-MM-DD").format("DD-MM-YYYY")}  {trips?.row.pickuptime}
                            </Typography>
                    </div>
                    <div className='col-sm-6 text-right'>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingTop: '1%' }}>
                            <span>
                                <Typography className='companytext' >Transaction ID :</Typography>
                            </span>
                            <span><Typography className='newText' style={{ paddingLeft: 4 }}> {`${trips?.row?.paymentdetails?.transactionID}`}</Typography> </span>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingTop: '1%', gap: 5 }}>
                            <div style={{ display: 'flex' }}>
                                <span>
                                    <Typography className='companytext' >Payment Mode : </Typography>
                                </span>
                                <span><Typography className='completeText' style={{ paddingLeft: 4 }}>{trips?.row?.payment_type}</Typography> </span>

                            </div>
                            <div style={{ display: 'flex' }}>
                                <span>
                                    <Typography className='companytext' >Payment Status : </Typography>
                                </span>
                                <span><Typography className='completeText' style={{ paddingLeft: 4 }}>{trips?.row?.paymentdetails?.status}</Typography> </span>

                            </div>


                        </div>
                    </div>
                    </div>
                   
                    
                </div>

<div className='col-sm-12'>


                <div className='row mb-20'>
                    <div className='col-sm-6'>
                        {/* {(user?.role === "agent" || user?.role === "corporate") && <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                            <span>
                            <Typography className='companytext' >Company Name :</Typography>
                            </span>
                            <span><Typography className='newText' >{user?.companyinfo?.company_name}</Typography> </span>
                        </div>} */}
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
                            <span style={{ marginBottom: 5 }}>
                                <Typography className='modalHeadText'> Passenger Details</Typography>
                            </span>
                            <div style={{ display: 'block', flexDirection: 'row', justifyContent: 'start' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', marginBottom: 3 }}>
                                    <Typography className='namesText' >Name </Typography>
                                    <Typography className='storeText' > : {trips?.row?.name}</Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', marginBottom: 3 }}>
                                    <Typography className='namesText' > Email ID </Typography>
                                    <Typography className='storeText' > : {trips?.row?.email}</Typography>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', marginBottom: 3 }}>
                                    <Typography className='namesText' >Mobile </Typography>
                                    <Typography className='storeText' > : {trips?.row?.mobile}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', gap: 2 }}>
                            <div className='passenger-no'>
                                <span className='prsn-icon'><FontAwesomeIcon icon={faPerson} /></span> 
                                <Typography className='capacityText'>Adults</Typography>
                                <Typography className='capacityText'>{trips?.row?.adults}</Typography>
                            </div>
                            <div className='passenger-no'>
                                <span className='prsn-icon'><FontAwesomeIcon icon={faBaby} /></span>
                                <Typography className='capacityText'>Baby Seats</Typography>
                                <Typography className='capacityText'>{trips?.row?.baby_seats}</Typography>
                            </div>
                            <div className='passenger-no'>
                                <span className='prsn-icon'><FontAwesomeIcon icon={faChild} /></span>
                                <Typography className='capacityText'>Child Seats</Typography>
                                <Typography className='capacityText'>{trips?.row?.childrens}</Typography>
                            </div>
                            <div className='passenger-no'>
                                <span className='prsn-icon'><FontAwesomeIcon icon={faBaby} /></span>
                                <Typography className='capacityText'>Booster Seats</Typography>
                                <Typography className='capacityText'>{trips?.row?.booster_seats}</Typography>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='row'>
                    <div className='col-sm-12'>
                    <div className='trip-route trp-dtl-route'>
                    
                    <p className='text-center'><span className='place'>{booking?.location_from?.address}
                    
                    {booking?.location_to !== null  &&
                                <div className='d-none' >
                                    {booking?.stops?.map((stop, index) => (
                                        <SubStop
                                            address={stop?.stop_name?.address}
                                            count={index + 2}
                                            color="blue"
                                            index={index}
                                            //removeStop={(index) => removeExtraStop(index)}
                                            stopNote={stop?.stopNote}
                                        />
                                    ))}
                                </div>}
                                </span><span className='place-icon'><FontAwesomeIcon icon={faArrowRightLong} /></span>
                            {booking?.location_to?.address && <span className='place'>{booking?.location_to?.address}</span>}</p>
                        </div>
                       
                        {/* <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                            <span>
                            <img src={Loc1Img} style={{ width: 28, height: 30,paddingRight:10  }} />
                            </span>
                            <span>
                            <Typography className='loctext' >{trips.row.location_from}</Typography>
                            </span>
                        </div>
                        <Divider orientation="vertical" flexItem sx={{ width:'0px',height: '10%',marginLeft:2.5,marginTop:-3,marginBottom:-1 ,paddingBottom:2,
                        borderRight:'1.5px dashed' ,borderLeft:'none',borderRightColor: "#B6A269"}} > </Divider>
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                            <span>
                            <img src={Loc2Img} style={{ width: 28, height: 30,paddingRight:10 }} />
                            </span>
                            <span>
                            <Typography className='loctext'>{trips.row.location_to}</Typography>
                            </span>
                        </div> */}

                    </div>

                    {/* <Grid xs={12} md={3} sx={{ bgcolor: '#F6F6F6', height: 'auto',flexGrow:1}}>
                        
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'center'}}>
                            <span>
                            <img src={trips.row.vehicle_image} width="auto" height="120" />
                            </span>
                            
                        </div>
                        
                    </Grid> */}
                    <div className='col-sm-12 mb-20'>
                        <Divider color='#B6A269' flexItem sx={{ borderWidth: 1 }} />
                    </div>
                    {/* <Grid xs={12} md={12} sx={{ bgcolor: '#F6F6F6', height: 'auto', flexGrow:1}}>
                        
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                        <span>
                            <Typography className='companytext' >Transaction ID :</Typography>
                            </span>
                            <span><Typography className='newText' ></Typography> </span> 
                           
                        </div>
                        <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start',padding:'1%'}}>
                        <span>
                            <Typography className='companytext' >Payment Status :</Typography>
                            </span>
                            <span><Typography className='completeText' >{trips?.row?.payment_status}</Typography> </span> 
                           
                        </div>
                    </Grid> */}
<div className='col-sm-6'></div>
                   
                    <div className='col-sm-6'>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                            <Typography className='modalHeadText ttl-fare'> Total Fare</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                paddingRight: '30%',
                                flex: 0.5,
                                fontSize: 12
                            }}>Base Price</Typography>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontSize: 12,
                                textAlign: 'right',
                                paddingRight: 10,
                                flex: 0.5
                            }} >S${parseFloat(trips?.row?.pricebreakups?.base_rate + trips?.row?.pricebreakups?.markup).toFixed(2)}</Typography>
                        </div>
                        {(user && user.role === "agent" && trips?.row?.pricebreakups?.agentMarkup > 0) && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                paddingRight: '30%',
                                fontSize: 12,
                                flex: 0.5
                            }}>Markup Price</Typography>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontSize: 12,
                                textAlign: 'right',
                                paddingRight: 10,
                                flex: 0.5
                            }} >S${parseFloat(trips?.row?.pricebreakups?.agentMarkup).toFixed(2)}</Typography>
                        </div>}
                        {/* <div style={{ display: 'flex' ,flexDirection:'row' , justifyContent:'start'}}>
                        <Typography  style={{
                            fontFamily: 'Nunito',
                            fontWeight: 600,
                            paddingRight: '30%', 
                            flex: 0.5,
                            fontSize: 12
                        }}>Extra Charges</Typography>
                        <Typography style={{ 
                            fontFamily: 'Nunito',
                            fontSize: 12,
                            paddingRight: 10, 
                            flex: 0.5  }}  >S${parseFloat(trips?.row?.pricebreakups?.extraCharges).toFixed(2)}</Typography>
                    </div> */}
                        {addOns?.map((add, index) => (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }} key={index}>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontWeight: 600,
                                    paddingRight: '30%',
                                    flex: 0.5,
                                    fontSize: 12
                                }}>{add?.name}</Typography>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontSize: 12,
                                    textAlign: 'right',
                                    paddingRight: 10,
                                    flex: 0.5
                                }}  >S${parseFloat(add?.price).toFixed(2)}</Typography>
                            </div>
                        ))}
                        {addonitems?.map((add, index) => (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }} key={index}>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontWeight: 600,
                                    paddingRight: '30%',
                                    flex: 0.5,
                                    fontSize: 12
                                }}>{add?.name}</Typography>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontSize: 12,
                                    paddingRight: 10,
                                    textAlign: 'right',
                                    flex: 0.5
                                }}  >S${parseFloat(add?.price).toFixed(2)}</Typography>
                            </div>
                        ))}
                        {parseFloat(trips?.row?.pricebreakups?.toll) > 0 &&
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontWeight: 600,
                                    paddingRight: '30%',
                                    flex: 0.5,
                                    fontSize: 12,
                                }}>Toll</Typography>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontSize: 12,
                                    paddingRight: 10,
                                    textAlign: 'right',
                                    flex: 0.5
                                }}  >S${parseFloat(trips?.row?.pricebreakups?.toll).toFixed(2)}</Typography>
                            </div>}
                        {trips?.row?.pricebreakups?.tax > 0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontWeight: 600,
                                paddingRight: '30%',
                                fontSize: 12,
                                flex: 0.5
                            }}>Tax</Typography>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontSize: 12,
                                paddingRight: 10,
                                textAlign: 'right',
                                flex: 0.5
                            }}  >S${parseFloat(trips?.row?.pricebreakups?.tax).toFixed(2)}</Typography>
                        </div>}


                        <Divider orientation="horizontal" sx={{ bgcolor: "#F6F6F6", height: '0px', width: '100%', marginTop: 1 }} flexItem />

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center', width: '100%', marginBottom: 5, marginTop: 8 }}>
                            <Typography style={{ color: '#333333', fontWeight: '600', paddingRight: '28%', flex: 0.5, fontSize: 20 }} >Total</Typography>
                            <Typography style={{ color: '#b7a269', fontWeight: '800', flex: 0.5, fontSize: 25, textAlign: 'right' }} >S${parseFloat(trips?.row?.pricebreakups?.total_price).toFixed(2)}</Typography>
                        </div>
                    </div>
                </div>
                </div>

            </Box>

        </Modal>
    )
}

export default ViewTrip
