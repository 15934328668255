import React from 'react'
import './style.scss'
import { Grid, Typography, Button, Snackbar, Alert, CircularProgress } from '@mui/material'
import InputField from '../../components/InputField'
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import customAxios from '../../CustomAxios';
import { toast } from 'react-toastify';
import { LOADING } from '../../Redux/constants/userConstants';
import HomeHeaderWrapper from '../../components/HomeHeaderWrapper'
import Footer from '../../components/Footer'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,faArrowRightLong} from '@fortawesome/free-solid-svg-icons';
const ForgotPassword = () => {

    const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
        //navigate("/resetpassword")
    };

	const schema = yup.object({
		email: yup.string().email('Invalid email format').required('Email required'),
	}).required();

	const navigate = useNavigate();
	const { register, handleSubmit, control, formState: { errors } } = useForm({
		resolver: yupResolver(schema)
	});
    const { loading, isAuthenticated, error } = useSelector(state => state.auth)

	
	const onSubmit = async(data) => {
		dispatch({
			type: LOADING,
			payload: true
		})
		await customAxios.post(`auth/forgot-password`, data)
		.then(async response => {
			dispatch({
				type: LOADING,
				payload: false
			})
			setOpen(true)
			
		})
		.catch(async error => {
			toast.error(error)

			dispatch({
				type: LOADING,
				payload: false
			})

		});
		
	};

	return (
		<>
		<HomeHeaderWrapper />
		<div className="register-form registerfor-b2c">

			
				<section>
					<div className="register-holld forget-password">
						<div className="section-title">
				  			 <h2>Forget Password</h2>
				  		 </div>
						<div className="content-wrap">
							<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
								<Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
									Reset password link has been successfully sent
								</Alert>
							</Snackbar>
				    
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className='forget-form' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', marginLeft: 15 }}>
									
									<Typography width={{ xs: "95%", md: '100%'}} className='desText forget-text' >Please enter your registered email address. You will receive a link to create a new password via email.</Typography>
									<Grid md={12} width={{ xs: "95%", md: '100%'}}>
									<InputField 
										fieldLabel="Email Address" 
										fieldName="email" 
										control={control} 
										error={errors.email} 
										fieldIcon={<FontAwesomeIcon icon={faEnvelope}/>}
									/>
									</Grid>
									
								</div>
								
								
								<div style={{ display: 'flex',justifyContent: 'end', alignItems: 'center',paddingTop:'2%'  }}>
									{loading ? <CircularProgress /> : <Button type="submit"  className='subm-buttoncls'
									sx={{ boxShadow: 2 }}
									variant="filled" style={{ backgroundColor: '#ad9052', borderRadius: 0, padding: 5, width: 150, height: 40, borderRadius: 30 }}
									>
										<Typography className='loginButton'>SEND <span className='button-icon'><FontAwesomeIcon icon={faArrowRightLong} /></span></Typography>
									</Button>}
									
								</div>
							</form>
						</div>
						</div>
				</section>
		</div>
		<Footer/>
		</>
	)
}

export default ForgotPassword
