

import React from 'react'
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { makeStyles } from '@mui/styles';
import { FormGroup, Typography } from '@mui/material';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles(() => ({
	noBorder: {
	  borderRadius: 0,
	  border: 0,
	},
	resize:{
		fontSize:12,
        height: 15,
  
	},
}));

const CustomDate = ({label, changeDate,minDate}) => {

    const [value, setValue] = React.useState(null);
    const changeDateValue = (value) => {
        setValue(value)
        changeDate(value)
    }

    const classes = useStyles();

    return (
          
        <DatePicker
            autoComplete='off'
            selected={value}
            minDate={minDate ? minDate : null}
            dateFormat='dd-MM-yyyy'
            onChange={(newValue) => {
              
                changeDateValue(newValue);
            }}
            
            customInput={<TextField
                className='form-control'
                fullWidth
                
            />}
        />
      
    )
}

export default CustomDate