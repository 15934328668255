import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import WorkFlow from '../../components/WorkFlow';
import Layout from '../Layout';
import LoginRegister from './LoginRegister';
import Grid from '@mui/material/Unstable_Grid2';
import SelectedVehicle from '../../components/SelectedVehicle';
import Bookings from '../../components/Bookings';
import AddedVehicle from '../../components/AddedVehicle';

const BookLogin = ({ head }) => {

    return (
        <Layout head={head}>
            {/* <WorkFlow activeStep={3} /> */}
            {/* <Bookings /> */}
            {/* <SelectedVehicle/>   */}

            <Grid container>
                <Grid item md={12}>
                    <LoginRegister />
                </Grid>
            </Grid>
        </Layout>
    )
}

export default BookLogin
