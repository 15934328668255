import React, { Fragment, useEffect } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './style.scss'
import TripHistory from './TripHistory'
import { Button, Grid, Stack } from '@mui/material'
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import TopBar from '../../components/TopBar'
import { useNavigate } from "react-router-dom";
import TopMenu from '../../components/TopMenu'
import { useDispatch, useSelector } from 'react-redux'
import { getTrip } from '../../Redux/actions/tripActions'
import CarImg from '../../assets/images/IconsNew/car.png'
import { AddCircle } from '@mui/icons-material'
import { RESET_BOOKING } from '../../Redux/constants/bookingConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar} from '@fortawesome/free-solid-svg-icons';
const Dashboard = ({ head }) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        let params = {
            parent_id : user?.parentDetails ? user?.parentDetails?.id : user?.id,
            child_id : user?.id
        }
        
        //let id = user?.id
        dispatch(getTrip(params))
    }, [])

    useEffect(() => {
        dispatch({
            type: RESET_BOOKING
        })
      }, []);


    return (
        <Layout head={head}>
            {/* <TopBar activeMenu="1"/> 
            <TopMenu /> */}
            <div className='container-fluid'>
            <Stack direction={"row"} justifyContent="space-between" alignItems={"center"} >
                {/* <h1 class='underline'><span style={{ textDecorationLine:'underline', textDecorationColor:'#B6A269' }}>Trip </span></h1> */}
                <h1 class="hd-text" style={{ margin: '30px 0' }}>Trip History</h1>
                <Grid className='new-booking-btn' style={{ flexDirection: 'row', justifyContent: 'center' }} >
                    <Button 
                         className='subm-buttoncls '
                        variant="contained" 
                        
                        style={{ color: 'white', backgroundColor: '#333333', borderRadius: 1, height: 40,textTransform:'capitalize' }}
                        onClick={() => navigate("/newbooking")} 
                        
                    >
                       New Booking <span className='button-icon'><FontAwesomeIcon icon={faCar} /></span>
                    </Button>
                    
                </Grid>
                {/* <Grid display={{ xs: 'flex', md:'none' }}>
                    <AddCircle onClick={() => navigate("/newbooking")}  />
                </Grid> */}
            </Stack>
            <TripHistory />
            </div>
        </Layout>
    )
}

export default Dashboard
