import React from 'react'
import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { useNavigate } from "react-router-dom";
import mainLogo from '../assets/images/b2b_b2cgbanewlogo-white.png';

import  { useDispatch } from 'react-redux'
import { RESET_USER } from '../Redux/constants/userConstants';
const HomeHeader = () => {
    
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
    
    return (
        <div className="top_bar">
            <a href="#" title="+65 8180 8013" className="sa_call"><i className="fa fa-phone sa_show-sm" aria-hidden="true"></i><span> Call: </span><span className="clr_them">+65 8180 8013</span></a>
            <a className="sa_mail" href="#" title="booking@limo-z.sg"><i className="fa fa-envelope sa_show-sm" aria-hidden="true"></i><span> Mail: </span><span className="clr_them">booking@limo-z.sg</span></a>


            <div className="mainHeadLogoc ">
                <a onClick={()=>navigate('/')} title="Limoz.com Homepage" >
                    <img src={mainLogo} alt="Welcome"  />
                </a>
            </div>

            <div className="sa_social">
                <ul>
                    <li><a href="#"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                    <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
                    <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li>
                    <li><a href="#"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                </ul>
            </div>
        </div>    
    )
}

export default HomeHeader

