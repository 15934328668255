import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout'
import './style.scss'
import TransactionsList from './TransactionsList'
import { Button, Grid, Typography, Snackbar, Alert, Stack, Box, Popper, Dialog, ListItem, DialogTitle, List, Divider } from '@mui/material'
import { toast } from 'react-toastify'
import AddTopUp from './AddTopUp'
import { getBanks } from '../../Redux/actions/topupActions'
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { getProfile } from '../../Redux/actions/profileActions';
import CustomDate from '../../components/CustomDate'
import InfoIcon from '@mui/icons-material/Info';
import customAxios from '../../CustomAxios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faCalendar, faBusinessTime, faWallet, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
const Wallet = ({ head }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const NewTopUp = () => {
        setViewModalShow(true);
    }
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [banks, setBanks] = useState(null)
    const [show, setShow] = useState(false)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const [viewModalShow, setViewModalShow] = useState(false);
    let id = "popper";
    const { newTopup, error, transactionLists } = useSelector(state => state.topup)
    const { user } = useSelector(state => state.auth)
    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (newTopup) {
            setOpen(true)

            setViewModalShow(false);
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, newTopup])
    useEffect(() => {
        dispatch(getBanks())
        let id = user?.id
        dispatch(getProfile(id))
    }, [])


    const showBanks = async() => {
        const response = await customAxios.get('adminbanks');
        const data = response.data.data;

        setBanks(data);
        setShow(true)
    }

    const hideDialog = () => {
        setShow(false)
    }

    const { bankLists } = useSelector(state => state.topup)

    return (
       
        <Layout head={head}>
        <div className='container-fluid'>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Topup added Successfully
                </Alert>
            </Snackbar>
            {/* <TopBar activeMenu="2"/>  */}

            <h1 class="hd-text" style={{ margin: '30px 0 10px' }}>Wallet Details</h1>
            <Stack direction={"row"} justifyContent="space-between" alignItems={"center"} >
                        {/* <span><ErrorIcon style={{ width: 20, height: 20 }} /></span> */}
                        <span> <Typography fontSize={{ xs: '12px', md: '12px' }} fontFamily='Lato' >Top up your wallet for easy checkout!</Typography> </span>
                   

                </Stack>
                <div className="col-sm-6 mt-20">
					<div className="search-sec wallet-search-sec">
                        <div className="inline-form">
                        <label>From</label>
                        <div className="input-group">
                        <CustomDate label={'From'} changeDate={(value) => setFromDate(value)} />
                            <div className="input-group-addon"><FontAwesomeIcon icon={faCalendar} /></div>
                        </div>
                    </div>
                    <div className="inline-form">
                        <label>To</label>
                        <div className="input-group">
                        <CustomDate label={'To'} changeDate={(value) => setToDate(value)} />
                            <div className="input-group-addon"><FontAwesomeIcon icon={faCalendar} /></div>
                        </div>
                    </div>
                        </div>
                        </div>
					<div className="col-sm-6 text-right">
						<div className="my-booking-nav wallet-amt">
                            <div className=''>
                                <Typography fontSize='14px' fontFamily='Lato' fontWeight="bold" color="#848484">Current Balance: </Typography>
                                <Stack variant="contained" justifyContent={"center"} alignItems="start"
                                    style={{ color: '#b7a269', fontSize: '30px', fontFamily: 'Lato', fontWeight: '900' }} >
                                    S${((user?.walletbalance ? parseFloat(user?.walletbalance).toFixed(2) : 0))}
                                </Stack>
                            </div>
                        
                        <Button variant="contained"
                                style={{ color: 'white', fontSize: 14, backgroundColor: '#333333', borderRadius: 30, padding: 5, height: 35, width: 80 }} onClick={NewTopUp}
                                className="tabStyle commonButtonSize" >

                                TOP UP </Button>
                        </div>
                        </div>
                <Stack flexDirection={'row'} justifyContent="space-between" alignItems={"center"} width={"100%"}>

                    <Box display={"flex"} flexDirection="row" justifyContent={"center"} alignItems="center" pt={0} pb={2}>
                        
                        <Grid className='new-booking-btn' display={{ xs: 'none', md: 'flex' }} style={{ flexDirection: 'row', justifyContent: 'center' }} >
           
                           
                                <InfoIcon style={{ position:'absolute', top: -10 }} aria-describedby={id} onClick={showBanks} />
                                <Dialog onClose={hideDialog} open={show}>
                                    <List sx={{ pt: 0 }}>
                                        {banks?.map((bank, index) => (
                                            <div>
                                        <ListItem disableGutters key={index} style={{ flexDirection: 'column', flex: 1, width: 400, padding: 5, margin: 5 }}>
                                            <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label style={{ display: 'flex', flex: 0.4 }}>Bank Name</label>
                                                <label style={{ display: 'flex', flex: 0.6 }}>: {bank?.bank}</label>
                                            </Box>
                                            <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label style={{ flex: 0.4 }}>Account Number</label>
                                                <label style={{ flex: 0.6 }}>: {bank?.account_number}</label>
                                            </Box>
                                            <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label style={{ flex: 0.4 }}>Bank Code</label>
                                                <label style={{ flex: 0.6 }}>: {bank?.bank_code}</label>
                                            </Box>
                                            <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label style={{ flex: 0.4 }}>Branch Code</label>
                                                <label style={{ flex: 0.6 }}>: {bank?.branch_code}</label>
                                            </Box>
                                            <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label style={{ flex: 0.4 }}>Swift Code</label>
                                                <label style={{ flex: 0.6 }}>: {bank?.swift_code}</label>
                                            </Box>
                                            <Box style={{ display: 'flex', flexDirection: 'row', width: 300 }}>
                                                <label style={{ flex: 0.4 }}>more_info</label>
                                                <label style={{ flex: 0.6 }}>: {bank?.more_info}</label>
                                            </Box>
                                            {/* <ListItemButton onClick={() => handleListItemClick(email)} key={email}>
                                            <ListItemAvatar>
                                                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                                <PersonIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={email} />
                                            </ListItemButton> */}
                                            
                                        </ListItem>
                                        <Divider />
                                        </div>
                                        ))}
                                    </List>
                                </Dialog>
                           
                                
               
                       
                    </Grid>
                    </Box>
                </Stack>
              
           
            <TransactionsList fromDate={fromDate} toDate={toDate} />
            {viewModalShow &&
                <AddTopUp
                    bankLists={bankLists}
                    openModal={viewModalShow}
                    closeModal={() => setViewModalShow(false)}
                />
            }
            </div>
        </Layout>
    )
}

export default Wallet