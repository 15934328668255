import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import  { useDispatch } from 'react-redux'
import DropDownMenu from './DropDownMenu';
import { RESET_USER } from '../Redux/constants/userConstants';
import { BOOKING_DATA, REMOVE_ORDER_ITEM, RESET_BOOKING } from '../Redux/constants/bookingConstants';
import BookingForm from '../screens/Home/BookingForm'
const HomeTopBarBottom = ({}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleNavMenu1 = () => {
    setAnchorElNav(null);
    navigate('/attraction')
  };
  const handleNavMenu2 = () => {
    setAnchorElNav(null);
    navigate('/transfer')
  };
  
 
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const resetdata = ()=>{
    dispatch({
      type: RESET_BOOKING,
    })
  
  }

  return (
    <>
   
  <div class="mainslide_hold">
		<div class="home-search-bar transfer-home-search-bar">
	    <div class="container-fluid width-80">
        <div class="row">
            <div class="col-xs-12 col-lg-4">
              <div class='car_bg banner-cntnt'>
                <h1>THE BEST LIMO'Z</h1>
                <h2>IN SINGAPORE</h2>
              </div>
            </div>
            <div class="col-xs-12 col-lg-8">
            
              <div class="transferSearchpanel"  id="scroller" style={{width: "100%"}}>
                <BookingForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
      </>
  );
};
export default HomeTopBarBottom;
