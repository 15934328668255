import React from 'react'
import HomeTopBarBottom from '../../components/HomeTopBarBottom'
import HomeMiddlePortion from '../../components/HomeMiddlePortion'
import { Grid, Typography, Box } from '@mui/material'
import './style.scss';
import HomeHeaderWrapper from '../../components/HomeHeaderWrapper'
import Footer from '../../components/Footer'
import '../../assets/css/new/animate.min.css';

const Home = () => {
   //process.env.REACT_APP_GOOGLE_KEY
   // const script = document.createElement("script");
   // script.src = "../../assets/js/b2c_bannerscripts.js";
   // script.async = true;
   // document.body.appendChild(script);
   
   return (
      <div > 
         
            <HomeHeaderWrapper />
           
            <div className='homeBackground'></div>
            <Grid container className=" ">
               <HomeTopBarBottom />
               <HomeMiddlePortion />
               
               {/* <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'top' }} >
                  <h1 className='heading' >Book Now</h1>
               </Grid> */}
               {/* <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} >
                  <BookingForm />
               </Grid>
               <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'top', height: '3%' }} >
                  <h1 className='heading' >BEST LIMOUSINE SERVICE</h1>
               </Grid>
               <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'top', height: '3%' }} >
                  <h1 className='heading' >In</h1>
               </Grid>
               <Grid xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'top' }} >
                  <h1 className='heading'>SINGAPORE</h1>
               </Grid> */}
            </Grid>
         <Footer/>
      </div>
   )
}

export default Home
