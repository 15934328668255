import React, { Fragment, useEffect, useState } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import HomeHeaderWrapper from '../../components/HomeHeaderWrapper';
import Footer from '../../components/Footer';
import SummaryDetails from './SummaryDetails'
import './style.scss'
import AddStop from './AddStop'
import SelectVehicle from './SelectVehicle'
import TopBar from '../../components/TopBar'
import { Box, Grid, Typography } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getHourlyRate, getTripAvailability } from '../../Redux/actions/bookingActions'
import { LoadScript } from '@react-google-maps/api';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import reactotron from '../../ReactronConfig'
import moment from 'moment'
import GetId from '../ThirdParty/GetId'
import MyTransfer from '../ThirdParty/MyTransfer'
import VehicleList from '../VehicleList'
import { has } from 'lodash'
import image1 from '../../assets/images/transfer/benz.png';
import image2 from '../../assets/images/transfer/kia.png';
import image3 from '../../assets/images/transfer/bmw.png';
import image4 from '../../assets/images/transfer/audi.png';
import image5 from '../../assets/images/transfer/mg.png';
const Summary = ({ head, props }) => {

    const dispatch = useDispatch();
    const [bookingData, setBookingData] = useState(null)

    const { enablegett, orderData } = useSelector(state => state.booking)
    const { user } = useSelector(state => state.auth)







    useEffect(() => {
        checkPricePlans()
    }, [])

    function calcCrow(lat1, lon1, lat2, lon2) {
        var R = 6371; // km
        var dLat = toRad(lat2 - lat1);
        var dLon = toRad(lon2 - lon1);
        var lat1 = toRad(lat1);
        var lat2 = toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
 
        return d;
    }

    // Converts numeric degrees to radians
    function toRad(Value) {
        return Value * Math.PI / 180;
    }


    const checkPricePlans = async () => {
        let token = localStorage.getItem("token");
        if (token) {
            const bookinData = await JSON.parse(localStorage.getItem("booking"));
          
            bookinData.waypoints = bookinData.waypoints ? bookinData.waypoints : [];
            setBookingData(bookinData);
            dispatch({
                type: BOOKING_DATA,
                payload: bookinData
            })

            let extraseats = parseInt(bookinData?.baby) + parseInt(bookinData?.minors) + parseInt(bookinData?.childrens)


            if (bookinData.type === "point") {
                let datas = {
                    fromLat: bookinData?.location_from?.latitude.toFixed(16),
                    fromLng: bookinData?.location_from?.longitude.toFixed(16),
                    toLat: bookinData?.location_to?.latitude.toFixed(16),
                    toLng: bookinData?.location_to?.longitude.toFixed(16),
                    stops: bookinData?.stops ? bookinData?.stops.length : 0,
                    noOfPassengers: bookinData?.passenger,
                    date: moment(bookinData.pickupdate).format("DD-MM-YYYY HH:mm"),
                    user_type: user?.id ? user?.role === "customer" ? "Customer" : user?.role === "agent" ? 'Agent' : 'Corporate' : 'Guest',
                    location_type: (bookinData?.location_from?.type === 'airport' || bookinData?.location_to?.type === 'airport') ? 'airport' : 'point',
                    extraseats,
                    customer_type: user?.role === "customer" ? 3 : user?.role === "corporate" ? 1 : user?.role === "agent" ? 2 : 4,
                    user_id: user?.id
                }
               
                dispatch(getTripAvailability(datas))
            }
            else if (bookinData.type === "hour") {

                let distance;
                if (bookinData?.location_to !== null) {
                    distance = calcCrow(bookinData?.location_from?.latitude, bookinData?.location_from?.longitude, bookinData?.location_to?.latitude, bookinData?.location_to?.longitude);
                } else {
                    distance = 0;
                }
                let datas = {
                    km: distance.toFixed(2),
                    //hour: (bookinData?.durationFull?.value/3600).toFixed(1),
                    hour: bookinData?.duration,
                    noOfDays: ((bookinData?.durationFull?.value / 3600) / 24).toFixed(2) === "NaN" ? 0 : ((bookinData?.durationFull?.value / 3600) / 24).toFixed(2),
                    noOfPassengers: bookinData?.passenger,
                    date: moment(bookinData.pickupdate).format("DD-MM-YYYY HH:mm"),
                    user_type: user?.id ? user?.role === "customer" ? "Customer" : user?.role === "agent" ? 'Agent' : 'Corporate' : 'Guest',
                    location_type: (bookinData?.location_from?.type === 'airport' || bookinData?.location_to?.type === 'airport') ? 'airport' : 'point',
                    countryCode:bookinData?.location_from?.countryCode,
                    countryName:bookinData?.location_from?.countryName,
                    extraseats,
                    customer_type: user?.role === "customer" ? 3 : user?.role === "corporate" ? 1 : user?.role === "agent" ? 2 : 4,
                    user_id: user?.id
                }
                dispatch(getHourlyRate(datas))
            }
        }
    }


    return (
        <>
        {/* { !user && <Header/>} */}
        <HomeHeaderWrapper/>

         
            <div className='transferdetailslist'>
           <div className="car_bg car-dtl-cover">
                
            </div>
            <div className="brand_bg">
                <div className="brand1"><img src={image1}/></div>
                <div className="brand2"><img src={image2}/></div>
                <div className="brand3"><img src={image3}/></div>
                <div className="brand4"><img src={image4}/></div>
                <div className="brand5"><img src={image5}/></div>
            </div>
            {/* <WorkFlow activeStep={1} /> */}
            {/* <h4 className='underline'><span style={{ textDecorationLine: 'underline', textDecorationColor: '#B6A269' }}>Sum</span>mary</h4> */}
            <div className='width-80'>
            {/* <h1 className="hd-text mt-0">Summary</h1> */}
            {/* <div>


                {bookingData && <SummaryDetails data={bookingData} onAdd={checkPricePlans} />}
            </div> */}
            {/* {!enablegett &&  <div>
                {bookingData?.location_to === null ? '' :
                <AddStop onAdd={checkPricePlans} />  }
            </div>} */}
            <div className='col-sm-12'>
                {/* <h4 className='underline'><span style={{ textDecorationLine: 'underline', textDecorationColor: '#B6A269', margin: 0, padding: 0 }}>Selec</span>t Vehicle</h4> */}
                {/* <h3 className="hd-text">Select Vehicle</h3> */}
                {/* {!enablegett && <SelectVehicle/>} */}
                {!enablegett && <VehicleList />}
                {/* {enablegett && <GetId />} */}
                {enablegett && (!orderData || orderData?.length === 0) && <div>
                    {/* <GetId /> */}
                    <MyTransfer />
                </div>}
            </div>
            </div>
            </div>
        
      <Footer/>
    </>
    )
}

export default Summary
