import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import { Button, Grid, Typography, Snackbar, Alert } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { toast } from 'react-toastify'
import {  updatePassword } from '../../Redux/actions/profileActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong} from '@fortawesome/free-solid-svg-icons';
import * as yup from "yup";
const EditPassword = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const { user } = useSelector(state => state.auth)
    // useEffect(() => {
    //     let id = user?.id
    //     dispatch(getProfile(id))
    // }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const { updatepasswordSuccess, error } = useSelector(state => state.profile)
    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (updatepasswordSuccess) {
            setOpen(true)

            dispatch({
                type: RESET_ERROR
            })
            //dispatch(getSliderLists())

        }

    }, [error, updatepasswordSuccess])

    const schema = yup.object({
        password: yup.string().required('Password is required').min(8, 'Password length should be at least 8 characters').max(30,'Maximum character exceeded'),
        confirm_password: yup.string().when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("password")], "Both password need to be the same"),
        })
            .required("Confirm Password is Required"),

    }).required();

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode:'onChange'
    });

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append("password", data.password);
        formData.append("userID", user.id);
        dispatch(updatePassword(formData))
    };


    return (

        <Grid xs={12} marginTop={2} justifyContent="center" alignItems={"center"}>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Password updated Successfully
                </Alert>
            </Snackbar>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    
                    <div class="booking-detailscard mt-20">
                        <h3 class="hd-text">Change Password</h3>
                        <div className='row'>
                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="Password"
                                    fieldType="password"
                                    fieldName="password"
                                    control={control}
                                    error={errors.password}
                                />
                            </div>
                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="Confirm Password"
                                    fieldType="password"
                                    fieldName="confirm_password"
                                    control={control}
                                    error={errors.confirm_password}
                                />
                            </div>
                            
                        </div>
                    </div>
                    <div className='row'>
                    <div className='col-sm-12 text-right'>
                                <div class="btn-sec">
                                    <Button
                                        type="submit"
                                        variant="filled"
                                        className='subm-buttoncls'
                                        sx={{ boxShadow: 3 }}
                                        style={{ backgroundColor: '#b8a269', borderRadius: 30, marginTop: 20, padding: 5, width: 100, alignSelf: 'center', height: 40, marginTop: 20 }}
                                    >
                                        <Typography className='buttonText'>Save <span className='button-icon'><FontAwesomeIcon icon={faArrowRightLong} /></span></Typography>
                                    </Button>
                                </div>
                            </div>
                    </div>
                    

                </div>
            </form>

        </Grid>

    )
}

export default EditPassword
