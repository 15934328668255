import { Divider, Grid, Typography } from '@mui/material'
import { has } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import Vehicle from './Vehicle'
import {  useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
const VehicleList = () => {
    const { availableVehicles, bookingData } = useSelector(state => state.booking)
    const navigate = useNavigate()
    console.log({availableVehicles})
    
    return (
        <>
        <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='booking-top'>
                            {/* <h5>Step - 2</h5> */}
                            <h2>Select Vehicle</h2>
                            <a onClick={() => navigate(-1)} className='btn back-btn'><span className='button-icon'><FontAwesomeIcon icon={faArrowLeftLong} /></span>Back</a>
                         </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="sa_card_panel">
                        <div className="availableSearch">
                            
                        {availableVehicles?.length > 0 ?
                        <Grid container spacing={4}  mt={0}>
                        
                        {availableVehicles && availableVehicles.map((vehicle, index) => {
                            if(has(vehicle, 'vehicledetails')){
                                return(
                                    // <Grid  xs={12} md={4} id={index}  borderBottom="1px solid " borderColor={"rgba(112, 112, 112, 0.2)"}  paddingBottom={1} >
                                
                                        <Vehicle 
                                            item={vehicle}
                                        />
                                
                                    
                                )
                            }
                            
                        })}
                    
                        </Grid>: <div style={{display:'flex',justifyContent:'center'}} >
                            <Typography fontFamily={'Nunito'} fontSize={20} fontWeight={'bold'} letterSpacing={1}>
                                No Vehicle Found !
                            </Typography>
                        </div>}
                    </div>
            </div>
        </div>
    </div>
</>
    )
}

export default VehicleList