import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';
import {  useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,faUser,faPhone,faArrowLeftLong,faArrowRightLong} from '@fortawesome/free-solid-svg-icons';
const CustomerForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const [country, setCountry] = useState(null);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const { registerSuccess, error } = useSelector(state => state.auth)

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const schema = yup.object({
        firstname: yup.string().required('First name is required').max(100, 'Maximum character exceeded'),
        lastname: yup.string().required('Last name is required').max(100, 'Maximum character exceeded'),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required').max(30, 'Maximum character exceeded'),
        email: yup.string().email('Invalid email format').required('Email is required').max(30, 'Maximum character exceeded'),
        password: yup.string().required('Password is required').min(8, 'Password should contain atleast 8 characters').max(30, 'Maximum character exceeded'),
        confirm_password: yup.string().when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("password")], "Both password need to be the same"),
        })
            .required("Confirm Password Required"),
    }).required();

    const { register, handleSubmit, control, reset, formState: { errors },setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            is_corporate: 1,
            phone: '',
            mobile: '',
            password: '',
            email: '',
            firstname: '',
            lastname: '',
            confirm_password: ''
        },
        mode: 'onChange'
    });
    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch({
                type: RESET_ERROR
            })
        }

        if (registerSuccess) {
            setOpen(true)
            reset();
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [error, registerSuccess])

    const changePhone = (value) => {
        setCountry(value)
    }

    const onSubmit = (data) => {

         console.log({data})
        let formData = new FormData();
        formData.append("email", data.email);
        formData.append("name", data.firstname);
        formData.append("lastname", data.lastname);
        formData.append("phone", country.phonenumber);
        formData.append("country_code", country.callingCode);
        formData.append("mobile", country.phonenumber);
        formData.append("password", data.password);
        formData.append("user_type", 3);
        formData.append("is_corporate", 1);
        //formData.append("userID", user?.id);

        dispatch(userRegister(formData))
    };
    return (

        <Grid container justifyContent="center">
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Customer successfully registered
                </Alert>
            </Snackbar>

            <Grid xs={12} md={7} padding='1%' sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>


                <form onSubmit={handleSubmit(onSubmit)}>
                    <div class="booking-top">
                        <div className='row' style={{ display: 'flex', flexDirection: 'row', padding: '0 0 2% 0' }}>
                        <div className='col-sm-12'>
                                <h2>Customer Registration</h2>
                            </div>
                           
                        </div>
                    </div>

                <div className='row'>
                    <div className='col-md-12 mt-30'>
                    <div className='booking-detailscard'>
                    <h3 class="hd-text register-txt-hd">Customer Details</h3>
                    
                    <FormGroup >
                        <div className='row' >
                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="First Name"
                                    fieldName="firstname"
                                    control={control}
                                    error={errors.firstname}
                                    fieldIcon={<FontAwesomeIcon icon={faUser}/>}
                                />
                            </div>
                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="Last Name"
                                    fieldName="lastname"
                                    control={control}
                                    error={errors.lastname}
                                    fieldIcon={<FontAwesomeIcon icon={faUser}/>}
                                />
                            </div>
                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="Email Address"
                                    fieldName="email"
                                    control={control}
                                    error={errors.email}
                                    fieldType="email"
                                    fieldIcon={<FontAwesomeIcon icon={faEnvelope}/>}
                                />
                            </div>
                            <div className='col-sm-6 pb-15'>
                                <PhoneInputs
                                    fieldLabel="Phone"
                                    fieldName="phone"
                                    control={control}
                                    error={errors.phone}
                                    onChangePhone={changePhone}
                                    fieldIcon={<FontAwesomeIcon icon={faPhone}/>}
                                />
                            </div>
                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="Password"
                                    fieldName="password"
                                    control={control}
                                    error={errors.password}
                                    fieldType="password"
                                    placeholder='Password should contain atleast 8 characters'
                                />
                            </div>
                            <div className='col-sm-6 pb-15'>
                                <InputField
                                    fieldLabel="Confirm Password"
                                    fieldName="confirm_password"
                                    control={control}
                                    fieldType="password"
                                    error={errors.confirm_password}
                                />
                            </div>
                        </div>
                    </FormGroup>
                    </div>
                </div>
                <div className='col-sm-12' style={{ marginTop: '3%', borderTop: '1px solid #e0e0e0' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', padding: '3% 0 5% 0' }}>
                        <Button type="submit" className='subm-buttoncls' variant="filled" style={{ backgroundColor: '#ad9052', borderRadius: 30, padding: 5, height: 45, width: 120 }}>
                            <Typography className='buttonText'>Register <span className='button-icon'><FontAwesomeIcon icon={faArrowRightLong} /></span></Typography></Button>
                    </div>
                </div>

                    

                </div>
                
                </form>

            </Grid>
        </Grid>

    )
}

export default CustomerForm
